import { gql } from '@apollo/client'
import { bankAccountFragment } from './bankAccountFragment'

export const approveBankAccountMutation = gql`
  ${bankAccountFragment}
  mutation ($input: ApproveBankAccountInput!) {
    approveBankAccount(input: $input) {
      bankAccount {
        ...bankAccountFragment
        user {
          id
          displayName
        }
      }
    }
  }
`

export const approveBankAccountOptions = { parser: [['bankAccount', 'approveBankAccount.bankAccount']] }
