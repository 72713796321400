import { useEffect } from 'react'

const useDropdown = (dropdownLinkRef, dropdownSelfRef, isDropdownOpen, setDropdownOpen) => {
  useEffect(
    () => {
      const listener = event => {
        if (dropdownLinkRef.current && dropdownLinkRef.current.contains(event.target)) {
          setDropdownOpen(!isDropdownOpen)
          return
        }

        if (!dropdownSelfRef.current || dropdownSelfRef.current.contains(event.target)) {
          return
        }

        setDropdownOpen(false)
      }

      // document.addEventListener('touchstart', listener)
      document.addEventListener('mousedown', listener)

      return () => {
        // document.removeEventListener('touchstart', listener)
        document.removeEventListener('mousedown', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [dropdownLinkRef, dropdownSelfRef, isDropdownOpen, setDropdownOpen]
  )
}

export default useDropdown
