import { gql } from '@apollo/client'
import { companyResultFragment } from './companyResultFragment.js'

export const companyResultGetQuery = gql`
  ${companyResultFragment}
  query ($input: GetCompanyResultInput) {
    companyResultGet(input: $input) {
      companyResult {
        ...companyResultFragment
      }
    }
  }
`

export const companyResultGetOptions = {
  parser: [['companyResult', 'companyResultGet.companyResult']],
}
