import React from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import yup from 'lib/validation'
import useMutation from 'lib/useMutation'

const ChangePassword = ({ history }) => {
  const [changePassword] = useMutation('changePassword', {
    errorCodes: {
      ERR_UNAUTHORIZED: 'Неверно указан текущий пароль',
    },
  })
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordAgain: '',
    },
    onSubmit: ({ password, newPassword }) => {
      return changePassword({
        variables: { input: { password, newPassword } },
      })
    },
    validationSchema: yup.object({
      password: yup.string().required(),
      newPassword: yup.string().required(),
      newPasswordAgain: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'Пароли не совпадают')
        .required(),
    }),
    successMessage: 'Пароль успешно изменён',
    resetOnSuccess: true,
  })
  return (
    <Form formik={formik}>
      <FormItems>
        <Input
          label="Текущий пароль"
          name="password"
          type="password"
          formik={formik}
        />
        <Input
          label="Новый пароль"
          name="newPassword"
          type="password"
          formik={formik}
        />
        <Input
          label="Подтвердите новый пароль"
          name="newPasswordAgain"
          type="password"
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button {...buttonProps}>Изменить пароль</Button>
      </FormItems>
    </Form>
  )
}

export default ChangePassword
