import { gql } from '@apollo/client'

export const sendFormToTelegramMutation = gql`
  mutation ($input: SendFormToTelegramInput!) {
    sendFormToTelegram(input: $input) {
      token
      email
      password
    }
  }
`
