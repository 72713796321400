import Alert from 'components/Alert'
import Button from 'components/Button'
import Link from 'components/Link'
import Loader from 'components/Loader'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import toHumanDate from 'lib/toHumanDate'
import useQuery from 'lib/useQuery'
import React from 'react'
import css from './styles.module.scss'

const Referrals = () => {
  const { referrals, pageInfo, loading, error, fetchMore, fetchingMore } = useQuery('getReferrals')
  if (loading) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!referrals.length) return <Alert message="У вас ещё нет приглашённых пользователей" />
  return (
    <div className={css.referrals}>
      <table>
        <thead>
          <tr>
            <th>Дата регистрации</th>
            <th>ФИО</th>
          </tr>
        </thead>
        <tbody>
          {referrals.map((referral, i) => (
            <tr key={i}>
              <td className={css.nowrap}>{toHumanDate(referral.createdAt, 'DD.MM.YYYY')}</td>
              <td>{referral.displayName}</td>
            </tr>
          ))}
          {pageInfo.hasNextPage && (
            <tr className={css.more}>
              <td colSpan={4}>
                <Button
                  fullWidth
                  loading={fetchingMore}
                  disabled={fetchingMore}
                  onClick={() => {
                    fetchMore({
                      variables: {
                        input: {
                          endCursor: pageInfo.endCursor,
                          limit: 30,
                        },
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev
                        return {
                          getReferrals: {
                            ...fetchMoreResult.getReferrals,
                            referrals: [...prev.getReferrals.referrals, ...fetchMoreResult.getReferrals.referrals],
                          },
                        }
                      },
                    })
                  }}
                >
                  Показать ещё
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const ReferralsPage = () => {
  return (
    <PageWrapper redirerctUnauthorized>
      {({ me }) => (
        <Segment title="Реферальная программа">
          <Segment size={2} title="Основное">
            <div className={css.general}>
              <div className={css.info}>
                Реферальный код: {me.referralCode}
                <br />
                Реферальная ссылка:{' '}
                <Link color="system-links-blue" href={process.env.REACT_APP_FRONTEND_URL + '?ref=' + me.referralCode}>
                  {process.env.REACT_APP_FRONTEND_URL + '?ref=' + me.referralCode}
                </Link>
              </div>
            </div>
          </Segment>
          <Segment size={2} title={`Приглашённые${me.referralsCount ? ` (всего ${me.referralsCount})` : ''}`}>
            <Referrals />
          </Segment>
        </Segment>
      )}
    </PageWrapper>
  )
}

export default ReferralsPage
