import { gql } from '@apollo/client'

export const uploadSingleFileMutation = gql`
  mutation($input: UploadSingleFileInput!) {
    uploadSingleFile(input: $input) {
      uploadedFile {
        path
      }
    }
  }
`

export const uploadSingleFileOptions = {
  parser: [['uploadedFile', 'uploadSingleFile.uploadedFile']],
}
