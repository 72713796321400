import { gql } from '@apollo/client'
import { userFragment } from './userFragment'

export const createUserMutation = gql`
  ${userFragment}
  mutation ($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...userFragment
      }
    }
  }
`

export const createUserOptions = { parser: [['user', 'createUser.user']] }
