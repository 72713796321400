import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'

const Loader = ({ type, ...restProps }) => (
  <span
    className={cn({
      [css.loader]: true,
      [css[`type-${type}`]]: true,
    })}
    {...restProps}
  />
)

export default Loader
