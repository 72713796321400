import React from 'react'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import useMutation from 'lib/useMutation'

const ResendEmailConfirmation = () => {
  const [resendEmailConfirmation] = useMutation('resendEmailConfirmation')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {},
    onSubmit: () => {
      return resendEmailConfirmation()
    },
    successMessage: 'Вам повторно направлено письмо для подтверждения почты',
  })
  return (
    <Form formik={formik}>
      <FormItems>
        <Alert {...alertProps} />
        <Button {...buttonProps}>Получить письмо для подтверждения почты повторно</Button>
      </FormItems>
    </Form>
  )
}

export default ResendEmailConfirmation
