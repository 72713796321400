import { gql } from '@apollo/client'

export const getFaqQuery = gql`
  query getFaq {
    getFaq {
      sections {
        name
        questions {
          question
          answer
        }
      }
    }
  }
`

export const getFaqOptions = {
  parser: [['sections', 'getFaq.sections']],
}
