import { gql } from '@apollo/client'

export const confirmEmailMutation = gql`
  mutation($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      token
      user {
        id
        emailConfirmed
      }
    }
  }
`
