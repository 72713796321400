import usualMoment from 'moment'
import 'moment/locale/ru'
import { extendMoment } from 'moment-range'
const moment = extendMoment(usualMoment)
moment.locale('ru')

const toHumanDate = (date, format) => {
  const result = format ? moment(date).format(format) : moment(date).calendar()
  if (format === 'D MMM YYYY') {
    return result.replace('.', '')
  }
  return result
}

export default toHumanDate

export { moment }
