import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import PageWrapper from 'components/PageWrapper'
import RichText from 'components/RichText'
import Segment from 'components/Segment'
import { canEditUsers, canViewUsers } from 'lib/can'
import { getUserEditRoute } from 'lib/routes'
import toHumanPermission from 'lib/toHumanPermission'
import useQuery from 'lib/useQuery'
import { DashboardData } from 'pages/DashboardPage/index.js'
import css from './styles.module.scss'

const UserViewPage = ({ user, me, dashboardData }) => {
  return (
    <Segment title={`Пользователь ${user.displayName}`}>
      <Segment>
        {user.deletedAt && (
          <div className={css.deletedAlert}>
            <Alert message="Пользователь удалён и не может пользоваться системой" type="danger" />
          </div>
        )}
        <RichText>
          <h2>Основное</h2>
          <p>
            Номер: {user.serialNumber || '—'}
            <br />
            E-mail: {user.email || '—'}
            <br />
            Телефон: {user.phone || '—'}
            <br />
            Пригласивший: {user.dummyReferrer || '—'}
            <br />
            Приглашённые: {user.dummyReferrals || '—'}
            <br />
            {/* Реферальная ссылка:{' '}
            <Link color="system-links-blue" href={process.env.REACT_APP_FRONTEND_URL + '?ref=' + user.referralCode}>
              {process.env.REACT_APP_FRONTEND_URL + '?ref=' + user.referralCode}
            </Link> */}
          </p>
          {!!user.permissions.length && (
            <p>
              Права:
              <ul>
                {user.permissions.length ? (
                  user.permissions.map((permission, i) => <li key={i}>{toHumanPermission(permission)}</li>)
                ) : (
                  <li key={0}>Никаких особых</li>
                )}
              </ul>
            </p>
          )}
          {/* <h2>Паспорт</h2>
          <p>
            Есть подтверждённый паспорт:{' '}
            {!!user.passport ? (
              <Link
                color="system-links-blue"
                disabled={!canManageUserPassports(me)}
                to={getPassportRoute(user.passport.id)}
              >
                Да
              </Link>
            ) : (
              'Нет'
            )}
            <br />
            Есть паспорт на проверке:{' '}
            {!!user.processingPassport ? (
              <Link
                color="system-links-blue"
                disabled={!canManageUserPassports(me)}
                to={getPassportRoute(user.processingPassport.id)}
              >
                Да
              </Link>
            ) : (
              'Нет'
            )}
            {user.passport && (
              <>
                <br />
                ФИО: {user.passport.fullName || '—'}
                <br />
                Серия и номер: {user.passport.seriesAndNumber || '—'}
                <br />
                Дата выдачи: {user.passport.dateOfIssue || '—'}
                <br />
                Прописка: {user.passport.registration || '—'}
                <br />
                Кем выдан: {user.passport.issuedBy || '—'}
                <br />
                Код подразделения: {user.passport.departmentCode || '—'}
                <br />
                Дата рождения: {user.passport.dateOfBirth || '—'}
                <br />
                Место рождения: {user.passport.placeOfBirth || '—'}
                <br />
                Скан страницы с фотографией:{' '}
                {user.passport.passportScan1 ? (
                  <Link
                    color="system-links-blue"
                    target="_blank"
                    href={getUploadUrl(user.passport.passportScan1, 'large')}
                  >
                    смотреть
                  </Link>
                ) : (
                  '—'
                )}
                <br />
                Скан старницы с пропиской:{' '}
                {user.passport.passportScan2 ? (
                  <Link
                    color="system-links-blue"
                    target="_blank"
                    href={getUploadUrl(user.passport.passportScan2, 'large')}
                  >
                    смотреть
                  </Link>
                ) : (
                  '—'
                )}
              </>
            )}
          </p>
          <h2>Реквизиты</h2>
          <p>
            Есть подтверждённые реквизиты:{' '}
            {user.bankAccount ? (
              <Link
                color="system-links-blue"
                disabled={!canManageUserBankAccounts(me)}
                to={getBankAccountRoute(user.bankAccount.id)}
              >
                Да
              </Link>
            ) : (
              'Нет'
            )}
            <br />
            Есть реквизиты на проверке:{' '}
            {user.processingBankAccount ? (
              <Link
                color="system-links-blue"
                disabled={!canManageUserBankAccounts(me)}
                to={getBankAccountRoute(user.processingBankAccount.id)}
              >
                Да
              </Link>
            ) : (
              'Нет'
            )}
            {user.bankAccount && (
              <>
                <br />
                Название банка: {user.bankAccount.bankName || '—'}
                <br />
                БИК банка: {user.bankAccount.bankBik || '—'}
                <br />
                Корр счёт: {user.bankAccount.corrAccount || '—'}
                <br />
                Номер счёта: {user.bankAccount.benAccount || '—'}
                <br />
                ФИО: {user.bankAccount.fullName || '—'}
              </>
            )}
          </p> */}
        </RichText>
      </Segment>
      <Segment title="Дашборд пользователя">
        <DashboardData dashboardData={dashboardData} />
      </Segment>
      {canEditUsers(me) && (
        <div className={css.edit}>
          <Buttons>
            <Button to={getUserEditRoute(user.serialNumber)}>Редактировать</Button>
          </Buttons>
        </div>
      )}
    </Segment>
  )
}

const GetUserDashboardDataWrapper = (props) => {
  const userId = props.user.id
  const queryResult = useQuery('getUserDashboardData', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        userId,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult}>
      {({ dashboardData }) => <UserViewPage dashboardData={dashboardData} {...props} />}
    </PageWrapper>
  )
}

const GetUserWrapper = (props) => {
  const userSerialNumber = props.match.params.userSerialNumber
  const queryResult = useQuery('getUser', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        userSerialNumber,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ user }) => !!user}>
      {({ user }) => <GetUserDashboardDataWrapper user={user} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canViewUsers(me)}>
    {({ me }) => <GetUserWrapper me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
