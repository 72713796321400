import { gql } from '@apollo/client'

export const createDummyReferralMutation = gql`
  mutation ($input: CreateDummyReferralInput!) {
    createDummyReferral(input: $input) {
      dummyReferral {
        id
        fio
        code
        referralUrl
      }
    }
  }
`

export const createDummyReferralOptions = { parser: [['dummyReferral', 'createDummyReferral.dummyReferral']] }
