import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'

const Segment = ({ title, className, desc, children, size = 1, rightRender, ...restProps }) => (
  <div className={cn(css.segment, className)} {...restProps}>
    {title && (
      <div className={css.header}>
        {size === 1 ? (
          <h1 className={css.title}>{title}</h1>
        ) : size === 2 ? (
          <h2 className={css.title}>{title}</h2>
        ) : (
          <h3 className={css.title}>{title}</h3>
        )}
        {rightRender && <div className={css.right}>{rightRender}</div>}
      </div>
    )}
    {desc && <div className={css.desc}>{desc}</div>}
    {children && <div className={css.content}>{children}</div>}
  </div>
)

export default Segment
