import React from 'react'
import Input from 'components/Input'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'
import { useAppCtx } from '../AppContextProvider/index.js'
import Textarea from '../Textarea/index.js'
import UploadSingleImage from '../UploadSingleImage/index.js'

const PassportFields = ({ formik, compareWith, disabled }) => {
  return (
    <>
      <Input
        disabled={disabled}
        label="ФИО"
        name="fullName"
        formik={formik}
        hint={
          compareWith && formik.values.fullName !== compareWith.fullName && `Прежнее значение: ${compareWith.fullName}`
        }
      />
      <Input
        disabled={disabled}
        label="Серия и номер"
        name="seriesAndNumber"
        mask="0000 000000"
        formik={formik}
        hint={
          compareWith &&
          formik.values.seriesAndNumber !== compareWith.seriesAndNumber &&
          `Прежнее значение: ${compareWith.seriesAndNumber}`
        }
      />
      <Input
        disabled={disabled}
        label="Дата выдачи"
        name="dateOfIssue"
        mask={Date}
        placeholder="ДД.ММ.ГГГГ"
        formik={formik}
        hint={
          compareWith &&
          formik.values.dateOfIssue !== compareWith.dateOfIssue &&
          `Прежнее значение: ${compareWith.dateOfIssue}`
        }
      />
      <Textarea
        disabled={disabled}
        label="Прописка"
        name="registration"
        formik={formik}
        hint={
          compareWith &&
          formik.values.registration !== compareWith.registration &&
          `Прежнее значение: ${compareWith.registration}`
        }
      />
      <Input
        disabled={disabled}
        label="Кем выдан"
        name="issuedBy"
        formik={formik}
        hint={
          compareWith && formik.values.issuedBy !== compareWith.issuedBy && `Прежнее значение: ${compareWith.issuedBy}`
        }
      />
      <Input
        disabled={disabled}
        label="Код подразделения"
        name="departmentCode"
        mask="000-000"
        formik={formik}
        hint={
          compareWith &&
          formik.values.departmentCode !== compareWith.departmentCode &&
          `Прежнее значение: ${compareWith.departmentCode}`
        }
      />
      <Input
        disabled={disabled}
        label="Дата рождения"
        name="dateOfBirth"
        mask={Date}
        placeholder="ДД.ММ.ГГГГ"
        formik={formik}
        hint={
          compareWith &&
          formik.values.dateOfBirth !== compareWith.dateOfBirth &&
          `Прежнее значение: ${compareWith.dateOfBirth}`
        }
      />
      <Input
        disabled={disabled}
        label="Место рождения"
        name="placeOfBirth"
        formik={formik}
        hint={
          compareWith &&
          formik.values.placeOfBirth !== compareWith.placeOfBirth &&
          `Прежнее значение: ${compareWith.placeOfBirth}`
        }
      />
      <UploadSingleImage
        disabled={disabled}
        label="Скан страницы с фотографией"
        name="passportScan1"
        formik={formik}
        hint={compareWith && formik.values.passportScan1 !== compareWith.passportScan1 && `Было загружено новое фото`}
      />
      <UploadSingleImage
        disabled={disabled}
        label="Скан старницы с пропиской"
        name="passportScan2"
        formik={formik}
        hint={compareWith && formik.values.passportScan2 !== compareWith.passportScan2 && `Было загружено новое фото`}
      />
    </>
  )
}

export const PassportForUser = () => {
  const { me } = useAppCtx()
  const [sendPassportToProcess] = useMutation('sendPassportToProcess')
  const passportInProcess = !!me.processingPassport
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      fullName: (passportInProcess ? me.processingPassport.fullName : me.passport?.fullName) || '',
      seriesAndNumber: (passportInProcess ? me.processingPassport.seriesAndNumber : me.passport?.seriesAndNumber) || '',
      dateOfIssue: (passportInProcess ? me.processingPassport.dateOfIssue : me.passport?.dateOfIssue) || '',
      registration: (passportInProcess ? me.processingPassport.registration : me.passport?.registration) || '',
      issuedBy: (passportInProcess ? me.processingPassport.issuedBy : me.passport?.issuedBy) || '',
      departmentCode: (passportInProcess ? me.processingPassport.departmentCode : me.passport?.departmentCode) || '',
      dateOfBirth: (passportInProcess ? me.processingPassport.dateOfBirth : me.passport?.dateOfBirth) || '',
      placeOfBirth: (passportInProcess ? me.processingPassport.placeOfBirth : me.passport?.placeOfBirth) || '',
      passportScan1: (passportInProcess ? me.processingPassport.passportScan1 : me.passport?.passportScan1) || '',
      passportScan2: (passportInProcess ? me.processingPassport.passportScan2 : me.passport?.passportScan2) || '',
    },
    onSubmit: ({
      fullName,
      seriesAndNumber,
      dateOfIssue,
      registration,
      issuedBy,
      departmentCode,
      dateOfBirth,
      placeOfBirth,
      passportScan1,
      passportScan2,
    }) => {
      return sendPassportToProcess({
        variables: {
          input: {
            fullName,
            seriesAndNumber,
            dateOfIssue,
            registration,
            issuedBy,
            departmentCode,
            dateOfBirth,
            placeOfBirth,
            passportScan1,
            passportScan2,
          },
        },
      })
    },
    validationSchema: yup.object({
      fullName: yup.string().required(),
      seriesAndNumber: yup.string().required(),
      dateOfIssue: yup.string().required(),
      registration: yup.string().required(),
      issuedBy: yup.string().required(),
      departmentCode: yup.string().required(),
      dateOfBirth: yup.string().required(),
      placeOfBirth: yup.string().required(),
      passportScan1: yup.string().required(),
      passportScan2: yup.string().required(),
    }),
    successMessage: 'Паспорт успешно отправлен на проверку',
    enableReinitialize: true,
  })
  const statusAlertProps =
    me.processingPassport?.status === 'processing'
      ? {
          title: 'Ваши паспортные данные находятся на проверке',
          message: 'Когда данные будут проверены вы получите уведомление по электронной почте',
        }
      : me.processingPassport?.status === 'rejected'
      ? {
          title: 'Менеджер отклонил ваши паспортные данные',
          type: 'danger',
          message: `Комментарий от менеджера: ${me.processingPassport.rejectComment}`,
        }
      : !me.processingPassport && me.passport
      ? {
          title: 'Ваши паспортные данные успешно прошли проверку',
          type: 'success',
        }
      : {
          hidden: true,
        }

  return (
    <Form formik={formik}>
      <FormItems>
        <Alert {...statusAlertProps} />
        <PassportFields formik={formik} compareWith={me.passport} />
        <Alert {...alertProps} />
        <Button {...buttonProps} disabled={!formik.dirty}>
          Сохранить изменения
        </Button>
        <Alert {...statusAlertProps} />
      </FormItems>
    </Form>
  )
}

export const PassportValidation = ({ newPassport, oldPassport }) => {
  const [approvePassport] = useMutation('approvePassport')
  const [rejectPassport] = useMutation('rejectPassport')
  const { formik, alertProps } = useForm({
    initialValues: {
      fullName: newPassport.fullName || '',
      seriesAndNumber: newPassport.seriesAndNumber || '',
      dateOfIssue: newPassport.dateOfIssue || '',
      registration: newPassport.registration || '',
      issuedBy: newPassport.issuedBy || '',
      departmentCode: newPassport.departmentCode || '',
      dateOfBirth: newPassport.dateOfBirth || '',
      placeOfBirth: newPassport.placeOfBirth || '',
      passportScan1: newPassport.passportScan1 || '',
      passportScan2: newPassport.passportScan2 || '',
      rejectComment: newPassport.rejectComment || '',
      action: 'reject',
    },
    onSubmit: ({
      fullName,
      seriesAndNumber,
      dateOfIssue,
      registration,
      issuedBy,
      departmentCode,
      dateOfBirth,
      placeOfBirth,
      passportScan1,
      passportScan2,
      rejectComment,
      action,
    }) => {
      return action === 'approve'
        ? approvePassport({
            variables: {
              input: {
                passportId: newPassport.id,
                fullName,
                seriesAndNumber,
                dateOfIssue,
                registration,
                issuedBy,
                departmentCode,
                dateOfBirth,
                placeOfBirth,
                passportScan1,
                passportScan2,
              },
            },
          })
        : action === 'reject'
        ? rejectPassport({
            variables: {
              input: {
                passportId: newPassport.id,
                fullName,
                seriesAndNumber,
                dateOfIssue,
                registration,
                issuedBy,
                departmentCode,
                dateOfBirth,
                placeOfBirth,
                passportScan1,
                passportScan2,
                rejectComment,
              },
            },
          })
        : Promise.reject('Неизвестное действие')
    },
    validationSchema: yup.object({
      fullName: yup.string().required(),
      seriesAndNumber: yup.string().required(),
      dateOfIssue: yup.string().required(),
      registration: yup.string().required(),
      issuedBy: yup.string().required(),
      departmentCode: yup.string().required(),
      dateOfBirth: yup.string().required(),
      placeOfBirth: yup.string().required(),
      passportScan1: yup.string().required(),
      passportScan2: yup.string().required(),
      rejectComment: yup.string().when('action', {
        is: 'reject',
        then: yup.string().required(),
      }),
    }),
    successMessage: (res) => {
      return res.data.rejectPassport
        ? 'Данные успешно отклонены'
        : res.data.approvePassport
        ? 'Данные успешно приняты'
        : 'Произошло что-то странное'
    },
    enableReinitialize: true,
  })
  const statusAlertProps =
    newPassport.status === 'processing'
      ? {
          title: 'Данные ожидают проверки с вашей стороны',
        }
      : newPassport.status === 'rejected'
      ? {
          title: 'Данные были отклонены',
          type: 'danger',
        }
      : newPassport.status === 'accepted'
      ? {
          title: 'Данные были приняты',
          type: 'success',
        }
      : {
          hidden: true,
        }
  const disabled = false // user.processingPassport.status !== 'sentToProcess'
  return (
    <Form formik={formik}>
      <FormItems>
        <Alert {...statusAlertProps} />
        <PassportFields formik={formik} compareWith={oldPassport} disabled={disabled} />
        <Textarea
          disabled={disabled}
          label="Причина отказа"
          name="rejectComment"
          formik={formik}
          hint={newPassport.prevRejectComment && `Прежняя причина: ${newPassport.prevRejectComment}`}
        />
        <Alert {...alertProps} />
        <Buttons>
          <Button
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.setFieldValue('action', 'approve')
              setTimeout(() => {
                formik.handleSubmit()
              }, 1)
            }}
          >
            Принять
          </Button>
          <Button
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.setFieldValue('action', 'reject')
              setTimeout(() => {
                formik.handleSubmit()
              }, 1)
            }}
          >
            Отклонить
          </Button>
        </Buttons>
        <Alert {...statusAlertProps} />
      </FormItems>
    </Form>
  )
}
