import React from 'react'
import cn from 'classnames'
import css from './styles.module.scss'
import toPrettyText from 'lib/toPrettyText'
import _ from 'lodash'
import Icon from 'components/Icon'

const Alert = ({
  type = 'neutral',
  withIcon = true,
  title,
  message,
  className,
  children,
  hidden,
  compact,
  ...restProps
}) => {
  const content = children || message
  return hidden ? null : (
    <div
      className={cn({
        [css.alert]: true,
        [css[type]]: !!type,
        [className]: !!className,
        [css.compact]: !!compact,
      })}
      {...restProps}
    >
      <div className={css.container}>
        {withIcon && (
          <div className={css.icon}>
            <Icon name="info" />
          </div>
        )}
        <div className={css.content}>
          {!!title && <p className={css.title}>{title}</p>}
          {!!content && (
            <div className={css.message}>
              {_.isString(content) ? <span dangerouslySetInnerHTML={{ __html: toPrettyText(content) }} /> : content}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Alert
