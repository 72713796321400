const getUploadUrl = (name, preset = 'origin') => {
  switch (preset) {
    case 'file':
      return `${process.env.REACT_APP_S3_URL}/${name}`
    case 'origin':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${name}`
    case 'property':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_662,h_496,c_fill/${name}`
    case 'preview':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_200,h_200,c_fill/${name}`
    case 'large':
      return `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_1000,h_1000,c_limit,q_80/${name}`
    case 'avatar':
      return name
        ? `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/w_200,h_200,c_fill/${name}`
        : '/avatar-placeholder.png'
    default:
      return null
  }
}

export default getUploadUrl
