import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useApolloClient } from '@apollo/client'
import { getDashboardRoute, getHomeRoute } from 'lib/routes'

const requestToken = async (code) => {
  const url = `${process.env.REACT_APP_BACKEND_URI}/alfa/auth-self`
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code }),
    keepalive: false,
  })
  const data = await res.json()
  return data
}

const AlfaBackPage = ({ history }) => {
  const apolloClient = useApolloClient()
  const searchParams = new URLSearchParams(window.location.search)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const code = searchParams.get('code')

  useEffect(() => {
    ;(async () => {
      try {
        const data = await requestToken(code)
        if (!data.token) {
          console.error(data)
          throw new Error(data.message || 'Неизвестная ошибка')
        } else {
          Cookies.set('token', data.token, { expires: 99999 })
          return apolloClient.resetStore().then(() => {
            history.push(getDashboardRoute())
            return false
          })
        }
      } catch (err) {
        console.error(err)
        setError(err.message)
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return <div>Загрузка...</div>
  }

  if (error) {
    return (
      <div>
        {error}{' '}
        <a style={{ color: '#fff' }} href={getHomeRoute()}>
          Назад
        </a>
      </div>
    )
  }

  return (
    <div>
      Ошибка{' '}
      <a style={{ color: '#fff' }} href={getHomeRoute()}>
        Назад
      </a>
    </div>
  )
}

export default AlfaBackPage
