import { createContext, useContext, useEffect, useState } from 'react'
import Loader from '../Loader/index.js'
import useQuery from '../../lib/useQuery.js'

const AppContext = createContext({
  me: undefined,
  settings: undefined,
  loading: true,
})

const AppContextProvider = ({ children }) => {
  const [initLoadingFinished, setInitLoadingFinished] = useState(false)
  const { me, loading } = useQuery('getMe')
  useEffect(() => {
    if (!loading && !initLoadingFinished) {
      setInitLoadingFinished(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  return (
    <AppContext.Provider
      value={{
        me,
        loading: !initLoadingFinished,
      }}
    >
      {loading ? <Loader type="page" /> : children}
    </AppContext.Provider>
  )
}

export default AppContextProvider

export const useAppCtx = () => {
  return useContext(AppContext)
}
