import React from 'react'
import Segment from 'components/Segment'

const NotFoundPage = ({
  title = 'Нет такой страницы',
  text = 'Страницы с указанным адресом не существует. Перейдите на другую страницу, пожалуйста.',
}) => (
  <Segment title={title}>
    <p>{text}</p>
  </Segment>
)

export default NotFoundPage
