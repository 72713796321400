import { gql } from '@apollo/client'

export const genDocTplFragment = gql`
  fragment genDocTplFragment on GenDocTpl {
    id
    filePath
    fields {
      label
      placeholder
      input
      output
    }
    createdAt
  }
`
