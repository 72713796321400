import { gql } from '@apollo/client'

export const genDocSingleMutation = gql`
  mutation ($input: GenDocSingleInput!) {
    genDocSingle(input: $input) {
      singleGenDocFilePath
    }
  }
`

export const genDocSingleOptions = { parser: [['singleGenDocFilePath', 'genDocSingle.singleGenDocFilePath']] }
