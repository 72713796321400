import Alert from 'components/Alert'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import Link from 'components/Link'
import PageWrapper from 'components/PageWrapper'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import Segment from 'components/Segment'
import Select from 'components/Select'
import Textarea from 'components/Textarea'
import UploadGenDocData from 'components/UploadGenDocData'
import UploadGenDocTpl from 'components/UploadGenDocTpl'
import { canGenDoc } from 'lib/can'
import getUploadName from 'lib/getUploadName'
import getUploadUrl from 'lib/getUploadUrl'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import css from './styles.module.scss'

const DeleteGenDocTpl = ({ genDocTplId }) => {
  const [deleteGenDocTpl, { loading }] = useMutation('deleteGenDocTpl', {})
  return (
    <Link
      href="#"
      onClick={(e) => {
        e.preventDefault()
        deleteGenDocTpl({ variables: { input: { genDocTplId } }, refetchQueries: ['getGenDocTpls'] })
      }}
      className={css.deleteLink}
      color="system-error-red"
      loading={loading}
    >
      Удалить
    </Link>
  )
}

const SelectGenDocTpl = ({ genDocTpls, refetchGetGenTpls, setGenDocTpl }) => {
  const { formik } = useForm({
    initialValues: {
      newGenDocTplFile: '',
      genDocTplId: '',
    },
  })
  useEffect(() => {
    const selectedGenDocTpl = genDocTpls.find((genDocTpl) => genDocTpl.id === formik.values.genDocTplId)
    setGenDocTpl(selectedGenDocTpl)
  }, [formik.values.genDocTplId, genDocTpls, setGenDocTpl])

  return (
    <Segment size={2} title="Выбрети шаблон">
      <FormItems>
        {!!genDocTpls.length && (
          <RadioButtons className={css.genDocTpls} direction="column" formik={formik} name="genDocTplId">
            {genDocTpls.map((genDocTpl) => (
              <div className={css.genDocTpl} key={genDocTpl.id}>
                <RadioButton
                  className={css.readioButton}
                  label={getUploadName(genDocTpl.filePath)}
                  value={genDocTpl.id}
                  formik={formik}
                  name="genDocTplId"
                />{' '}
                <DeleteGenDocTpl genDocTplId={genDocTpl.id} />
              </div>
            ))}
          </RadioButtons>
        )}
        <UploadGenDocTpl
          name="newGenDocTplFile"
          formik={formik}
          refetchGetGenTpls={refetchGetGenTpls}
          genDocTplIdName="genDocTplId"
        />
      </FormItems>
    </Segment>
  )
}

const GenDocField = ({ formik, field }) => {
  switch (field.input) {
    case 'input':
      return <Input formik={formik} name={field.label} label={field.label} />
    case 'textarea':
      return <Textarea formik={formik} name={field.label} label={field.label} />
    case 'date':
      return <Input formik={formik} name={field.label} label={field.label} mask={Date} placeholder="ДД.ММ.ГГГГ" />
    case 'number':
      return <Input formik={formik} name={field.label} label={field.label} mask="0000000000" />
    case 'currency':
      return (
        <Select
          formik={formik}
          name={field.label}
          label={field.label}
          options={[
            { value: '₽', label: '₽' },
            { value: '$', label: '$' },
            { value: '€', label: '€' },
            { value: 'USDT', label: 'USDT' },
          ]}
        />
      )
    default:
      return null
  }
}

const GenDocSingleForm = ({ genDocTpl }) => {
  const uniqFields = [
    { input: 'input', label: 'Номер инвестора', output: 'text', placeholder: 'Номер инвестора' },
    ..._.uniqBy(genDocTpl.fields, (field) => field.label),
  ]
  const initialValues = uniqFields.reduce((acc, f) => ({ ...acc, [f.label]: '' }), {})
  const validationSchema = yup.object({
    ...uniqFields.reduce((acc, f) => ({ ...acc, [f.label]: yup.string() }), {}),
  })
  const [genDocSingle] = useMutation('genDocSingle')
  const [serializedStateAfterGeneration, setSerializedStateAfterGeneration] = useState('')
  const [downloadLink, setDownloadLink] = useState('')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      return genDocSingle({
        variables: { input: { genDocTplId: genDocTpl.id, serializedInput: JSON.stringify(values) } },
      }).then(({ singleGenDocFilePath }) => {
        setSerializedStateAfterGeneration(JSON.stringify(values))
        setDownloadLink(getUploadUrl(singleGenDocFilePath, 'file'))
      })
    },
    validationSchema,
    successMessage: 'Документ успешно сгенерирован',
  })
  const serializedState = JSON.stringify(formik.values)
  return (
    <Segment size={2} title="Заполните данные">
      <Form formik={formik}>
        <FormItems>
          {uniqFields.map((f) => (
            <GenDocField field={f} key={f.label} formik={formik} />
          ))}
          <Alert {...alertProps} />
          {serializedState !== serializedStateAfterGeneration ? (
            <Button {...buttonProps}>Сгенерировать</Button>
          ) : (
            <Button href={downloadLink}>Скачать</Button>
          )}
        </FormItems>
      </Form>
    </Segment>
  )
}

const GenDocMassForm = ({ genDocTpl }) => {
  const [downloadLink, setDownloadLink] = useState('')
  // const [genDocSingle] = useMutation('genDocSingle')
  const { formik } = useForm({
    initialValues: {
      genDocDataFile: '',
      sendToSigneasy: true,
    },
  })
  // useEffect(() => {
  //   const selectedGenDocTpl = genDocTpls.find((genDocTpl) => genDocTpl.id === formik.values.genDocTplId)
  //   setGenDocTpl(selectedGenDocTpl)
  // }, [formik.values.genDocTplId, genDocTpls])

  return (
    <Segment size={2} title="Загрузите таблицу в формате CSV с данными">
      <FormItems>
        <Checkbox formik={formik} label={'Отправить на подписание по почте через SignEasy'} name="sendToSigneasy" />
        <UploadGenDocData
          name="genDocDataFile"
          formik={formik}
          setDownloadLink={setDownloadLink}
          genDocTpl={genDocTpl}
        />
        {!!downloadLink && <Button href={downloadLink}>Скачать архив с документами</Button>}
      </FormItems>
    </Segment>
  )
}

const GenDocPage = ({ genDocTpls, refetchGetGenTpls }) => {
  const [genDocTpl, setGenDocTpl] = useState(null)
  const { formik } = useForm({
    initialValues: {
      type: 'single',
    },
  })
  return (
    <Segment title="Генерация документов">
      <SelectGenDocTpl {...{ genDocTpls, refetchGetGenTpls, setGenDocTpl }} />
      {!!genDocTpl && (
        <Segment size={2} title="Тип генерации">
          <FormItems>
            {!!genDocTpls.length && (
              <RadioButtons direction="row" formik={formik} name="type">
                <RadioButton label={'Единичный'} value={'single'} formik={formik} name="type" />
                <RadioButton label={'Массовый'} value={'mass'} formik={formik} name="type" />
              </RadioButtons>
            )}
          </FormItems>
        </Segment>
      )}
      {formik.values.type === 'single' && !!genDocTpl && <GenDocSingleForm genDocTpl={genDocTpl} />}
      {formik.values.type === 'mass' && !!genDocTpl && <GenDocMassForm genDocTpl={genDocTpl} />}
    </Segment>
  )
}

const GetGenDocTplsWrapper = (props) => {
  const queryResult = useQuery('getGenDocTpls', {
    fetchPolicy: 'network-only',
  })
  return (
    <PageWrapper queryResult={queryResult}>
      {({ genDocTpls }) => <GenDocPage genDocTpls={genDocTpls} refetchGetGenTpls={queryResult.refetch} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canGenDoc(me)}>
    {() => <GetGenDocTplsWrapper {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
