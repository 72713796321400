import { gql } from '@apollo/client'

export const signUpMutation = gql`
  mutation($input: SignUpInput!) {
    signUp(input: $input) {
      token
      user {
        id
      }
    }
  }
`

export const signUpOptions = { parser: [['token', 'signUp.token'], ['user', 'signUp.user']] }
