import { gql } from '@apollo/client'

export const uploadMultipleImagesMutation = gql`
  mutation($input: UploadMultipleImagesInput!) {
    uploadMultipleImages(input: $input) {
      uploadedFiles {
        name
      }
    }
  }
`

export const uploadMultipleImagesOptions = {
  parser: [['uploadedFiles', 'uploadMultipleImages.uploadedFiles']],
}
