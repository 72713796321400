import { gql } from '@apollo/client'

export const getNewsPostQuery = gql`
  query ($input: GetNewsPostInput) {
    getNewsPost(input: $input) {
      newsPost {
        id
        serialNumber
        date
        deleted
        contentMd
        contentHtml
        photos
        propertyId
      }
    }
  }
`

export const getNewsPostOptions = {
  parser: [['newsPost', 'getNewsPost.newsPost']],
}
