import { gql } from '@apollo/client'
import { genDocTplFragment } from './genDocTplFragment'

export const getGenDocTplsQuery = gql`
  ${genDocTplFragment}
  query getGenDocTpls {
    getGenDocTpls {
      genDocTpls {
        ...genDocTplFragment
      }
    }
  }
`

export const getGenDocTplsOptions = {
  parser: [['genDocTpls', 'getGenDocTpls.genDocTpls']],
}
