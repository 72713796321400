import { gql } from '@apollo/client'
import { userFragment } from './userFragment'

export const updateUserMutation = gql`
  ${userFragment}
  mutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...userFragment
      }
    }
  }
`

export const updateUserOptions = { parser: [['user', 'updateUser.user']] }
