import { gql } from '@apollo/client'

export const getReferralNameByCodeQuery = gql`
  query ($input: GetReferralNameByCodeInput) {
    getReferralNameByCode(input: $input) {
      referralName
    }
  }
`

export const getReferralNameByCodeOptions = {
  parser: [['referralName', 'getReferralNameByCode.referralName']],
}
