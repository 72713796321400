import { gql } from '@apollo/client'

export const updateMeMutation = gql`
  mutation($input: UpdateMeInput!) {
    updateMe(input: $input) {
      me {
        id
        name
      }
    }
  }
`

export const updateMeOptions = { parser: [['me', 'updateMe.me']] }
