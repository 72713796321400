import { gql } from '@apollo/client'
import { companyResultFragment } from './companyResultFragment'

export const companyResultCreateMutation = gql`
  ${companyResultFragment}
  mutation ($input: CreateCompanyResultInput!) {
    companyResultCreate(input: $input) {
      companyResult {
        ...companyResultFragment
      }
    }
  }
`

export const companyResultCreateOptions = { parser: [['companyResult', 'companyResultCreate.companyResult']] }
