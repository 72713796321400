import PageWrapper from 'components/PageWrapper'
import { useEffect } from 'react'

export const ResultsPage = ({ me, history }) => {
  useEffect(() => {
    window.location.replace('https://telegra.ph/Nashi-rezultaty-04-17-2')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <PageWrapper queryResult={{ loading: true }} />
}
