import cn from 'classnames'
import Alert from 'components/Alert'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import PageWrapper from 'components/PageWrapper'
import ScrollToHash from 'components/ScrollToHash'
import Segment from 'components/Segment'
import { ReactComponent as Gratz } from 'assets/gratz.svg'
import { copyToClipboard } from 'lib/copyToClipboard'
import getUploadName from 'lib/getUploadName'
import getUploadUrl from 'lib/getUploadUrl'
import { plural } from 'lib/plural'
import toHumanDate from 'lib/toHumanDate'
import toHumanPropertyStatus from 'lib/toHumanPropertyStatus'
import toMoney from 'lib/toMoney'
import useQuery from 'lib/useQuery'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import css from './styles.module.scss'
import { useHistory, useLocation, Link as NativeLink } from 'react-router-dom'
import parseLocationSearch from 'lib/parseLocationSearch'
import { NewPaymentModal, useNewPaymentModal } from 'components/NewPaymentModal'
import Button from 'components/Button'
import { getPropertyRoute } from 'lib/routes'

const isMoneyInCurrenciesSame = (moneyInCurrencies1, moneyInCurrencies2) => {
  if (!moneyInCurrencies1 && !moneyInCurrencies2) {
    return true
  }
  if (!moneyInCurrencies1 || !moneyInCurrencies2) {
    return false
  }
  const toFixed = (value) => {
    return value.toFixed(2)
  }
  return (
    toFixed(moneyInCurrencies1.rub) === toFixed(moneyInCurrencies2.rub) &&
    toFixed(moneyInCurrencies1.euro) === toFixed(moneyInCurrencies2.euro) &&
    toFixed(moneyInCurrencies1.usd) === toFixed(moneyInCurrencies2.usd) &&
    toFixed(moneyInCurrencies1.usdt) === toFixed(moneyInCurrencies2.usdt)
  )
}

const getMoneyInCurrenciesArray = (moneyInCurrencies, existingCurrencies) => {
  const values = []
  if (existingCurrencies) {
    if (!!existingCurrencies.rub) {
      values.push(toMoney(moneyInCurrencies.rub, 'rub'))
    }
    if (!!existingCurrencies.euro) {
      values.push(toMoney(moneyInCurrencies.euro, 'euro'))
    }
    if (!!existingCurrencies.usd) {
      values.push(toMoney(moneyInCurrencies.usd, 'usd'))
    }
    if (!!existingCurrencies.usdt) {
      values.push(toMoney(moneyInCurrencies.usdt, 'usdt'))
    }
    if (!existingCurrencies.rub && !existingCurrencies.euro && !existingCurrencies.usd && !existingCurrencies.usdt) {
      values.push('0,00 ₽')
    }
  } else {
    if (!!moneyInCurrencies.rub) {
      values.push(toMoney(moneyInCurrencies.rub, 'rub'))
    }
    if (!!moneyInCurrencies.euro) {
      values.push(toMoney(moneyInCurrencies.euro, 'euro'))
    }
    if (!!moneyInCurrencies.usd) {
      values.push(toMoney(moneyInCurrencies.usd, 'usd'))
    }
    if (!!moneyInCurrencies.usdt) {
      values.push(toMoney(moneyInCurrencies.usdt, 'usdt'))
    }
    if (!moneyInCurrencies.rub && !moneyInCurrencies.euro && !moneyInCurrencies.usd && !moneyInCurrencies.usdt) {
      values.push('0,00 ₽')
    }
  }
  return values
}

const isMoneyInCurrenciesEmpty = (moneyInCurrencies) => {
  return (
    !moneyInCurrencies ||
    (!moneyInCurrencies.rub && !moneyInCurrencies.euro && !moneyInCurrencies.usd && !moneyInCurrencies.usdt)
  )
}

const MoneyInCurrencies = ({ moneyInCurrencies, existingCurrencies, textBefore }) => {
  return (
    <>
      {getMoneyInCurrenciesArray(moneyInCurrencies, existingCurrencies).map((value, index) => (
        <React.Fragment key={index}>
          {!!index && <br />}
          {textBefore}
          {value}
        </React.Fragment>
      ))}
    </>
  )
}

const getMoneyInCurrenciesInline = (moneyInCurrencies, existingCurrencies) => {
  return getMoneyInCurrenciesArray(moneyInCurrencies, existingCurrencies).join(', ')
}

export const SegmentHere = ({ className, children, id, ...restProps }) => {
  return (
    <div id={id} className={cn(className, css.segment)}>
      <Segment {...restProps}>{children}</Segment>
    </div>
  )
}

const Property = ({ propertyWithMoney, isFuture, canInvest, isFinished, isCancelled, isActive }) => {
  const { openNewPaymentModal } = useNewPaymentModal()
  return (
    <div className={css.item}>
      <NativeLink className={css.clickable} to={getPropertyRoute(propertyWithMoney.property.serialNumber)}>
        <div className={css.photo}>
          <img
            className={css.photoImage}
            width="662"
            height="496"
            alt=""
            src={getUploadUrl(propertyWithMoney.property.photo, 'property')}
          />
        </div>
        <div className={css.title}>{propertyWithMoney.property.name}</div>
        {isCancelled ? (
          <div className={css.info}>
            <div className={css.infoItem}>
              <span>Инвест. предложение</span> №{propertyWithMoney.property.serialNumber}
            </div>
            <div className={css.infoItem}>
              <span>Статус</span> {toHumanPropertyStatus(propertyWithMoney.property.status)}
            </div>
            <div className={css.infoItem}>
              <span>Доп. информация</span> {propertyWithMoney.property.message}
            </div>
          </div>
        ) : (
          <div className={css.info}>
            <div className={css.infoItem}>
              <span>Инвест. предложение</span> №{propertyWithMoney.property.serialNumber}
            </div>
            {!isFinished && (
              <div className={css.infoItem}>
                <span>Доходность</span> {propertyWithMoney.property.roi}
                {/* <span>ROI</span> {propertyWithMoney.property.minProfitPercent} – 
            {propertyWithMoney.property.maxProfitPercent}% */}
              </div>
            )}
            {!isMoneyInCurrenciesEmpty(propertyWithMoney.invested) && (
              <div className={css.infoItem}>
                <span>Инвестированно всего</span> {getMoneyInCurrenciesInline(propertyWithMoney.invested)}
              </div>
            )}
            {!isMoneyInCurrenciesSame(propertyWithMoney.invested, propertyWithMoney.investedCurrent) && (
              <div className={css.infoItem}>
                <span>Текущая инвестиция</span> {getMoneyInCurrenciesInline(propertyWithMoney.investedCurrent)}
              </div>
            )}
            {!isMoneyInCurrenciesEmpty(propertyWithMoney.profited) && (
              <div className={css.infoItem}>
                <span>Вы получили</span> {getMoneyInCurrenciesInline(propertyWithMoney.profited)}
              </div>
            )}
            {!isMoneyInCurrenciesEmpty(propertyWithMoney.profitedBody) && (
              <div className={css.infoItem}>
                <span>Из них "тело"</span> {getMoneyInCurrenciesInline(propertyWithMoney.profitedBody)}
              </div>
            )}
            {!isMoneyInCurrenciesEmpty(propertyWithMoney.profitedInterest) && (
              <div className={css.infoItem}>
                <span>Из них "прибыль"</span> {getMoneyInCurrenciesInline(propertyWithMoney.profitedInterest)}
              </div>
            )}
            <div className={css.infoItem}>
              <span>Статус</span> {toHumanPropertyStatus(propertyWithMoney.property.status)}
            </div>
            {!!propertyWithMoney.property.neededToCollectOriginal && (
              <div className={css.infoItem}>
                <span>Сумма сбора</span>{' '}
                {toMoney(
                  propertyWithMoney.property.neededToCollectOriginal,
                  propertyWithMoney.property.collectCurrency
                )}
              </div>
            )}
            {!!propertyWithMoney.property.leftToCollectOriginal && (
              <div className={css.infoItem}>
                <span>Осталось собрать</span>{' '}
                {toMoney(propertyWithMoney.property.leftToCollectOriginal, propertyWithMoney.property.collectCurrency)}
              </div>
            )}
            {!isFuture && (
              <div className={css.infoItem}>
                <span>Дата старта</span> {toHumanDate(propertyWithMoney.property.startsAt, 'D MMM YYYY')}
              </div>
            )}
            {isFinished && (
              <div className={css.infoItem}>
                <span>Дата завершения</span> {toHumanDate(propertyWithMoney.property.endsAt, 'D MMM YYYY')}
              </div>
            )}
            {isFinished && (
              <>
                <div className={css.infoItem}>
                  <span>Доходность проекта</span> {propertyWithMoney.property.roi}
                  {/* <span>ROI проекта</span> {propertyWithMoney.property.profitPercentTotal}% (
              {propertyWithMoney.property.realProfitPercent}% в годовых) */}
                </div>
                <div className={css.infoItem}>
                  <span>Ваша доходность</span> {propertyWithMoney.property.roi}
                  {/* <span>Ваш ROI</span> {propertyWithMoney.profitPercentTotal}% ({propertyWithMoney.profitPercentYear}% в
              годовых) */}
                </div>
              </>
            )}
          </div>
        )}
      </NativeLink>
      {isFuture && canInvest && (
        <div className={css.newPaymentPlace}>
          <Button
            color="white"
            className={css.newPaymentButton}
            onClick={() =>
              openNewPaymentModal({
                propertySerialNumber: propertyWithMoney.property.serialNumber,
                sum: '',
              })
            }
          >
            Инвестировать
          </Button>
        </div>
      )}
    </div>
  )
}

const SummaryItem = ({ children, index, setItemsOffsets, parentOffset, scrollLeft, scrollWidth, clientWidth }) => {
  const elRef = useRef(null)
  useEffect(() => {
    if (elRef.current) {
      setItemsOffsets((itemsOffsets) => {
        const newItemsOffsets = [...itemsOffsets]
        newItemsOffsets[index] = elRef.current.getBoundingClientRect().left - parentOffset
        return newItemsOffsets
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentOffset, scrollLeft, scrollWidth, clientWidth, elRef])
  return (
    <div ref={elRef} className={css.item}>
      {children}
    </div>
  )
}

const ShorBalanceItems2 = ({ dashboardData, existingCurrencies }) => {
  const data = Object.entries(existingCurrencies)
    .filter(([key, value]) => !!value)
    .map(([key, value]) => {
      return {
        currency: key,
        total: dashboardData.moneyTotal[key],
        free: dashboardData.moneyFree[key],
        willBeProfited: dashboardData.moneyWillBeProfited[key],
        referralWillBeProfited: dashboardData.moneyReferralWillBeProfited[key],
      }
    })
  if (!data.length) {
    return (
      <div className={css.shortBalanceItems2}>
        <div className={css.mainItem}>
          <div className={css.total}>0,00 ₽</div>
          <div className={css.free}>свободно 0,00 ₽</div>
        </div>
      </div>
    )
  }
  return (
    <div className={css.shortBalanceItems2}>
      {data.map((item, i) => {
        return (
          <div className={css.mainItem} key={i}>
            <div className={css.total}>{toMoney(item.total, item.currency)}</div>
            <div className={css.free}>свободно {toMoney(item.free, item.currency)}</div>
          </div>
        )
      })}
      {!isMoneyInCurrenciesEmpty(dashboardData.moneyWillBeProfited) ? (
        <div className={css.scondaryItem}>
          <div className={css.values}>
            {data.map((item, i) => {
              return !item.willBeProfited ? null : (
                <div className={css.value} key={i}>
                  от {toMoney(item.willBeProfited, item.currency)}
                </div>
              )
            })}
          </div>
          <div className={css.label}>ожидаемая прибыль</div>
        </div>
      ) : (
        <div className={css.scondaryItem}>
          <div className={css.values}>
            <div className={cn(css.value, css.sad)}>{toMoney(0, 'rub')}</div>
          </div>
          <div className={css.label}>ожидаемая прибыль</div>
        </div>
      )}
      {!isMoneyInCurrenciesEmpty(dashboardData.moneyReferralWillBeProfited) ? (
        <div className={css.scondaryItem}>
          <div className={css.values}>
            {data.map((item, i) => {
              return !item.referralWillBeProfited ? null : (
                <div className={css.value} key={i}>
                  от {toMoney(item.referralWillBeProfited, item.currency)}
                </div>
              )
            })}
          </div>
          <div className={css.label}>ожидаемые реферальные бонусы</div>
        </div>
      ) : (
        <div className={css.scondaryItem}>
          <div className={css.values}>
            <div className={cn(css.value, css.sad)}>{toMoney(0, 'rub')}</div>
          </div>
          <div className={css.label}>ожидаемые реферальные бонусы</div>
        </div>
      )}
    </div>
  )
}

const Summary = ({ dashboardData, canInvest, existingCurrencies }) => {
  const scrollableElRef = useRef(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [clientWidth, setClientWidth] = useState(0)
  const [leftArrowVisible, setLeftArrowVisible] = useState(false)
  const [rightArrowVisible, setRightArrowVisible] = useState(false)
  const [parentOffset, setParentOffset] = useState(0)
  const [itemsOffsets, setItemsOffsets] = useState([0, 0, 0, 0, 0])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const updateScrollData = () => {
    setScrollLeft(scrollableElRef.current?.scrollLeft || 0)
    setScrollWidth(scrollableElRef.current?.scrollWidth || 0)
    setClientWidth(scrollableElRef.current?.clientWidth || 0)
    setParentOffset(scrollableElRef.current?.getBoundingClientRect().left || 0)
  }
  useEffect(() => {
    updateScrollData()
    const scrollableElRefCurrent = scrollableElRef.current
    scrollableElRefCurrent?.addEventListener('scroll', updateScrollData)
    return () => {
      scrollableElRefCurrent?.removeEventListener('scroll', updateScrollData)
    }
  }, [scrollableElRef])
  useLayoutEffect(() => {
    window.addEventListener('resize', updateScrollData)
    return () => window.removeEventListener('resize', updateScrollData)
  }, [])
  useEffect(() => {
    setLeftArrowVisible(scrollLeft > 0)
    setRightArrowVisible(scrollLeft + clientWidth < scrollWidth)
  }, [scrollLeft, scrollWidth, clientWidth])
  const { openNewPaymentModal } = useNewPaymentModal()

  return (
    <SegmentHere id="summary" className={css.summarySegment}>
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} title="Баланс">
        <div className={css.balanceItems}>
          <div className={css.balanceItem}>
            <div className={css.title}>Всего</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyTotal}
                existingCurrencies={null && existingCurrencies}
              />
            </div>
          </div>
          <div className={css.balanceItem}>
            <div className={css.title}>В объектах</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyInProperties}
                existingCurrencies={null && existingCurrencies}
              />
            </div>
          </div>
          <div className={cn({ [css.balanceItem]: true, [css.green]: true })}>
            <div className={css.title}>Ожидаемая прибыль</div>
            <div className={css.value}>
              <MoneyInCurrencies
                textBefore="от "
                moneyInCurrencies={dashboardData.moneyWillBeProfited}
                existingCurrencies={null && existingCurrencies}
              />
            </div>
          </div>
          <div
            className={cn({
              [css.balanceItem]: true,
              [css.green]: !isMoneyInCurrenciesEmpty(dashboardData.moneyReferralWillBeProfited),
            })}
          >
            <div className={css.title}>Ожидаемые реферальные бонусы</div>
            <div className={css.value}>
              <MoneyInCurrencies
                textBefore={isMoneyInCurrenciesEmpty(dashboardData.moneyReferralWillBeProfited) ? '' : 'от '}
                moneyInCurrencies={dashboardData.moneyReferralWillBeProfited}
                existingCurrencies={null && existingCurrencies}
              />
            </div>
          </div>
          <div className={css.balanceItem}>
            <div className={css.title}>Свободных</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyFree}
                existingCurrencies={null && existingCurrencies}
              />
            </div>
          </div>
          <div className={css.balanceItem}>
            <div className={css.title}>Заработано</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyProfited}
                existingCurrencies={null && existingCurrencies}
              />
            </div>
          </div>
          <div className={css.balanceItem}>
            <div className={css.title}>Выведено</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyWithdrawled}
                existingCurrencies={null && existingCurrencies}
              />
            </div>
          </div>
          <div className={css.balanceItem}>
            <div className={css.title}>Вложено</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyInvested}
                existingCurrencies={existingCurrencies}
              />
            </div>
          </div>
          <div className={css.balanceItem}>
            <div className={css.title}>Подарочные бонусы</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyDepositBonus}
                existingCurrencies={existingCurrencies}
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className={css.coinsTopBgOuter}>
        <img src="/images/coins-top-bg.png" width="360" height="205" className={css.coinsTopBg} alt="" />
      </div>
      {/* <div className={css.shortBalanceItems}>
        <div className={css.balanceItem}>
          <div className={css.title}>В объектах</div>
          <div className={css.value}>
            <MoneyInCurrencies
              moneyInCurrencies={dashboardData.moneyInProperties}
              existingCurrencies={existingCurrencies}
            />
          </div>
        </div>
        <div className={cn(css.balanceItem, css.green)}>
          <div className={css.title}>Ожидаемая прибыль</div>
          <div className={css.value}>
            <MoneyInCurrencies
              moneyInCurrencies={dashboardData.moneyWillBeProfited}
              existingCurrencies={existingCurrencies}
            />
          </div>
        </div>
      </div> */}
      <ShorBalanceItems2 dashboardData={dashboardData} existingCurrencies={existingCurrencies} />
      {/* <div className={css.summary}>
        <div
          className={cn({ [css.leftArrowWrapper]: true, [css.arrowWrapper]: true, [css.visible]: leftArrowVisible })}
          onClick={() => {
            const nextItemIndex = _.findLastIndex(itemsOffsets, (offset) => offset < 0)
            if (nextItemIndex === -1) {
              return
            }
            const nextItemOffset = itemsOffsets[nextItemIndex]
            scrollableElRef.current.scrollTo({
              left: scrollLeft + nextItemOffset - 10,
              behavior: 'smooth',
            })
          }}
        >
          <div className={css.round}>
            <Icon name="slider-left-arrow" className={css.icon} />
          </div>
        </div>
        <div
          className={cn({ [css.rightArrowWrapper]: true, [css.arrowWrapper]: true, [css.visible]: rightArrowVisible })}
          onClick={() => {
            const nextItemIndex = itemsOffsets.findIndex((offset) => offset > 20)
            if (nextItemIndex === -1) {
              return
            }
            const nextItemOffset = itemsOffsets[nextItemIndex]
            scrollableElRef.current.scrollTo({
              left: scrollLeft + nextItemOffset - 10,
              behavior: 'smooth',
            })
          }}
        >
          <div className={css.round}>
            <Icon name="slider-right-arrow" className={css.icon} />
          </div>
        </div>
        <div className={css.scrollable} ref={scrollableElRef}>
          <SummaryItem
            index={0}
            setItemsOffsets={setItemsOffsets}
            parentOffset={parentOffset}
            scrollLeft={scrollLeft}
            scrollWidth={scrollWidth}
            clientWidth={clientWidth}
          >
            <div className={css.title}>Всего</div>
            <div className={css.value}>
              <MoneyInCurrencies moneyInCurrencies={dashboardData.moneyTotal} existingCurrencies={existingCurrencies} />
            </div>
          </SummaryItem> 
          <SummaryItem
            index={1}
            setItemsOffsets={setItemsOffsets}
            parentOffset={parentOffset}
            scrollLeft={scrollLeft}
            scrollWidth={scrollWidth}
            clientWidth={clientWidth}
          >
            <div className={css.title}>В объектах</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyInProperties}
                existingCurrencies={existingCurrencies}
              />
            </div>
          </SummaryItem>
          <SummaryItem
            index={2}
            setItemsOffsets={setItemsOffsets}
            parentOffset={parentOffset}
            scrollLeft={scrollLeft}
            scrollWidth={scrollWidth}
            clientWidth={clientWidth}
          >
            <div className={css.title}>Свободных</div>
            <div className={css.value}>
              <MoneyInCurrencies moneyInCurrencies={dashboardData.moneyFree} existingCurrencies={existingCurrencies} />
            </div>
          </SummaryItem>
          <SummaryItem
            index={3}
            setItemsOffsets={setItemsOffsets}
            parentOffset={parentOffset}
            scrollLeft={scrollLeft}
            scrollWidth={scrollWidth}
            clientWidth={clientWidth}
          >
            <div className={css.title}>Заработано</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyProfited}
                existingCurrencies={existingCurrencies}
              />
            </div>
          </SummaryItem>
          <SummaryItem
            index={4}
            setItemsOffsets={setItemsOffsets}
            parentOffset={parentOffset}
            scrollLeft={scrollLeft}
            scrollWidth={scrollWidth}
            clientWidth={clientWidth}
          >
            <div className={css.title}>Выведено</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyWithdrawled}
                existingCurrencies={existingCurrencies}
              />
            </div>
          </SummaryItem>
          <SummaryItem
            index={5}
            setItemsOffsets={setItemsOffsets}
            parentOffset={parentOffset}
            scrollLeft={scrollLeft}
            scrollWidth={scrollWidth}
            clientWidth={clientWidth}
          >
            <div className={css.title}>Вложено</div>
            <div className={css.value}>
              <MoneyInCurrencies
                moneyInCurrencies={dashboardData.moneyInvested}
                existingCurrencies={existingCurrencies}
              />
            </div>
          </SummaryItem>
        </div>
      </div> */}
      <div className={css.summaryButtonsPlace}>
        <Button color="blue" onClick={() => setIsModalOpen(true)}>
          Подробнее о балансе
        </Button>
        {canInvest && (
          <Button className={css.newPaymentButton} onClick={() => openNewPaymentModal()}>
            Пополнить баланс
          </Button>
        )}
      </div>
    </SegmentHere>
  )
}

export const DashboardData = ({ dashboardData, propertiesWithMoneyFuture, canInvest = false }) => {
  const propertiesWithMoneyActive = dashboardData.propertiesWithMoney.filter((propertyWithMoney) =>
    ['processing', 'payoutPrepearing', 'foundrising'].includes(propertyWithMoney.property.status)
  )

  const propertiesWithMoneyFinished = dashboardData.propertiesWithMoney.filter((propertyWithMoney) =>
    ['finished', 'cancelled'].includes(propertyWithMoney.property.status)
  )

  const existingCurrencies = [
    dashboardData.moneyTotal,
    dashboardData.moneyFree,
    dashboardData.moneyInProperties,
    dashboardData.moneyWillBeProfited,
    dashboardData.moneyReferralWillBeProfited,
    dashboardData.moneyProfited,
    dashboardData.moneyWithdrawled,
    dashboardData.moneyDepositBonus,
  ].reduce(
    (acc, money) => {
      return {
        rub: acc.rub || !!money.rub,
        euro: acc.euro || !!money.euro,
        usd: acc.usd || !!money.usd,
        usdt: acc.usdt || !!money.usdt,
      }
    },
    { rub: false, euro: false, usd: false, usdt: false }
  )

  return (
    <>
      <Summary canInvest={canInvest} dashboardData={dashboardData} existingCurrencies={existingCurrencies} />

      <TransactionsBlock txs={dashboardData.txs} />

      {!!propertiesWithMoneyFuture && (
        <SegmentHere title="Доступные инвестиционные предложения" id="future">
          {!propertiesWithMoneyFuture?.length ? (
            <Alert compact message="На данный момент нет запланированных объектов" />
          ) : (
            <div className={css.properties}>
              {propertiesWithMoneyFuture.map((propertyWithMoney) => (
                <Property
                  key={propertyWithMoney.property.name}
                  isFuture={true}
                  canInvest={canInvest}
                  propertyWithMoney={propertyWithMoney}
                />
              ))}
            </div>
          )}
        </SegmentHere>
      )}

      <SegmentHere title="Инвестиционный портфель" id="portfolio">
        {!propertiesWithMoneyActive.length ? (
          <Alert compact message="В вашем портфолио пока нет активных объектов" />
        ) : (
          <div className={css.properties}>
            {[...propertiesWithMoneyActive].reverse().map((propertyWithMoney) => (
              <Property key={propertyWithMoney.property.name} isActive={true} propertyWithMoney={propertyWithMoney} />
            ))}
          </div>
        )}
      </SegmentHere>

      <SegmentHere title="Завершенные объекты" id="finished">
        {!propertiesWithMoneyFinished.length ? (
          <Alert compact message="В вашем портфолио пока нет завершённых объектов" />
        ) : (
          <div className={css.properties}>
            {propertiesWithMoneyFinished.map((propertyWithMoney) => (
              <Property
                key={propertyWithMoney.property.name}
                isFinished={propertyWithMoney.property.status === 'finished'}
                isCancelled={propertyWithMoney.property.status === 'cancelled'}
                propertyWithMoney={propertyWithMoney}
              />
            ))}
          </div>
        )}
      </SegmentHere>
    </>
  )
}

const Transactions = ({ txs }) => {
  return (
    <div className={css.txs}>
      {txs.map((tx) => (
        <div className={css.tx} key={tx.id}>
          <div
            className={cn({
              [css.iconRound]: true,
              // [css.green]: ['investmentIncome', 'payoutIncome', 'payoutOutcome'].includes(tx.type),
              [css.green]: [
                'payoutBody',
                'payoutInterest',
                'payoutReferral',
                'payoutTeam',
                'payoutTeamGeneral',
              ].includes(tx.type),
              // [css.blue]: ['depositInitialIncome', 'depositAdditionalIncome', 'depositOutcome'].includes(tx.type),
              [css.blue]: [
                'depositInitialIncome',
                'depositBonus',
                'depositOutcome',
                'depositTransferPlus',
                'depositTransferMinus',
              ].includes(tx.type),
              [css.orange]: ['investmentOutcome'].includes(tx.type),
              [css.gray]: ['depositConvertation', 'newReferral'].includes(tx.type),
            })}
          >
            {['investmentOutcome'].includes(tx.type) ? (
              <Icon name="new-building" className={css.icon} />
            ) : ['depositConvertation'].includes(tx.type) ? (
              <Icon name="tx-convertation" className={css.icon} />
            ) : ['depositOutcome', 'depositTransferPlus', 'depositTransferMinus'].includes(tx.type) ? (
              <Icon name="tx-minus" className={css.icon} />
            ) : [
                'depositInitialIncome',
                'payoutTeamGeneral',
                'depositBonus',
                // 'depositAdditionalIncome',
                // 'investmentIncome',
                // 'payoutIncome',
                // 'payoutOutcome',
                'payoutBody',
                'payoutInterest',
                'payoutReferral',
                'payoutTeam',
                'newReferral',
              ].includes(tx.type) ? (
              <Icon name="tx-plus" className={css.icon} />
            ) : null}
          </div>
          <div className={css.infoAndMoney}>
            <div className={css.info}>
              <div className={css.infoMain}>
                {
                  {
                    depositInitialIncome: 'Пополнение баланса',
                    payoutTeamGeneral: 'Выплата командного бонуса',
                    depositBonus: 'Подарочный бонус',
                    // depositAdditionalIncome: 'Довложение в депозит',
                    depositConvertation: 'Конвертация в депозите',
                    depositOutcome: 'Вывод с баланса',
                    depositTransferPlus: 'Перевод от другого инвестора',
                    depositTransferMinus: 'Перевод другому инвестору',
                    investmentOutcome: `Вложение в объект №${tx.property?.serialNumber}`,
                    // investmentIncome: `Возврат инвестиции с объекта №${tx.property?.serialNumber}`,
                    // payoutIncome: `Доход с объекта №${tx.property?.serialNumber} (в депозит)`,
                    // payoutOutcome: `Доход с объекта №${tx.property?.serialNumber} (на руки)`,
                    payoutBody: `Выплата тела с объекта №${tx.property?.serialNumber}`,
                    payoutInterest: `Выплата процента с объекта №${tx.property?.serialNumber}`,
                    payoutReferral: `Выплата реферального бонуса`,
                    payoutTeam: `Выплата командного бонуса`,
                    newReferral: `Новый приглашённый`,
                  }[tx.type]
                }
              </div>
              {!!tx.property && (
                <div className={css.infoSecondary}>
                  <NativeLink to={getPropertyRoute(tx.property.serialNumber)}>{tx.property.name}</NativeLink>
                </div>
              )}
              {tx.type === 'newReferral' && (
                <div className={css.infoSecondary}>
                  По вашему приглашению зарегистрировался новый инвестор {tx.extraText}
                </div>
              )}
            </div>
            <div
              className={cn({
                [css.money]: true,
                [css.green]: [
                  // 'investmentIncome',
                  // 'payoutIncome',
                  // 'payoutOutcome',
                  'depositInitialIncome',
                  'depositBonus',
                  'payoutTeamGeneral',
                  // 'depositAdditionalIncome',
                  'payoutBody',
                  'payoutInterest',
                  'payoutReferral',
                  'payoutTeam',
                ].includes(tx.type),
              })}
            >
              {tx.type === 'depositConvertation' ? (
                <>
                  <div className={css.moneyItem}>{toMoney(tx.sumMinus, tx.currencyMinus)}</div>
                  <div className={css.moneyItem}>+{toMoney(tx.sumPlus, tx.currencyPlus)}</div>
                </>
              ) : tx.type === 'newReferral' ? (
                <></>
              ) : (
                <div className={css.moneyItem}>
                  {tx.type === 'investmentOutcome' ? '-' : tx.sum > 0 ? '+' : ''}
                  {toMoney(tx.sum, tx.currency)}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const TransactionsBlock = ({ txs }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const txsWithDate = txs.map((tx) => ({ ...tx, date: moment(tx.createdAt).format('D MMMM YYYY') }))
  const groupedTxs = []
  for (const tx of txsWithDate) {
    const group = groupedTxs.find((group) => group.date === tx.date)
    if (group) {
      group.txs.push(tx)
    } else {
      groupedTxs.push({ date: tx.date, txs: [tx] })
    }
  }
  return (
    <SegmentHere
      title="Операции"
      id="txs"
      rightRender={
        txs.length > 3 ? (
          <a
            href="#txs"
            onClick={(e) => {
              e.preventDefault()
              setModalOpen(true)
            }}
            className={css.moreTxs}
          >
            Все операции
          </a>
        ) : null
      }
    >
      {!txs.length ? (
        <Alert compact message="Вы пока не совершали никаких транзакций" />
      ) : (
        <Transactions txs={txs.slice(0, 3)} />
      )}
      <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        {groupedTxs.map(({ date, txs: groupTxs }) => (
          <div key={date} className={css.txsGroup}>
            <div className={css.txsGroupTitle}>{date}</div>
            <Transactions txs={groupTxs} />
          </div>
        ))}
      </Modal>
    </SegmentHere>
  )
}

const Documents = ({ documents }) => {
  const hasHiddenDocuments = documents.length > 2
  const [showAll, setShowAll] = useState(!hasHiddenDocuments)
  const docs = showAll ? documents : documents.slice(0, 2)
  return (
    <div className={css.documents}>
      <div className={css.list}>
        {docs.map((document, i) => (
          <a key={i} className={css.document} rel="noreferrer" target="_blank" href={getUploadUrl(document, 'file')}>
            <Icon name="new-docs" className={css.icon} />
            <span className={css.name}>{getUploadName(document)}</span>
          </a>
        ))}
      </div>
      {!showAll && (
        <div className={css.more}>
          <a
            href="#all"
            onClick={(e) => {
              e.preventDefault()
              setShowAll(true)
            }}
          >
            Показать все
          </a>
        </div>
      )}
    </div>
  )
}

export const HaveQuestions = () => {
  return (
    <SegmentHere title="Если у вас есть вопросы" id="help">
      <div className={css.questions}>
        <p className={css.intro}>Вы всегда можете их написать в чат поддержки</p>
        <a rel="noreferrer" className={css.button} href="https://t.me/Estate_invest_RF_bot" target="_blank">
          <Icon name="telegram" className={css.buttonIcon} />
          <span className={css.buttonText}>Написать в поддержку</span>
        </a>
        <Alert
          className={css.message}
          compact
          title="Обязательно"
          message="Нажмите «Запустить» и задайте любой вопрос, мы обязательно ответим."
        />
      </div>
    </SegmentHere>
  )
}

const WelcomeModal = () => {
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const location = useLocation()
  const parsedLocationSearch = parseLocationSearch(location.search)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  useEffect(() => {
    if (parsedLocationSearch.modal === 'welcome') {
      setEmail(parsedLocationSearch.email)
      setPassword(parsedLocationSearch.password)
      setModalOpen(true)
      history.replace(location.pathname)
    }
  }, [parsedLocationSearch])
  return (
    <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
      <div className={css.welcomeModal}>
        <div className={css.gratz}>
          <Gratz className={css.icon} />
        </div>
        <h2 className={css.title}>Анкета успешно отправлена</h2>
        <div className={css.panel}>
          <Icon name="info" className={css.icon} />
          <h3 className={css.panelTitle}>Что дальше?</h3>
          <p className={css.panelDesc}>
            Теперь важно подписаться на нашего телеграм-бота, чтобы наш сотрудник смог с вами связаться.
            <br />
            <br />
            Просто перейдите в телеграм по кнопке ниже и нажмите «Запустить бота».
          </p>
        </div>
        <div className={css.buttonPlace}>
          <a rel="noreferrer" className={css.button} href="https://t.me/Estate_invest_RF_bot" target="_blank">
            <Icon name="telegram" className={css.buttonIcon} />
            <span className={css.buttonText}>Перейти в телеграм</span>
          </a>
        </div>
        {!!email && !!password && (
          <>
            <div className={css.panel}>
              <Icon name="dashboard" className={css.icon} />
              <h3 className={css.panelTitle}>Личный кабинет</h3>
              <p className={css.panelDesc}>
                Мы создали для вас аккаунт в нашей системе и автоматически авторизировали вас в личном кабинете.
                <br />
                <br />
                Вот ваши данные для входа. Мы также отправили эти данные вам на почту.
                <br />
                <br />
                Электронная почта: <b>{email}</b>
                <br />
                Пароль: <b>{password}</b>
              </p>
            </div>
            <div className={css.buttonPlace}>
              <button rel="noreferrer" className={css.button} onClick={() => setModalOpen(false)}>
                <Icon name="dashboard" className={css.buttonIcon} />
                <span className={css.buttonText}>Перейти в личный кабинет</span>
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

const PleaseSignContract = () => {
  return (
    <div className={css.pleaseSignContract}>
      <Alert
        type="orange"
        withIcon={false}
        className={css.message}
        compact
        title="Чтобы начать инвестировать"
        message={
          <>
            Вам необходимо подписать договор инвестирования. Для этого свяжитесь с нашим менеджером в телеграме{' '}
            <a href="https://t.me/Estate_invest_RF_bot" target="_blank" rel="noreferrer">
              @Estate_invest_RF_bot
            </a>
          </>
        }
      />
    </div>
  )
}

const DashboardPage = ({ dashboardData, propertiesWithMoneyFuture, me }) => {
  const dummyReferralsArray = me.dummyReferrals ? me.dummyReferrals.split(',').map((dr) => dr.trim()) : []
  const canInvest = me && !!me.contractNumber && !!me.contractDate
  return (
    <PageWrapper>
      {() => (
        <div className={css.dashboard}>
          {(!me.contractNumber || me.contractNumber.includes('?')) && <PleaseSignContract />}
          <WelcomeModal />
          <NewPaymentModal me={me} dashboardData={dashboardData} />
          <Segment className={css.wrapper}>
            {/* <div className={css.topBg}>
              <div className={css.inner}>
                <img alt="" src="/images/top-bg.png" width="1792" height="530" className={css.image} />
              </div>
            </div> */}
            <DashboardData
              dashboardData={dashboardData}
              propertiesWithMoneyFuture={propertiesWithMoneyFuture}
              canInvest={canInvest}
            />

            <SegmentHere title="Реферальная система" id="referral">
              {!me.dummyReferralUrl ? (
                <div className={css.referral}>
                  <Alert
                    className={css.empty}
                    compact
                    message={
                      <>
                        <a href="https://t.me/Estate_invest_RF_bot" target="_blank" rel="noreferrer">
                          Свяжитесь с нашим менеджером,
                        </a>{' '}
                        чтобы начать участвовать в реферальной системе
                      </>
                    }
                  />
                </div>
              ) : (
                <div className={css.referral}>
                  <p className={css.intro}>
                    Вы получаете <b>10% каждый раз</b> от созданной чистой прибыли вашего приглашенного инвестора.{' '}
                  </p>
                  {!!dummyReferralsArray.length ? (
                    <div className={css.list}>
                      <div className={css.listHeader}>
                        Вы пригласили {plural(dummyReferralsArray.length, ['человека', 'человека', 'человек'], true)}
                      </div>
                      <div className={css.listItems}>
                        {dummyReferralsArray.map((referral) => (
                          <div className={css.listItem} key={referral}>
                            {referral}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Alert className={css.empty} compact message="Вы пока никого не пригласили" />
                  )}
                  <div className={css.blocks}>
                    <div className={cn(css.block, css.first)}>
                      <div className={css.blockInner}>
                        <p className={css.blockIntro}>
                          Для приглашения в проект отправьте человеку вашу личную ссылку-приглашение:
                        </p>
                        {navigator.share ? (
                          <div
                            className={css.link}
                            onClick={() => {
                              navigator.share({
                                title: 'Инвестиционная возможность',
                                url: me.dummyReferralUrl,
                              })
                            }}
                          >
                            <p className={css.linkText}>{me.dummyReferralUrl}</p>
                            <div className={css.linkIcon}>
                              <Icon name="share" />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={css.link}
                            onClick={() => {
                              copyToClipboard(me.dummyReferralUrl)
                            }}
                          >
                            <p className={css.linkText}>{me.dummyReferralUrl}</p>
                            <div className={css.linkIcon}>
                              <Icon name="copy" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={cn(css.block, css.second)}>
                      <div className={css.blockInner}>
                        <img className={css.image} alt="" src="/images/referral-bg.png" width="165" height="165" />
                        {me.generatedPresentation ? (
                          <>
                            <p className={css.blockIntro}>Презентация проекта с вашей личной реферальной ссылкой</p>
                            <a
                              className={css.button}
                              rel="noreferrer"
                              target="_blank"
                              href={getUploadUrl(me.generatedPresentation, 'file')}
                            >
                              Скачать
                            </a>
                          </>
                        ) : (
                          <>
                            <p className={css.blockIntro}>
                              Презентация проекта с вашей личной реферальной ссылкой ещё не готова. Вы моежете
                              обратиться к нашему менеджеру, чтобы узнать, когда презентация будет готова
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </SegmentHere>
            {/* 
          <SegmentHere title="Внесите свои данные в калькулятор" id="calc">
            <RichText>
              <p>
                В подготовленной Google таблице. Здесь вы можете вставить сумму и посмотреть примерную доходность:{' '}
                <Link
                  color="system-links-blue"
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/10ILGU3165f0U-2MssVMf2atF_n-FudG7c3660NZ9KYo/edit?usp=drivesdk"
                >
                  перейти в калькулятор
                </Link>
              </p>
            </RichText>
          </SegmentHere> */}

            <SegmentHere title="Договоры и документы" id="contract">
              {!me.documents.length ? (
                <Alert compact message="У вас ещё нет подписанных документов" />
              ) : (
                <Documents documents={me.documents} />
              )}
            </SegmentHere>

            <HaveQuestions />
            <div className={css.bottomBg}>
              <div className={css.inner}>
                <img alt="" src="/images/bottom-bg.png" width="1928" height="999" className={css.image} />
              </div>
            </div>
            <ScrollToHash />
          </Segment>
        </div>
      )}
    </PageWrapper>
  )
}

const GetFuturePropertiesWrapper = (props) => {
  const queryResult = useQuery('getProperties', {
    variables: {
      input: {
        limit: 99,
      },
    },
  })

  return (
    <PageWrapper queryResult={queryResult}>
      {({ properties }) => {
        const propertiesWithMoney = props.dashboardData.propertiesWithMoney
        const propertiesFuture = properties.filter((property) => property.status === 'foundrising')
        const propertiesWithMoneyFuture = []
        for (const property of propertiesFuture) {
          const propertyWithMoney = propertiesWithMoney.find(
            (propertyWithMoney) => propertyWithMoney.property.id === property.id
          )
          if (propertyWithMoney) {
            propertiesWithMoneyFuture.push(propertyWithMoney)
          } else {
            propertiesWithMoneyFuture.push({
              property,
            })
          }
        }
        return <DashboardPage propertiesWithMoneyFuture={propertiesWithMoneyFuture} {...props} />
      }}
    </PageWrapper>
  )
}

const GetDashboardDataWrapper = (props) => {
  const queryResult = useQuery('getMyDashboardData')
  return (
    <PageWrapper queryResult={queryResult}>
      {({ dashboardData }) => <GetFuturePropertiesWrapper dashboardData={dashboardData} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper authorizedOnly>{({ me }) => <GetDashboardDataWrapper me={me} {...props} />}</PageWrapper>
)

export default CheckAuthWrapper
