import { gql } from '@apollo/client'
import { propertyFragment } from './propertyFragment'

export const getPropertiesQuery = gql`
  ${propertyFragment}
  query getProperties($input: GetPropertiesInput) {
    getProperties(input: $input) {
      properties {
        ...propertyFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const getPropertiesOptions = {
  parser: [
    ['properties', 'getProperties.properties'],
    ['pageInfo', 'getProperties.pageInfo'],
  ],
}
