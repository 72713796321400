import { gql } from '@apollo/client'

export const getReferralsQuery = gql`
  query getReferrals($input: GetReferralsInput) {
    getReferrals(input: $input) {
      referrals {
        id
        displayName
        createdAt
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const getReferralsOptions = {
  parser: [
    ['referrals', 'getReferrals.referrals'],
    ['pageInfo', 'getReferrals.pageInfo'],
  ],
}
