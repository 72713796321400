import React, { useEffect, useState } from 'react'
import Input from 'components/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import Link from 'components/Link'
import { useForm } from 'components/Form'
import yup from 'lib/validation'
import useMutation from 'lib/useMutation'

export const usePhoneVerification = ({
  onChange,
  purpose,
  formik,
  phoneLabel = 'Телефон',
  requestButton = 'Получить код подтверждения',
  checkButton = 'Отправить код',
  checkSuccessMessage = 'Вы успешно подтвердили владение номером телефона',
  hideButton = false,
}) => {
  const [step, setStep] = useState('request') // request → check → success
  const [validVerificationCode, setValidVerificationCode] = useState('') // request → check → success
  const [requestPhoneVerificationCode] = useMutation('requestPhoneVerificationCode', {
    errorCodes: {
      ERR_USER_NOT_FOUND: 'Пользователь с такой почтой не найден',
      ERR_COMPANY_NOT_FOUND: 'Компания с таким идентификатором не найдена',
    },
  })
  const [checkPhoneVerificationCode] = useMutation('checkPhoneVerificationCode')
  const {
    formik: formikRequest,
    buttonProps: buttonPropsRequest,
    alertProps: alertPropsRequest,
    forgetSubmitResult: forgetSubmitResultRequest,
    setError: setErrorRequest,
    reset: resetRequest,
  } = useForm({
    onSubmit: ({ phone }) => {
      return requestPhoneVerificationCode({
        variables: { input: { phone, purpose } },
      }).then(() => {
        setStep('check')
      })
    },
    initialValues: {
      phone: '',
    },
    validationSchema: yup.object({
      phone: yup.string().required(),
    }),
    successMessage: 'Код подтверждения был отправлен вам по смс',
  })
  const {
    formik: formikCheck,
    buttonProps: buttonPropsCheck,
    alertProps: alertPropsCheck,
    reset: resetCheck,
  } = useForm({
    onSubmit: ({ verificationCode }) => {
      return checkPhoneVerificationCode({
        variables: { input: { verificationCode, purpose, phone: formikRequest.values.phone } },
      })
        .then(() => {
          forgetSubmitResultRequest()
          setValidVerificationCode(verificationCode)
          setStep('success')
        })
        .catch(err => {
          if (err.message === 'ERR_ATTEMPTS_EXCEEDED_CONFIRM_PHONE_CODE') {
            setTimeout(() => {
              resetCheck()
            }, 1)
            setErrorRequest(err.humanMessage)
            setStep('request')
          }
          throw err
        })
    },
    initialValues: {
      verificationCode: '',
    },
    validationSchema: yup.object({
      verificationCode: yup.string().required(),
    }),
    successMessage: checkSuccessMessage,
  })
  const onChangeArg = {
    step,
    verificationCode: step === 'success' ? validVerificationCode : '',
    phone: step === 'success' ? formikRequest.values.phone : '',
  }
  useEffect(() => {
    onChange && onChange(onChangeArg)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(onChangeArg)])
  return {
    formikInForm: { request: formikRequest, check: formikCheck, success: formik }[step],
    formikRequest,
    formikCheck,
    forgetSubmitResultRequest,
    resetCheck,
    setValidVerificationCode,
    step,
    setStep,
    alertPropsRequest,
    buttonPropsRequest,
    buttonProps: step === 'request' ? buttonPropsRequest : step === 'check' ? buttonPropsCheck : undefined,
    alertPropsCheck,
    buttonPropsCheck,
    phoneLabel,
    requestButton,
    checkButton,
    button: step === 'request' ? requestButton : step === 'check' ? checkButton : undefined,
    reset: () => {
      resetRequest()
      resetCheck()
      setValidVerificationCode('')
      setStep('request')
    },
    hideButton,
  }
}

const PhoneVerification = ({
  step,
  formikRequest,
  formikCheck,
  forgetSubmitResultRequest,
  resetCheck,
  setValidVerificationCode,
  setStep,
  alertPropsRequest,
  buttonPropsRequest,
  alertPropsCheck,
  buttonPropsCheck,
  phoneLabel,
  requestButton,
  checkButton,
  hideButton,
}) => {
  return (
    <>
      <Input
        label={phoneLabel}
        disabled={step !== 'request'}
        mask="+{7} 000 000-00-00"
        name="phone"
        inputMode="tel"
        formik={formikRequest}
        hint={
          step !== 'request' && (
            <Link
              type="dashed"
              onClick={() => {
                forgetSubmitResultRequest()
                resetCheck()
                setValidVerificationCode('')
                setStep('request')
              }}
            >
              Изменить номер телефона
            </Link>
          )
        }
      />
      <Alert {...alertPropsRequest} />
      {step === 'request' && !hideButton && <Button {...buttonPropsRequest}>{requestButton}</Button>}
      {step === 'check' && <Input label="Код подтверждения" name="verificationCode" formik={formikCheck} />}
      <Alert {...alertPropsCheck} />
      {step === 'check' && !hideButton && <Button {...buttonPropsCheck}>{checkButton}</Button>}
    </>
  )
}

export default PhoneVerification
