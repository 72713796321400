import { gql } from '@apollo/client'

export const updateNewsPostMutation = gql`
  mutation ($input: UpdateNewsPostInput!) {
    updateNewsPost(input: $input) {
      newsPost {
        id
        serialNumber
        date
        contentMd
        contentHtml
        photos
        propertyId
      }
    }
  }
`

export const updateNewsPostOptions = { parser: [['newsPost', 'updateNewsPost.newsPost']] }
