import React, { useEffect, useRef } from 'react'
import RichText from 'components/RichText'
import useMutation from 'lib/useMutation'
import PageWrapper from 'components/PageWrapper'
import { getDashboardRoute } from 'lib/routes'
import Loader from 'components/Loader'

const WelcomePage = ({ me, history }) => {
  const [setWelcomePageVisitedTrue] = useMutation('setWelcomePageVisitedTrue')
  const meRef = useRef(me)
  useEffect(() => {
    if (me.welcomePageVisited) {
      history.replace(getDashboardRoute())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setWelcomePageVisitedTrue().catch(e => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (meRef.current.welcomePageVisited) {
    return <Loader type="page" />
  }
  return (
    <RichText>
      <h1>Добро пожаловать!</h1>
    </RichText>
  )
}

const CheckAuthWrapper = props => (
  <PageWrapper redirerctUnauthorized>{({ me }) => <WelcomePage me={me} {...props} />}</PageWrapper>
)

export default CheckAuthWrapper
