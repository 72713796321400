import React, { useState, useRef } from 'react'
import css from './styles.module.scss'
import useMutation from 'lib/useMutation'
import getUploadUrl from 'lib/getUploadUrl'
import Button, { Buttons, ButtonWrapper } from 'components/Button'
import cn from 'classnames'
import _ from 'lodash'

const UploadSingleImage = ({ label, formik, name, hint, disabled, accept = 'image/*' }) => {
  const inputEl = useRef()
  const [loading, setLoading] = useState(false)
  const [uploadSingleImage] = useMutation('uploadSingleImage')
  const error = _.get(formik.errors, name)
  const value = _.get(formik.values, name)
  const touched = _.get(formik.touched, name)
  return (
    <div className={cn({ [css.uploadSingleImage]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      {!!value && !loading && (
        <a className={css.previewPlace} rel="noreferrer" target="_blank" href={getUploadUrl(value, 'large')}>
          <img className={css.preview} alt="" src={getUploadUrl(value, 'preview')} />
        </a>
      )}
      <div className={css.buttons}>
        <Buttons>
          <ButtonWrapper>
            <label className={css.uploadButton}>
              <input
                className={css.fileInput}
                type="file"
                disabled={loading || disabled}
                accept={accept}
                ref={el => (inputEl.current = el)}
                onChange={async ({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => {
                  setLoading(true)
                  try {
                    const { uploadedFile } = await uploadSingleImage({
                      variables: { input: { file } },
                    })
                    formik.setFieldValue(name, uploadedFile.name)
                  } catch (err) {
                    console.error(err)
                    formik.setFieldError(name, err.humanMessage || err.message)
                  } finally {
                    formik.setFieldTouched(name, true, true)
                    setLoading(false)
                    inputEl.current.value = ''
                  }
                }}
              />
              {!disabled && (
                <Button type="button" loading={loading} disabled={loading || disabled} color="outlined" asSpan>
                  {value ? 'Загрузить другой' : 'Загрузить'}
                </Button>
              )}
            </label>
          </ButtonWrapper>
          {!disabled && !!value && !loading && (
            <Button
              type="button"
              color="outlined-red"
              onClick={() => {
                formik.setFieldValue(name, '')
                formik.setFieldError(name, false)
                formik.setFieldTouched(name, true, true)
              }}
              disabled={disabled}
            >
              Удалить
            </Button>
          )}
        </Buttons>
      </div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default UploadSingleImage
