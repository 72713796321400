import { gql } from '@apollo/client'

export const bankAccountFragment = gql`
  fragment bankAccountFragment on BankAccount {
    id
    bankName
    bankBik
    corrAccount
    benAccount
    fullName
    sentToProcessByUserAt
    rejectedByManagerAt
    acceptedByManagerAt
    status
    rejectComment
    prevRejectComment
    user {
      id
      serialNumber
      displayName
      email
      phone
    }
  }
`
