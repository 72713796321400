import { gql } from '@apollo/client'
import { passportFragment } from './passportFragment'

export const getPassportsQuery = gql`
  ${passportFragment}
  query getPassports($input: GetPassportsInput) {
    getPassports(input: $input) {
      passports {
        ...passportFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const getPassportsOptions = {
  parser: [
    ['passports', 'getPassports.passports'],
    ['pageInfo', 'getPassports.pageInfo'],
  ],
}
