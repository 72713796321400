import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import DatePicker from 'components/DatePicker'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import Textarea from 'components/Textarea'
import { canManageProperties } from 'lib/can'
import { getPropertyRoute } from 'lib/routes'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'
import { useHistory } from 'react-router-dom'

const PropertyCreatePage = ({ me }) => {
  const history = useHistory()
  const [createProperty] = useMutation('createProperty')
  const {
    formik: createPropertyFormik,
    buttonProps: buttonCreatePropertyProps,
    alertProps: alertCreatePropertyProps,
  } = useForm({
    initialValues: {
      name: '',
      desc: '',
      content: '',
      propertyType: '',
      area: '',
      additionalExpenses: '',
      purchasePrice: '',
      sellingPrice: '',
      minPeriod: '',
      maxPeriod: '',
      createdAt: '',
      startsAt: '',
      fundraisingUntil: '',
      endsAt: '',
      photos: [],
      documents: [],
      status: '',
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      desc: yup.string().required(),
      content: yup.string().required(),
      propertyType: yup.string().required(),
      area: yup.number().required(),
      additionalExpenses: yup.number().required(),
      purchasePrice: yup.number().required(),
      sellingPrice: yup.number().required(),
      minPeriod: yup.number().required(),
      maxPeriod: yup.number().required(),
      startsAt: yup.string().required(),
      fundraisingUntil: yup.string().required(),
      endsAt: yup.string().required(),
      photos: yup.array(yup.string().required()),
      documents: yup.array(yup.string().required()),
      status: yup.string().required(),
    }),
    onSubmit: (values) => {
      return createProperty({
        variables: {
          input: {
            name: values.name,
            desc: values.desc,
            content: values.content,
            propertyType: values.propertyType,
            area: +values.area,
            additionalExpenses: +values.additionalExpenses,
            purchasePrice: +values.purchasePrice,
            sellingPrice: +values.sellingPrice,
            minPeriod: +values.minPeriod,
            maxPeriod: +values.maxPeriod,
            startsAt: values.startsAt,
            fundraisingUntil: values.fundraisingUntil,
            endsAt: values.endsAt,
            photos: values.photos,
            documents: values.documents,
            status: values.status,
          },
        },
      }).then(({ property }) => {
        history.push(getPropertyRoute(property.serialNumber))
      })
    },
    successMessage: false,
  })
  return (
    <Segment title="Создание объекта">
      <Form formik={createPropertyFormik}>
        <FormItems>
          <Input name="name" label="Название" formik={createPropertyFormik} />
          <Textarea name="desc" label="Краткое описание" formik={createPropertyFormik} />
          <Textarea name="content" label="Подробное описание" formik={createPropertyFormik} />
          <Input name="propertyType" label="Тип недвижимости" formik={createPropertyFormik} />
          <Input name="area" label="Площадь" formik={createPropertyFormik} />
          <Input name="additionalExpenses" label="Дополнительные траты" formik={createPropertyFormik} />
          <Input name="purchasePrice" label="Цена покупки" formik={createPropertyFormik} />
          <Input name="sellingPrice" label="Цена продажи" formik={createPropertyFormik} />
          <Input name="minPeriod" label="Минимальный срок реализации в месяцах" formik={createPropertyFormik} />
          <Input name="maxPeriod" label="Максимальный срок реализации в месяцах" formik={createPropertyFormik} />
          <DatePicker withTime={false} name="startsAt" label="Дата старта объекта" formik={createPropertyFormik} />
          <DatePicker
            withTime={false}
            name="fundraisingUntil"
            label="Дата окончания сбора средств"
            formik={createPropertyFormik}
          />
          <DatePicker withTime={false} name="endsAt" label="Дата завершения объекта" formik={createPropertyFormik} />
          <Input name="status" label="Статус" formik={createPropertyFormik} />
          <Alert {...alertCreatePropertyProps} />
          <Buttons>
            <Button {...buttonCreatePropertyProps}>Создать объект</Button>
          </Buttons>
        </FormItems>
      </Form>
    </Segment>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canManageProperties(me)}>
    {({ me }) => <PropertyCreatePage me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
