import { gql } from '@apollo/client'
import { passportFragment } from './passportFragment'

export const approvePassportMutation = gql`
  ${passportFragment}
  mutation ($input: ApprovePassportInput!) {
    approvePassport(input: $input) {
      passport {
        ...passportFragment
        user {
          id
          displayName
        }
      }
    }
  }
`

export const approvePassportOptions = { parser: [['passport', 'approvePassport.passport']] }
