import Alert from 'components/Alert'
import Link from 'components/Link'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canManageUserPassports } from 'lib/can'
import { getUserRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'
import React from 'react'
import { PassportValidation } from '../../components/Passport/index.js'
import css from './styles.module.scss'

const PassportPage = ({ passport }) => {
  return (
    <Segment
      title={
        <>
          Заявка на установку паспортных данных{' '}
          <Link color="system-links-blue" to={getUserRoute(passport.user.serialNumber)}>
            от {passport.user.displayName}
          </Link>
        </>
      }
    >
      {passport.user.deletedAt && (
        <div className={css.deletedAlert}>
          <Alert message="Пользователь удалён" type="danger" />
        </div>
      )}
      <PassportValidation newPassport={passport} oldPassport={passport.user.passport} />
    </Segment>
  )
}

const GetUserWrapper = (props) => {
  const passportId = props.match.params.passportId
  const queryResult = useQuery('getPassport', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        passportId,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ passport }) => !!passport}>
      {({ passport }) => <PassportPage passport={passport} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canManageUserPassports(me)}>
    {({ me }) => <GetUserWrapper me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
