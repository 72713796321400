import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import cn from 'classnames'
import css from './styles.module.scss'

const Link = React.forwardRef(
  (
    {
      to,
      href,
      children,
      className,
      currentClassName,
      loading,
      type = 'clear',
      color = 'text-primary',
      onClick,
      ...restProps
    },
    ref
  ) => {
    const location = useLocation()
    const isCurrent = location.pathname === to
    const classNameHere = cn({
      [css.link]: true,
      [className]: !!className,
      [currentClassName]: !!currentClassName && isCurrent,
      [css.loading]: !!loading,
      [css[`type-${type}-color-${color}`]]: true,
    })
    const onClickHere =
      onClick &&
      (e => {
        // e.preventDefault()
        onClick(e)
      })
    if (to) {
      return (
        <RouterLink ref={ref} className={classNameHere} to={to} onClick={onClickHere} {...restProps}>
          {children}
        </RouterLink>
      )
    }
    return (
      <a ref={ref} href={href || '#'} className={classNameHere} onClick={onClickHere} {...restProps}>
        {children}
      </a>
    )
  }
)

export default Link
