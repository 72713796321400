import { gql } from '@apollo/client'
import { genDocTplFragment } from './genDocTplFragment'

export const uploadGenDocTplMutation = gql`
  ${genDocTplFragment}
  mutation ($input: UploadGenDocTplInput!) {
    uploadGenDocTpl(input: $input) {
      genDocTpl {
        ...genDocTplFragment
      }
    }
  }
`

export const uploadGenDocTplOptions = {
  parser: [['genDocTpl', 'uploadGenDocTpl.genDocTpl']],
}
