import { ReactComponent as Add } from 'assets/icons/add.svg'
import { ReactComponent as Burger } from 'assets/icons/burger.svg'
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg'
import { ReactComponent as Catalog } from 'assets/icons/catalog.svg'
import { ReactComponent as Cross } from 'assets/icons/cross.svg'
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg'
import { ReactComponent as Deal } from 'assets/icons/deal.svg'
import { ReactComponent as Delete } from 'assets/icons/delete.svg'
import { ReactComponent as Dots } from 'assets/icons/dots.svg'
import { ReactComponent as Edit } from 'assets/icons/edit.svg'
import { ReactComponent as HandHello } from 'assets/icons/hand-hello.svg'
import { ReactComponent as HandNote } from 'assets/icons/hand-note.svg'
import { ReactComponent as HandOk } from 'assets/icons/hand-ok.svg'
import { ReactComponent as Info } from 'assets/icons/info.svg'
import { ReactComponent as Location } from 'assets/icons/location.svg'
import { ReactComponent as Lock } from 'assets/icons/lock.svg'
import { ReactComponent as Logout } from 'assets/icons/logout.svg'
import { ReactComponent as Mail } from 'assets/icons/mail.svg'
import { ReactComponent as Person } from 'assets/icons/person.svg'
import { ReactComponent as Phone } from 'assets/icons/phone.svg'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { ReactComponent as Profile } from 'assets/icons/profile.svg'
import { ReactComponent as Question } from 'assets/icons/question.svg'
import { ReactComponent as Question1 } from 'assets/icons/question1.svg'
import { ReactComponent as Smile } from 'assets/icons/smile.svg'
import { ReactComponent as Star } from 'assets/icons/star.svg'
import { ReactComponent as Structure } from 'assets/icons/structure.svg'
import { ReactComponent as Suitcase } from 'assets/icons/suitcase.svg'
import { ReactComponent as Task } from 'assets/icons/task.svg'
import { ReactComponent as Team } from 'assets/icons/team.svg'
import { ReactComponent as Ticket } from 'assets/icons/ticket.svg'
import { ReactComponent as Person1 } from 'assets/icons/person1.svg'
import { ReactComponent as Phone1 } from 'assets/icons/phone1.svg'
import { ReactComponent as Mail1 } from 'assets/icons/mail1.svg'
import { ReactComponent as Bell } from 'assets/icons/bell.svg'
import { ReactComponent as SignIn } from 'assets/landing/sign-in-blue-icon.svg'

import { ReactComponent as NewCase } from 'assets/icons/new/case.svg'
import { ReactComponent as NewLogout } from 'assets/icons/new/logout.svg'
import { ReactComponent as NewLock } from 'assets/icons/new/lock.svg'
import { ReactComponent as NewQuestion } from 'assets/icons/new/question.svg'
import { ReactComponent as NewDocs } from 'assets/icons/new/docs.svg'
import { ReactComponent as NewCalc } from 'assets/icons/new/calc.svg'
import { ReactComponent as NewReferral } from 'assets/icons/new/referral.svg'
import { ReactComponent as NewBuilding } from 'assets/icons/new/building.svg'
import { ReactComponent as Copy } from 'assets/icons/copy.svg'
import { ReactComponent as Share } from 'assets/icons/share.svg'
import { ReactComponent as Telegram } from 'assets/icons/new/telegram.svg'
import { ReactComponent as Telegram1 } from 'assets/icons/telegram1.svg'
import { ReactComponent as TxPlus } from 'assets/icons/new/tx-plus.svg'
import { ReactComponent as TxPinus } from 'assets/icons/new/tx-minus.svg'
import { ReactComponent as TxPonvertation } from 'assets/icons/new/tx-convertation.svg'
import { ReactComponent as SliderLeftArrow } from 'assets/icons/slider-left-arrow.svg'
import { ReactComponent as SliderRightArrow } from 'assets/icons/slider-right-arrow.svg'
import { ReactComponent as PersonsIcon } from 'assets/landing/persons-icon.svg'

import { ReactComponent as NPCopyIcon } from 'assets/icons/new-payment/copy.svg'
import { ReactComponent as NPDocIcon } from 'assets/icons/new-payment/doc.svg'
import { ReactComponent as NPInfoIcon } from 'assets/icons/new-payment/info.svg'
import { ReactComponent as NPPropertyIcon } from 'assets/icons/new-payment/property.svg'
import { ReactComponent as NPQrIcon } from 'assets/icons/new-payment/qr.svg'
import { ReactComponent as NPRightArrowIcon } from 'assets/icons/new-payment/right-arrow.svg'

import React from 'react'
import { AiOutlineBank } from 'react-icons/ai'
import {
  FaPassport,
  FaFilePdf,
  FaDotCircle,
  FaBuilding,
  FaSyncAlt,
  FaCalculator,
  FaFileAlt,
  FaQuestionCircle,
  FaMoneyBill,
} from 'react-icons/fa'

const Icon = ({ name, ...restProps }) => {
  switch (name) {
    case 'suitcase':
      return <Suitcase {...restProps} />
    case 'mail':
      return <Mail {...restProps} />
    case 'lock':
      return <Lock {...restProps} />
    case 'add':
      return <Add {...restProps} />
    case 'catalog':
      return <Catalog {...restProps} />
    case 'dashboard':
      return <Dashboard {...restProps} />
    case 'deal':
      return <Deal {...restProps} />
    case 'info':
      return <Info {...restProps} />
    case 'logout':
      return <Logout {...restProps} />
    case 'profile':
      return <Profile {...restProps} />
    case 'question':
      return <Question1 {...restProps} />
    case 'question1':
      return <Question1 {...restProps} />
    case 'smile':
      return <Smile {...restProps} />
    case 'star':
      return <Star {...restProps} />
    case 'structure':
      return <Structure {...restProps} />
    case 'task':
      return <Task {...restProps} />
    case 'team':
      return <Team {...restProps} />
    case 'burger':
      return <Burger {...restProps} />
    case 'cross':
      return <Cross {...restProps} />
    case 'hand-hello':
      return <HandHello {...restProps} />
    case 'hand-ok':
      return <HandOk {...restProps} />
    case 'hand-note':
      return <HandNote {...restProps} />
    case 'ticket':
      return <Ticket {...restProps} />
    case 'delete':
      return <Delete {...restProps} />
    case 'edit':
      return <Edit {...restProps} />
    case 'plus':
      return <Plus {...restProps} />
    case 'dots':
      return <Dots {...restProps} />
    case 'person':
      return <Person {...restProps} />
    case 'persons':
      return <PersonsIcon {...restProps} />
    case 'location':
      return <Location {...restProps} />
    case 'phone':
      return <Phone {...restProps} />
    case 'calendar':
      return <Calendar {...restProps} />
    case 'passport':
      return <FaPassport {...restProps} />
    case 'bank':
      return <AiOutlineBank {...restProps} />
    case 'document':
      return <FaFilePdf {...restProps} />
    case 'dot':
      return <FaDotCircle {...restProps} />
    case 'property':
      return <FaBuilding {...restProps} />
    case 'sync':
      return <FaSyncAlt {...restProps} />
    case 'calc':
      return <FaCalculator {...restProps} />
    case 'contract':
      return <FaFileAlt {...restProps} />
    case 'help':
      return <FaQuestionCircle {...restProps} />
    case 'person1':
      return <Person1 {...restProps} />
    case 'phone1':
      return <Phone1 {...restProps} />
    case 'mail1':
      return <Mail1 {...restProps} />
    case 'money':
      return <FaMoneyBill {...restProps} />
    case 'new-case':
      return <NewCase {...restProps} />
    case 'new-logout':
      return <NewLogout {...restProps} />
    case 'new-lock':
      return <NewLock {...restProps} />
    case 'new-question':
      return <NewQuestion {...restProps} />
    case 'new-docs':
      return <NewDocs {...restProps} />
    case 'new-calc':
      return <NewCalc {...restProps} />
    case 'new-referral':
      return <NewReferral {...restProps} />
    case 'new-building':
      return <NewBuilding {...restProps} />
    case 'copy':
      return <Copy {...restProps} />
    case 'signin':
      return <SignIn {...restProps} />
    case 'share':
      return <Share {...restProps} />
    case 'telegram':
      return <Telegram {...restProps} />
    case 'telegram1':
      return <Telegram1 {...restProps} />
    case 'tx-plus':
      return <TxPlus {...restProps} />
    case 'tx-minus':
      return <TxPinus {...restProps} />
    case 'tx-convertation':
      return <TxPonvertation {...restProps} />
    case 'slider-left-arrow':
      return <SliderLeftArrow {...restProps} />
    case 'slider-right-arrow':
      return <SliderRightArrow {...restProps} />
    case 'np-copy-icon':
      return <NPCopyIcon {...restProps} />
    case 'np-doc-icon':
      return <NPDocIcon {...restProps} />
    case 'np-info-icon':
      return <NPInfoIcon {...restProps} />
    case 'np-property-icon':
      return <NPPropertyIcon {...restProps} />
    case 'np-qr-icon':
      return <NPQrIcon {...restProps} />
    case 'np-right-arrow-icon':
      return <NPRightArrowIcon {...restProps} />
    case 'bell':
      return <Bell {...restProps} />

    default:
      return null
  }
}

export default Icon
