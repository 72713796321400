import { gql } from '@apollo/client'
import { bankAccountFragment } from './bankAccountFragment'

export const getBankAccountsQuery = gql`
  ${bankAccountFragment}
  query getBankAccounts($input: GetBankAccountsInput) {
    getBankAccounts(input: $input) {
      bankAccounts {
        ...bankAccountFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const getBankAccountsOptions = {
  parser: [
    ['bankAccounts', 'getBankAccounts.bankAccounts'],
    ['pageInfo', 'getBankAccounts.pageInfo'],
  ],
}
