import React from 'react'
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import Cookies from 'js-cookie'

const requestLink = new ApolloLink((operation, forward) => {
  const token = Cookies.get('token')
  operation.setContext({
    headers: {
      ...(token && { authorization: `Bearer ${token}` }),
    },
  })
  return forward(operation)
})

const apolloClient = new ApolloClient({
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      if (networkError) {
        console.error(`[Network error]: ${networkError}`)
        if (networkError.bodyText === 'Unauthorized') {
          Cookies.remove('token')
          apolloClient.resetStore()
          window.location.reload(false)
        }
      }
    }),
    requestLink,
    createUploadLink({
      uri:
        window.location.host === 'mirror.estateinvest.online'
          ? 'https://backend-mirror.estateinvest.online/graphql'
          : process.env.REACT_APP_GRAPHQL_URI,
    }),
  ]),
  cache: new InMemoryCache(),
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => <ApolloProvider client={apolloClient} {...props} />
