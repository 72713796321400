import logo from 'assets/logo-light.svg'
import Link from 'components/Link'
import { getSignInRoute, getSignUpRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'
import css from './styles.module.scss'

const AuthLayout = ({ children }) => {
  const { me, loading } = useQuery('getMe')
  return (
    <div className={css.authLayout}>
      <div className={css.header}>
        <div className={css.logoPlaceDesktop}>
          <img src={logo} alt="Estate Invest" />
        </div>
        <div className={css.topBg}>
          <div className={css.inner}>
            <img alt="" width="1792" height="530" src="/images/top-bg.png" className={css.image} />
          </div>
        </div>
      </div>
      <div className={css.container}>
        <div className={css.logoPlaceMobile}>
          <img src={logo} alt="Estate Invest" />
        </div>
        {false && !loading && !me && (
          <ul className={css.tabs}>
            <li className={css.tab}>
              <Link className={css.link} currentClassName={css.current} to={getSignInRoute()}>
                Вход
              </Link>
            </li>
            <li className={css.tab}>
              <Link className={css.link} currentClassName={css.current} to={getSignUpRoute()}>
                Регистрация
              </Link>
            </li>
          </ul>
        )}
        <div className={css.content}>
          <div className={css.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
