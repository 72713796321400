import Alert from 'components/Alert'
import Button from 'components/Button'
import { FormItems } from 'components/Form'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { copyToClipboard } from 'lib/copyToClipboard'

const SetupBotPage = () => {
  return (
    <PageWrapper redirerctUnauthorized>
      {({ me }) => {
        return (
          <Segment title="Настройка телеграм бота">
            <FormItems>
              <Alert message={<>Ваш API ключ: <span className="wrapall">{me.apiKey}</span></>} />
              <Button onClick={() => {
                copyToClipboard(me.apiKey)
              }}>Скопировать ключ</Button>
              <Alert message={<>Скопируйте ключ и пришлите его в чат с ботом <a className="wrapall" href="https://t.me/Estate_invest_RF_bot" target="_blank" rel="noreferrer">https://t.me/Estate_invest_RF_bot</a></>} />
            </FormItems>
          </Segment>
        )
      }}
    </PageWrapper>
  )
}

export default SetupBotPage
