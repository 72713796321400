import { gql } from '@apollo/client'
import { userFragment } from './userFragment.js'

export const getUserQuery = gql`
  ${userFragment}
  query($input: GetUserInput) {
    getUser(input: $input) {
      user {
        ...userFragment
      }
    }
  }
`

export const getUserOptions = {
  parser: [['user', 'getUser.user']],
}
