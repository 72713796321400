import React from 'react'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Segment from 'components/Segment'
import { FormItems } from 'components/Form'
import Alert from 'components/Alert'

const ConfirmationModal = ({
  isOpen,
  setOpen,
  title,
  description,
  buttonText,
  buttonColor,
  onConfirm,
  alertProps,
  buttonProps,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={() => setOpen(false)}>
      <Segment size={2} title={title}>
        <FormItems>
          {description && <p>{description}</p>}
          <Alert {...alertProps} />
          <Button onClick={onConfirm} {...buttonProps} color={buttonColor} type="button">
            {buttonText}
          </Button>
        </FormItems>
      </Segment>
    </Modal>
  )
}

export default ConfirmationModal
