import Alert from 'components/Alert'
import Button from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import Select from 'components/Select'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'
import { useEffect, useState } from 'react'
import QRCode from 'qrcode'

const NewPaymentPage = ({ properties }) => {
  const { formik, buttonProps, alertProps, success } = useForm({
    // forgetSuccessDelay: 0,
    initialValues: {
      propertySerialNumber: 0,
      sum: 100000,
    },
    onSubmit: ({ fio, sum, telegram, about, email, referralCode }) => {
      // return sendFormToTelegram({
      //   variables: { input: { fio, sum, telegram, about, email, referralCode } },
      // }).then(({ data }) => {
      //   setEmail(data.sendFormToTelegram.email)
      //   setPassword(data.sendFormToTelegram.password)
      // })
    },
    validationSchema: yup.object({
      // sum: yup.number().required(),
      propertySerialNumber: yup.number().required(),
    }),
    // successMessage: 'Анкета успешно отправлена. Мы скоро с вами свяжемся. Спасибо!',
  })
  // ST00012|Name=1|PersonalAcc=2|BankName=3|BIC=4|CorrespAcc=5|Sum=600|SumRub=6|Purpose=9|PayeeINN=7|PayerINN=8
  const staticPaymentData = {
    Name: 'Индивидуальный предприниматель Мудров Иван Юрьевич',
    PersonalAcc: '40802810714500051003',
    BankName: 'ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"',
    BIC: '044525104',
    CorrespAcc: '30101810745374525104',
    PayeeINN: '590610248187',
  }
  const getPaymentData = ({ sum, propertySerialNumber }) => {
    return {
      ...staticPaymentData,
      Sum: sum * 100,
      // SumRub: sum * 100,
      Purpose: `Оплата Инвестиционного предложения №${propertySerialNumber} БЕЗ НДС`,
    }
  }
  const getPaymentString = ({ sum, propertySerialNumber }) => {
    const paymentData = getPaymentData({ sum, propertySerialNumber })
    const paymentString = Object.keys(paymentData)
      .map((key) => `${key}=${paymentData[key]}`)
      .join('|')
    return `ST00012|${paymentString}`
  }
  const [paymentString, setPaymentString] = useState(null)
  const [qrCodeUrl, setQrCodeUrl] = useState(null)
  useEffect(() => {
    if (formik.values.sum && formik.values.propertySerialNumber) {
      setPaymentString(
        getPaymentString({
          sum: formik.values.sum,
          propertySerialNumber: formik.values.propertySerialNumber,
        })
      )
    } else {
      setPaymentString(null)
    }
  }, [formik.values.sum, formik.values.propertySerialNumber])
  useEffect(() => {
    if (paymentString) {
      QRCode.toDataURL(paymentString)
        .then((url) => {
          setQrCodeUrl(url)
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      setQrCodeUrl(null)
    }
  }, [paymentString])
  return (
    <Segment title={`Новая инвестиция`}>
      <Form formik={formik}>
        <FormItems>
          <Select
            formik={formik}
            name={'propertySerialNumber'}
            label={'Инвестиционное предложение'}
            options={properties.map((property) => ({
              value: property.serialNumber,
              label: `№${property.serialNumber} ${property.name}`,
            }))}
          />
          {/* <RadioButtons label="Способ входа" direction="type" formik={formik} name="method">
            <RadioButton label="По номеру телефона" value="phone" formik={formik} name="method" />
            <RadioButton label="По адресу почты" value="email" formik={formik} name="method" />
          </RadioButtons> */}
          <Input type="number" label="Сумма инвестирования в рублях" name="sum" formik={formik} />
          {!!qrCodeUrl && (
            <>
              <img style={{ maxWidth: 300 }} src={qrCodeUrl} alt="" />
              <Alert message="С помощью этого QR кода вы можете сделать этот превод и не вводить данные в ручную. Так же, как вы оплачиываете любые квитанции, вы можете сделать этот перевод указав этот QR код в вашем приложении банка в разделе переводов по банковским реквизитам. Если вы открыли эту страницу с мобильного телефона, вы можете сохранить картинку с QR кодом себе на телефон и выбрать её в мобильном приложении вашего банка" />
              <p>
                Реквизиты:
                <br />
                Наименование: Индивидуальный предприниматель Мудров Иван Юрьевич
                <br />
                ИНН: 590610248187
                <br />
                Расчетный: №40802810714500051003
                <br />
                Название банка: ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"
                <br />
                БИК: 044525104
                <br />
                Город: г. Москва
                <br />
                Корр. счет: 30101810745374525104
                <br />
                Назначение платежа: Оплата Инвестиционного предложения №{formik.values.propertySerialNumber} БЕЗ НДС
              </p>
            </>
          )}
          <Alert {...alertProps} />
          {/* <Button {...buttonProps}>Отправить</Button> */}
        </FormItems>
      </Form>
    </Segment>
  )
}

const GetPropertiesWrapper = (props) => {
  const queryResult = useQuery('getProperties', {
    fetchPolicy: 'network-only',
  })
  const properties = (queryResult.properties || []).filter((property) => property.status === 'foundrising')

  return (
    <PageWrapper queryResult={queryResult}>{() => <NewPaymentPage properties={properties} {...props} />}</PageWrapper>
  )
}

export default GetPropertiesWrapper
