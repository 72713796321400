import React, { forwardRef } from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import _ from 'lodash'
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
registerLocale('ru', ru)
setDefaultLocale('ru')

const DatePicker = ({
  label,
  formik,
  name,
  hint,
  onChange,
  fullWidth,
  width,
  disabled,
  withTime = true,
  ...restProps
}) => {
  const error = _.get(formik.errors, name)
  const formikValue = _.get(formik.values, name)
  const value = formikValue ? new Date(formikValue) : formikValue
  const touched = _.get(formik.touched, name)
  const Input = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      onClick={disabled ? () => {} : onClick}
      value={value}
      ref={ref}
      className={cn({
        [css.input]: true,
        [css.fullWidth]: !!fullWidth,
        [css.invalid]: !!error && touched,
      })}
      onChange={onChange}
      disabled={disabled}
      {...restProps}
    />
  ))
  return (
    <div
      className={cn({ [css.field]: true, [css.disabled]: disabled, [css.fullWidth]: !!fullWidth })}
      style={width ? { maxWidth: width } : {}}
    >
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      <ReactDatePicker
        selected={value}
        onChange={date => {
          if (onChange) onChange(date || '')
          formik.setFieldValue(name, date || '')
          formik.setFieldTouched(name)
        }}
        disabled={disabled}
        showTimeSelect={withTime}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Время"
        dateFormat="dd.MM.yyyy HH:mm"
        customInput={<Input />}
      />
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default DatePicker
