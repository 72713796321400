import { gql } from '@apollo/client'

export const uploadMultipleFilesMutation = gql`
  mutation ($input: UploadMultipleFilesInput!) {
    uploadMultipleFiles(input: $input) {
      uploadedFiles {
        path
      }
    }
  }
`

export const uploadMultipleFilesOptions = {
  parser: [['uploadedFiles', 'uploadMultipleFiles.uploadedFiles']],
}
