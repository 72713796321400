import RichText from 'components/RichText'

const DocsTermsPage = () => {
  return (
    <RichText>
      <p>от 31.05.2024</p>
      <h1>ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
      <h2>1. Общие положения</h2>
      <p>
        1.1. Политика оператора в отношении обработки персональных данных (далее &ndash; Политика) разработана в целях
        обеспечения защиты прав и свобод субъекта персональных данных при обработке его персональных данных, в том числе
        защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
      </p>
      <p>
        В контексте настоящей Политики оператором обработки персональных данных является ИП Мудров Иван Юрьевич ИНН
        590610248187.
      </p>
      <p>1.2. Основные понятия, используемые в Политике:</p>
      <p>
        - персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому
        физическому лицу (субъекту персональных данных);
      </p>
      <p>
        - обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с
        использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор,
        запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
        передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
        персональных данных;
      </p>
      <p>
        - автоматизированная обработка персональных данных - обработка персональных данных с помощью средств
        вычислительной техники;
      </p>
      <p>
        - распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному
        кругу лиц;
      </p>
      <p>
        - предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному
        лицу или определенному кругу лиц;
      </p>
      <p>
        - блокирование персональных данных - временное прекращение обработки персональных данных (за исключением
        случаев, если обработка необходима для уточнения персональных данных);
      </p>
      <p>
        - уничтожение персональных данных - действия, в результате которых становится невозможным восстановить
        содержание персональных данных в информационной системе персональных данных и (или) в результате которых
        уничтожаются материальные носители персональных данных;
      </p>
      <p>
        - обезличивание персональных данных - действия, в результате которых становится невозможным без использования
        дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных
        данных;
      </p>
      <p>
        1.3. Оператор, получивший доступ к персональным данным, обязан соблюдать конфиденциальность персональных данных
        - не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных,
        если иное не предусмотрено федеральным законом.
      </p>
      <p>
        1.4. Субъект персональных данных имеет право на получение информации, касающейся обработки его персональных
        данных, в том числе содержащей:
      </p>
      <p>- подтверждение факта обработки персональных данных оператором;</p>
      <p>- правовые основания и цели обработки персональных данных;</p>
      <p>- цели и применяемые оператором способы обработки персональных данных;</p>
      <p>
        - наименование и место нахождения оператора, сведения о лицах (за исключением работников оператора), которые
        имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с
        оператором или на основании федерального закона;
      </p>
      <p>
        - обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их
        получения, если иной порядок представления таких данных не предусмотрен федеральным законом;
      </p>
      <p>- сроки обработки персональных данных, в том числе сроки их хранения;</p>
      <p>
        - порядок осуществления субъектом персональных данных прав, предусмотренных{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/0">Федеральным законом</a> от 27 июля 2006 г. N
        152-ФЗ &quot;О персональных данных&quot; (далее - Закон о персональных данных);
      </p>
      <p>- информацию об осуществленной или о предполагаемой трансграничной передаче данных;</p>
      <p>
        - наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по
        поручению оператора, если обработка поручена или будет поручена такому лицу;
      </p>
      <p>
        - информацию о способах исполнения оператором обязанностей, установленных{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/181">статьей 18.1</a> Закона о персональных
        данных;
      </p>
      <p>
        - иные сведения, предусмотренные <a href="https://internet.garant.ru/#/document/12148567/entry/0">Законом</a> о
        персональных данных или другими федеральными законами.
      </p>
      <p>
        1.5. Субъект персональных данных вправе требовать от оператора уточнения его персональных данных, их
        блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными,
        незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать
        предусмотренные законом меры по защите своих прав.
      </p>
      <p>
        1.6. Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на
        возмещение убытков и (или) компенсацию морального вреда в судебном порядке.
      </p>
      <p>1.7. Оператор персональных данных вправе:</p>
      <p>- отстаивать свои интересы в суде;</p>
      <p>
        - предоставлять персональные данные субъектов третьим лицам, если это предусмотрено действующим
        законодательством (налоговые, правоохранительные органы и др.);
      </p>
      <p>- отказывать в предоставлении персональных данных в случаях, предусмотренных законодательством;</p>
      <p>- использовать персональные данные субъекта без его согласия в случаях, предусмотренных законодательством.</p>
      <p>
        1.8. При сборе персональных данных оператор обязан предоставить субъекту персональных данных по его просьбе
        информацию, предусмотренную{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/1407">частью 7 статьи 14</a> Закона о персональных
        данных.
      </p>
      <p>
        1.9. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети
        &quot;Интернет&quot;, оператор обязан обеспечить запись, систематизацию, накопление, хранение, уточнение
        (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз
        данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/6012">пунктах 2</a>,{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/6013">3</a>,{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/6014">4</a>,{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/6018">8 части 1 статьи 6</a> Закона о персональных
        данных.
      </p>
      <p>
        <br />
      </p>
      <h2>2. Цели сбора персональных данных</h2>
      <p>
        2.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей.
        Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
      </p>
      <p>
        2.2. Цели обработки персональных данных происходят в том числе из анализа правовых актов, регламентирующих
        деятельность оператора, целей фактически осуществляемой оператором деятельности, а также деятельности, которая
        предусмотрена учредительными документами оператора, и конкретных бизнес-процессов оператора в конкретных
        информационных системах персональных данных (по структурным подразделениям оператора и их процедурам в отношении
        определенных категорий субъектов персональных данных).
      </p>
      <p>2.3. К целям обработки персональных данных оператора относятся:</p>
      <ul>
        <li>заключение, исполнение и прекращение гражданско-правовых договоров;</li>
        <li>
          организация кадрового учета организации, обеспечение соблюдения законов, заключение и исполнение обязательств
          по трудовым и гражданско-правовым договорам;
        </li>
        <li>
          ведение кадрового делопроизводства, содействие работникам в трудоустройстве, обучении и продвижении по службе,
          пользовании льготами;
        </li>
        <li>
          исполнение требований налогового законодательства по вопросам исчисления и уплаты налога на доходы физических
          лиц, взносов во внебюджетные фонды и страховых взносов во внебюджетные фонды, пенсионного законодательства при
          формировании и передаче в ПФР персонифицированных данных о каждом получателе доходов, которые учитываются при
          начислении взносов на обязательное пенсионное страхование;
        </li>
        <li>
          заполнение первичной статистической документации в соответствии с трудовым, налоговым законодательством и
          иными федеральными законами.
        </li>
      </ul>
      <h2>3. Правовые основания обработки персональных данных</h2>
      <p>3.1. Правовым основанием обработки персональных данных являются:</p>
      <ul>
        <li>Конституция Российской Федерации;</li>
        <li>Трудовой кодекс Российской Федерации от 30.12.2001 № 197-ФЗ;</li>
        <li>Федеральный закон Российской Федерации &laquo;О&nbsp;персональных данных&raquo; от 27.07.2006 № 152-ФЗ;</li>
        <li>
          Федеральный закон Российской Федерации &laquo;Об&nbsp;информации, информационных технологиях и о защите
          информации&raquo; от 27.07.2006 № 149-ФЗ;
        </li>
        <li>
          Указ Президента Российской Федерации от 06.03.1997 № 188 &laquo;Об утверждении Перечня сведений
          конфиденциального характера&raquo;;
        </li>
        <li>
          Постановление Правительства Российской Федерации от 06.07.2008 г. N 512 &laquo;Об утверждении требований к
          материальным носителям биометрических персональных данных и технологиям хранения таких данных вне
          информационных систем персональных данных&raquo;;
        </li>
        <li>
          Постановление Правительства Российской Федерации от 01.11.2012 № 1119 &laquo;Об&nbsp;утверждении требований к
          защите персональных данных при их обработке в информационных системах персональных данных&raquo;;
        </li>
        <li>
          Постановление Правительства Российской Федерации от 15.09.2008 № 687 &laquo;Об&nbsp;утверждении Положения об
          особенностях обработки персональных данных, осуществляемой без использования средств автоматизации&raquo;;
        </li>
        <li>
          Приказ ФСТЭК России от 18.02.2013 № 21 &laquo;Об утверждении Состава и содержания организационных и
          технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах
          персональных данных&raquo;;
        </li>
        <li>уставные документы оператора;</li>
        <li>договоры, заключаемые между оператором и субъектом персональных данных;</li>
        <li>
          согласие на обработку персональных данных (в случаях, прямо не предусмотренных законодательством Российской
          Федерации, но соответствующих полномочиям оператора).
        </li>
      </ul>
      <p>4. Объем и категории обрабатываемых персональных данных, категории субъектов персональных данных.</p>
      <p>
        4.1. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки.
        Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.
      </p>
      <p>4.2. Обработка персональных данных допускается в следующих случаях:</p>
      <ul>
        <li>
          обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его
          персональных данных;
        </li>
        <li>
          обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем
          или поручителем по которому является субъект персональных данных, а также для заключения договора по
          инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться
          выгодоприобретателем или поручителем. Заключаемый с субъектом персональных данных договор не может содержать
          положения, ограничивающие права и свободы субъекта персональных данных, устанавливающие случаи обработки
          персональных данных несовершеннолетних, если иное не предусмотрено законодательством Российской Федерации, а
          также положения, допускающие в качестве условия заключения договора бездействие субъекта персональных данных;
        </li>
        <li>
          обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов
          субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;
        </li>
        <li>
          обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих
          лиц, в том числе в случаях, предусмотренных{' '}
          <a href="https://internet.garant.ru/#/document/71433918/entry/0">Федеральным законом</a> &quot;О защите прав и
          законных интересов физических лиц при осуществлении деятельности по возврату просроченной задолженности и о
          внесении изменений в Федеральный закон &quot;О микрофинансовой деятельности и микрофинансовых
          организациях&quot;, либо для достижения общественно значимых целей при условии, что при этом не нарушаются
          права и свободы субъекта персональных данных;
        </li>
        <li>
          обработка персональных данных необходима для осуществления профессиональной деятельности журналиста и (или)
          законной деятельности средства массовой информации либо научной, литературной или иной творческой деятельности
          при условии, что при этом не нарушаются права и законные интересы субъекта персональных данных;
        </li>
        <li>
          обработка персональных данных осуществляется в статистических или иных исследовательских целях, за исключением
          целей, указанных в <a href="https://internet.garant.ru/#/document/12148567/entry/15">статье 15</a> Закона о
          персональных данных, при условии обязательного обезличивания персональных данных;
        </li>
        <li>
          осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в
          соответствии с федеральным законом.
        </li>
      </ul>
      <p>4.3. К категориям субъектов персональных данных относятся:</p>
      <p>
        4.3.1. Работники оператора, бывшие работники, кандидаты на замещение вакантных должностей, а также родственники
        работников.
      </p>
      <p>В данной категории субъектов оператором обрабатываются персональные данные:</p>
      <p>
        В целях обеспечения соблюдения законов и иных нормативных правовых актов, содействия работникам в
        трудоустройстве, получении образования и продвижении по службе, обеспечения личной безопасности работников,
        контроля количества и качества выполняемой работы и обеспечения сохранности имущества:
      </p>
      <h3>Перечень персональных данных для категории: только общие персональные данные</h3>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>гражданство;</li>
        <li>дата и место рождения;</li>
        <li>адрес места проживания;</li>
        <li>сведения о регистрации по месту жительства или пребывания;</li>
        <li>номера телефонов (домашний, мобильный, рабочий);</li>
        <li>адрес электронной почты;</li>
        <li>замещаемая должность;</li>
        <li>сведения о трудовой деятельности;</li>
        <li>идентификационный номер налогоплательщика;</li>
        <li>
          данные документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета, в том
          числе в форме электронного документа;
        </li>
        <li>данные полиса обязательного медицинского страхования;</li>
        <li>данные паспорта или иного удостоверяющего личность документа;</li>
        <li>
          данные паспорта, удостоверяющего личность гражданина Российской Федерации за пределами территории Российской
          Федерации;
        </li>
        <li>данные трудовой книжки, вкладыша в трудовую книжку;</li>
        <li>сведения о воинском учете;</li>
        <li>сведения об образовании;</li>
        <li>сведения о получении дополнительного профессионального образования;</li>
        <li>сведения о владении иностранными языками;</li>
        <li>
          сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного,
          гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества
          собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии
          предпринимательской деятельностью;
        </li>
        <li>сведения о наградах, иных поощрениях и знаках отличия;</li>
        <li>сведения о дисциплинарных взысканиях;</li>
        <li>сведения, содержащиеся в материалах служебных проверок;</li>
        <li>сведения о семейном положении;</li>
        <li>сведения о близких родственниках, свойственниках;</li>
        <li>
          сведения, содержащиеся в справках о доходах, расходах, об имуществе и обязательствах имущественного характера;
        </li>
        <li>номер расчетного счета;</li>
        <li>информация об оформленных допусках к государственной тайне;</li>
        <li>иное.</li>
      </ul>
      <h3>Перечень персональных данных для категории: общие и специальные персональные данные</h3>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>гражданство;</li>
        <li>дата и место рождения;</li>
        <li>адрес места проживания;</li>
        <li>сведения о регистрации по месту жительства или пребывания;</li>
        <li>номера телефонов (домашний, мобильный, рабочий);</li>
        <li>адрес электронной почты;</li>
        <li>замещаемая должность;</li>
        <li>сведения о трудовой деятельности;</li>
        <li>идентификационный номер налогоплательщика;</li>
        <li>
          данные документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета, в том
          числе в форме электронного документа;
        </li>
        <li>данные полиса обязательного медицинского страхования;</li>
        <li>данные паспорта или иного удостоверяющего личность документа;</li>
        <li>
          данные паспорта, удостоверяющего личность гражданина Российской Федерации за пределами территории Российской
          Федерации;
        </li>
        <li>данные трудовой книжки, вкладыша в трудовую книжку;</li>
        <li>сведения о воинском учете;</li>
        <li>сведения об образовании;</li>
        <li>сведения о получении дополнительного профессионального образования;</li>
        <li>сведения о владении иностранными языками;</li>
        <li>
          сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного,
          гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества
          собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии
          предпринимательской деятельностью;
        </li>
        <li>сведения о наградах, иных поощрениях и знаках отличия;</li>
        <li>сведения о дисциплинарных взысканиях;</li>
        <li>сведения, содержащиеся в материалах служебных проверок;</li>
        <li>сведения о семейном положении;</li>
        <li>сведения о близких родственниках, свойственниках;</li>
        <li>
          сведения, содержащиеся в справках о доходах, расходах, об имуществе и обязательствах имущественного характера;
        </li>
        <li>номер расчетного счета;</li>
        <li>информация об оформленных допусках к государственной тайне;</li>
        <li>расовая, национальная принадлежности;</li>
        <li>политические взгляды;</li>
        <li>религиозные или философские убеждения;</li>
        <li>состояние здоровья, интимной жизни;</li>
        <li>сведения о судимости;</li>
        <li>иное.</li>
      </ul>
      <h3>Перечень персональных данных для категории: общие и биометрические персональные данные</h3>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>гражданство;</li>
        <li>дата и место рождения;</li>
        <li>адрес места проживания;</li>
        <li>сведения о регистрации по месту жительства или пребывания;</li>
        <li>номера телефонов (домашний, мобильный, рабочий);</li>
        <li>адрес электронной почты;</li>
        <li>замещаемая должность;</li>
        <li>сведения о трудовой деятельности;</li>
        <li>идентификационный номер налогоплательщика;</li>
        <li>
          данные документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета, в том
          числе в форме электронного документа;
        </li>
        <li>данные полиса обязательного медицинского страхования;</li>
        <li>данные паспорта или иного удостоверяющего личность документа;</li>
        <li>
          данные паспорта, удостоверяющего личность гражданина Российской Федерации за пределами территории Российской
          Федерации;
        </li>
        <li>данные трудовой книжки, вкладыша в трудовую книжку;</li>
        <li>сведения о воинском учете;</li>
        <li>сведения об образовании;</li>
        <li>сведения о получении дополнительного профессионального образования;</li>
        <li>сведения о владении иностранными языками;</li>
        <li>
          сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного,
          гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества
          собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии
          предпринимательской деятельностью;
        </li>
        <li>сведения о наградах, иных поощрениях и знаках отличия;</li>
        <li>сведения о дисциплинарных взысканиях;</li>
        <li>сведения, содержащиеся в материалах служебных проверок;</li>
        <li>сведения о семейном положении;</li>
        <li>сведения о близких родственниках, свойственниках;</li>
        <li>
          сведения, содержащиеся в справках о доходах, расходах, об имуществе и обязательствах имущественного характера;
        </li>
        <li>номер расчетного счета;</li>
        <li>информация об оформленных допусках к государственной тайне;</li>
        <li>фото;</li>
        <li>видео;</li>
        <li>отпечатки пальцев;</li>
        <li>иное.</li>
      </ul>
      <h3>Перечень персональных данных для категории: общие, специальные и биометрические персональные данные</h3>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>гражданство;</li>
        <li>дата и место рождения;</li>
        <li>адрес места проживания;</li>
        <li>сведения о регистрации по месту жительства или пребывания;</li>
        <li>номера телефонов (домашний, мобильный, рабочий);</li>
        <li>адрес электронной почты;</li>
        <li>замещаемая должность;</li>
        <li>сведения о трудовой деятельности;</li>
        <li>идентификационный номер налогоплательщика;</li>
        <li>
          данные документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета, в том
          числе в форме электронного документа;
        </li>
        <li>данные полиса обязательного медицинского страхования;</li>
        <li>данные паспорта или иного удостоверяющего личность документа;</li>
        <li>
          данные паспорта, удостоверяющего личность гражданина Российской Федерации за пределами территории Российской
          Федерации;
        </li>
        <li>данные трудовой книжки, вкладыша в трудовую книжку;</li>
        <li>сведения о воинском учете;</li>
        <li>сведения об образовании;</li>
        <li>сведения о получении дополнительного профессионального образования;</li>
        <li>сведения о владении иностранными языками;</li>
        <li>
          сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного,
          гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества
          собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии
          предпринимательской деятельностью;
        </li>
        <li>сведения о наградах, иных поощрениях и знаках отличия;</li>
        <li>сведения о дисциплинарных взысканиях;</li>
        <li>сведения, содержащиеся в материалах служебных проверок;</li>
        <li>сведения о семейном положении;</li>
        <li>сведения о близких родственниках, свойственниках;</li>
        <li>
          сведения, содержащиеся в справках о доходах, расходах, об имуществе и обязательствах имущественного характера;
        </li>
        <li>номер расчетного счета;</li>
        <li>информация об оформленных допусках к государственной тайне;</li>
        <li>расовая, национальная принадлежности;</li>
        <li>политические взгляды;</li>
        <li>религиозные или философские убеждения;</li>
        <li>состояние здоровья, интимной жизни;</li>
        <li>сведения о судимости;</li>
        <li>фото;</li>
        <li>видео;</li>
        <li>отпечатки пальцев;</li>
        <li>иное.</li>
      </ul>
      <p>4.3.2. Клиенты и контрагенты оператора (физические лица);</p>
      <p>В данной категории субъектов оператором обрабатываются персональные данные, полученные оператором:</p>
      <p>В целях исполнения договора, стороной которого является субъект персональных данных:</p>

      <h3>Перечень персональных данных для категории: только общие персональные данные</h3>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>номера телефонов (домашний, мобильный, рабочий);</li>
        <li>адрес электронной почты;</li>
        <li>замещаемая должность;</li>
        <li>данные паспорта или иного удостоверяющего личность документа;</li>
        <li>
          сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного,
          гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества
          собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии
          предпринимательской деятельностью;
        </li>
        <li>иное.</li>
      </ul>
      <h3>Перечень персональных данных для категории: общие и специальные персональные данные</h3>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>номера телефонов (домашний, мобильный, рабочий);</li>
        <li>адрес электронной почты;</li>
        <li>замещаемая должность;</li>
        <li>данные паспорта или иного удостоверяющего личность документа;</li>
        <li>
          сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного,
          гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества
          собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии
          предпринимательской деятельностью;
        </li>
        <li>расовая, национальная принадлежности;</li>
        <li>политические взгляды;</li>
        <li>религиозные или философские убеждения;</li>
        <li>состояние здоровья, интимной жизни;</li>
        <li>сведения о судимости;</li>
        <li>иное.</li>
      </ul>
      <h3>Перечень персональных данных для категории: общие и биометрические персональные данные</h3>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>номера телефонов (домашний, мобильный, рабочий);</li>
        <li>адрес электронной почты;</li>
        <li>замещаемая должность;</li>
        <li>данные паспорта или иного удостоверяющего личность документа;</li>
        <li>
          сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного,
          гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества
          собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии
          предпринимательской деятельностью;
        </li>
        <li>фото;</li>
        <li>видео;</li>
        <li>отпечатки пальцев;</li>
        <li>иное.</li>
      </ul>
      <h3>Перечень персональных данных для категории: общие, специальные и биометрические персональные данные</h3>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>номера телефонов (домашний, мобильный, рабочий);</li>
        <li>адрес электронной почты;</li>
        <li>замещаемая должность;</li>
        <li>данные паспорта или иного удостоверяющего личность документа;</li>
        <li>
          сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного,
          гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества
          собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии
          предпринимательской деятельностью;
        </li>
        <li>расовая, национальная принадлежности;</li>
        <li>политические взгляды;</li>
        <li>религиозные или философские убеждения;</li>
        <li>состояние здоровья, интимной жизни;</li>
        <li>сведения о судимости;</li>
        <li>фото;</li>
        <li>видео;</li>
        <li>отпечатки пальцев;</li>
        <li>иное.</li>
      </ul>
      <p>
        4.4. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности,
        политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни, допускается:
      </p>
      <ul>
        <li>
          в случае, если субъект персональных данных дал согласие в письменной форме на обработку своих персональных
          данных;
        </li>
        <li>
          в соответствии с законодательством о государственной социальной помощи, трудовым законодательством, пенсионным
          законодательством Российской Федерации.
        </li>
      </ul>
      <h2>5. Порядок и условия обработки персональных данных.</h2>
      <p>
        5.1. Оператор осуществляет обработку персональных данных - операции, совершаемые с использованием средств
        автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление,
        доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
      </p>
      <p>
        5.2. Обработка персональных данных осуществляется с соблюдением принципов и правил, предусмотренных{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/200">Законом</a> о персональных данных.
      </p>
      <p>
        5.3. Обработка персональных данных оператором ограничивается достижением конкретных, заранее определенных и
        законных целей. Обработке подлежат только персональные данные, которые отвечают целям их обработки. Содержание и
        объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки.
      </p>
      <p>
        5.4. Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных
        данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных
        не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по
        которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо
        обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное
        не предусмотрено федеральным законом.
      </p>
      <p>
        5.5. При осуществлении хранения персональных данных оператор персональных данных обязан использовать базы
        данных, находящиеся на территории Российской Федерации, в соответствии с{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/41815">ч. 5 ст. 18</a> Закона о персональных
        данных.
      </p>
      <p>
        Персональные данные при их обработке, осуществляемой без использования средств автоматизации, должны
        обособляться от иной информации, в частности путем фиксации их на отдельных материальных носителях персональных
        данных (далее - материальные носители), в специальных разделах или на полях форм (бланков). При фиксации
        персональных данных на материальных носителях не допускается фиксация на одном материальном носителе
        персональных данных, цели обработки которых заведомо не совместимы. Для обработки различных категорий
        персональных данных, осуществляемой без использования средств автоматизации, для каждой категории персональных
        данных должен использоваться отдельный материальный носитель.
      </p>
      <p>
        5.6. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных
        данных, истечение срока действия согласия или отзыв согласия субъекта персональных данных на обработку его
        персональных данных, а также выявление неправомерной обработки персональных данных.
      </p>
      <p>
        5.7. Оператор вправе поручить обработку персональных данных другому лицу на основании заключаемого с этим лицом
        договора, в том числе государственного или муниципального контракта.
      </p>
      <p>
        Лицо, осуществляющее обработку персональных данных по поручению оператора, обязано соблюдать принципы и правила
        обработки персональных данных, предусмотренные{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/200">Законом</a> о персональных данных, соблюдать
        конфиденциальность персональных данных, принимать необходимые меры, направленные на обеспечение выполнения
        обязанностей, предусмотренных Законом о персональных данных.
      </p>
      <p>
        Кроме того, оператор вправе передавать персональные данные органам дознания и следствия, иным уполномоченным
        органам по основаниям, предусмотренным действующим законодательством Российской Федерации.
      </p>
      <p>
        5.8. Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не
        распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено
        федеральным законом.
      </p>
      <p>
        Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения,
        оформляется отдельно от иных согласий субъекта персональных данных на обработку его персональных данных.
        Оператор обязан обеспечить субъекту персональных данных возможность определить перечень персональных данных по
        каждой категории персональных данных, указанной в согласии на обработку персональных данных, разрешенных
        субъектом персональных данных для распространения.
      </p>
      <p>
        Передача (распространение, предоставление, доступ) персональных данных, разрешенных субъектом персональных
        данных для распространения, должна быть прекращена в любое время по требованию субъекта персональных данных.
        Данное требование должно включать в себя фамилию, имя, отчество (при наличии), контактную информацию (номер
        телефона, адрес электронной почты или почтовый адрес) субъекта персональных данных, а также перечень
        персональных данных, обработка которых подлежит прекращению. Указанные в данном требовании персональные данные
        могут обрабатываться только оператором, которому оно направлено.
      </p>
      <p>
        5.9. Оператор обязан принимать меры, необходимые и достаточные для обеспечения выполнения обязанностей,
        предусмотренных <a href="https://internet.garant.ru/#/document/12148567/entry/0">Законом</a> о персональных
        данных и принятыми в соответствии с ним нормативными правовыми актами. Состав и перечень мер оператор определяет
        самостоятельно.
      </p>
      <p>
        5.10. Оператор при обработке персональных данных принимает необходимые правовые, организационные и технические
        меры или обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним,
        уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также
        от иных неправомерных действий в отношении персональных данных.
      </p>
      <h2>6. Порядок и условия обработки биометрических персональных данных</h2>
      <p>
        6.1. К биометрическим персональным данным относятся сведения, которые характеризуют физиологические и
        биологические особенности человека, на основании которых можно установить его личность и которые используются
        оператором для установления личности субъекта персональных данных.
      </p>
      <p>
        6.2. Обработка биометрических персональных данных может осуществляться только при наличии согласия в письменной
        форме субъекта персональных данных, за исключением случаев, связанных с реализацией международных договоров
        Российской Федерации о реадмиссии, в связи с осуществлением правосудия и исполнением судебных актов, в связи с
        проведением обязательной государственной дактилоскопической регистрации, а также в случаях, предусмотренных
        законодательством Российской Федерации об обороне, о безопасности, о противодействии терроризму, о транспортной
        безопасности, о противодействии коррупции, об оперативно-разыскной деятельности, о государственной службе,
        уголовно-исполнительным законодательством Российской Федерации, законодательством Российской Федерации о порядке
        выезда из Российской Федерации и въезда в Российскую Федерацию, о гражданстве Российской Федерации,
        законодательством Российской Федерации о нотариате.
      </p>
      <p>
        6.3. Предоставление биометрических персональных данных не может быть обязательным, за исключением случаев,
        предусмотренных <a href="https://internet.garant.ru/#/document/55727561/entry/602">пунктом 6.2</a> настоящей
        Политики. Оператор не вправе отказывать в обслуживании в случае отказа субъекта персональных данных предоставить
        биометрические персональные данные и (или) дать согласие на обработку персональных данных, если в соответствии с
        федеральным законом получение оператором согласия на обработку персональных данных не является обязательным.
      </p>
      <p>
        6.4. Обработка биометрических персональных данных осуществляется оператором в соответствии с требованиями к
        защите биометрических персональных данных, установленными в соответствии со{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/19">статьей 19</a> Закона о персональных данных.
      </p>
      <p>
        6.5. Использование и хранение биометрических персональных данных вне информационных систем персональных данных
        могут осуществляться только на таких материальных носителях информации и с применением такой технологии ее
        хранения, которые обеспечивают защиту этих данных от неправомерного или случайного доступа к ним, их
        уничтожения, изменения, блокирования, копирования, предоставления, распространения.
      </p>
      <p>
        6.6. Под материальным носителем понимается машиночитаемый носитель информации (в том числе магнитный и
        электронный), на котором осуществляются запись и хранение сведений, характеризующих физиологические особенности
        человека и на основе которых можно установить его личность.
      </p>
      <p>6.7. Оператор утверждает порядок передачи материальных носителей уполномоченным лицам.</p>
      <p>
        6.8. Материальный носитель должен использоваться в течение срока, установленного оператором, осуществившим
        запись биометрических персональных данных на материальный носитель, но не более срока эксплуатации,
        установленного изготовителем материального носителя.
      </p>
      <p>6.9. Оператор обязан:</p>
      <p>осуществлять учет количества экземпляров материальных носителей;</p>
      <p>
        осуществлять присвоение материальному носителю уникального идентификационного номера, позволяющего точно
        определить оператора, осуществившего запись биометрических персональных данных на материальный носитель.
      </p>
      <p>
        6.10. Технологии хранения биометрических персональных данных вне информационных систем персональных данных
        должны обеспечивать:
      </p>
      <p>доступ к информации, содержащейся на материальном носителе, для уполномоченных лиц;</p>
      <p>
        применение средств электронной подписи или иных информационных технологий, позволяющих сохранить целостность и
        неизменность биометрических персональных данных, записанных на материальный носитель;
      </p>
      <p>
        проверку наличия письменного согласия субъекта персональных данных на обработку его биометрических персональных
        данных или наличия иных оснований обработки персональных данных, установленных законодательством Российской
        Федерации в сфере отношений, связанных с обработкой персональных данных.
      </p>
      <p>
        6.11. При хранении биометрических персональных данных вне информационных систем персональных данных должна
        обеспечиваться регистрация фактов несанкционированной повторной и дополнительной записи информации после ее
        извлечения из информационной системы персональных данных.
      </p>
      <h2>
        7. Актуализация, исправление, удаление и уничтожение персональных данных, ответы на запросы субъектов на доступ
        к персональным данным
      </h2>
      <p>
        7.1. Оператор обязан сообщить в порядке, предусмотренном{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/14">статьей 14</a> Закона о персональных данных,
        субъекту персональных данных или его представителю информацию о наличии персональных данных, относящихся к
        соответствующему субъекту персональных данных, а также предоставить возможность ознакомления с этими
        персональными данными при обращении субъекта персональных данных или его представителя либо в течение десяти
        рабочих дней с даты получения запроса субъекта персональных данных или его представителя. Указанный срок может
        быть продлен, но не более чем на пять рабочих дней в случае направления оператором в адрес субъекта персональных
        данных мотивированного уведомления с указанием причин продления срока предоставления запрашиваемой информации.
      </p>
      <p>
        7.2. Оператор обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность
        ознакомления с персональными данными, относящимися к этому субъекту персональных данных. В срок, не превышающий
        семи рабочих дней со дня предоставления субъектом персональных данных или его представителем сведений,
        подтверждающих, что персональные данные являются неполными, неточными или неактуальными, оператор обязан внести
        в них необходимые изменения. В срок, не превышающий семи рабочих дней со дня представления субъектом
        персональных данных или его представителем сведений, подтверждающих, что такие персональные данные являются
        незаконно полученными или не являются необходимыми для заявленной цели обработки, оператор обязан уничтожить
        такие персональные данные. Оператор обязан уведомить субъекта персональных данных или его представителя о
        внесенных изменениях и предпринятых мерах и принять разумные меры для уведомления третьих лиц, которым
        персональные данные этого субъекта были переданы.
      </p>
      <p>
        7.3. В случае подтверждения факта неточности персональных данных оператор на основании сведений, представленных
        субъектом персональных данных или его представителем либо уполномоченным органом по защите прав субъектов
        персональных данных, или иных необходимых документов обязан уточнить персональные данные либо обеспечить их
        уточнение (если обработка персональных данных осуществляется другим лицом, действующим по поручению оператора) в
        течение семи рабочих дней со дня представления таких сведений и снять блокирование персональных данных.
      </p>
      <p>
        7.4. Оператор обязан прекратить обработку персональных данных или обеспечить прекращение обработки персональных
        данных лицом, действующим по поручению оператора:
      </p>
      <ul>
        <li>
          в случае выявления неправомерной обработки персональных данных, осуществляемой оператором или лицом,
          действующим по поручению оператора, в срок, не превышающий трех рабочих дней с даты этого выявления;
        </li>
        <li>в случае отзыва субъектом персональных данных согласия на обработку его персональных данных;</li>
        <li>
          в случае достижения цели обработки персональных данных и уничтожить персональные данные или обеспечить их
          уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению
          оператора) в срок, не превышающий тридцати дней с даты достижения цели обработки персональных данных. В случае
          отсутствия возможности уничтожения персональных данных в течение указанного срока оператор осуществляет
          блокирование таких персональных данных или обеспечивает их блокирование (если обработка персональных данных
          осуществляется другим лицом, действующим по поручению оператора) и обеспечивает уничтожение персональных
          данных в срок не более чем шесть месяцев, если иной срок не установлен федеральными законами.
        </li>
      </ul>
      <p>
        7.5. В случае установления факта неправомерной или случайной передачи (предоставления, распространения, доступа)
        персональных данных, повлекшей нарушение прав субъектов персональных данных, оператор обязан с момента выявления
        такого инцидента оператором, уполномоченным органом по защите прав субъектов персональных данных или иным
        заинтересованным лицом уведомить уполномоченный орган по защите прав субъектов персональных данных:
      </p>
      <ul>
        <li>
          в течение двадцати четырех часов о произошедшем инциденте, о предполагаемых причинах, повлекших нарушение прав
          субъектов персональных данных, и предполагаемом вреде, нанесенном правам субъектов персональных данных, о
          принятых мерах по устранению последствий соответствующего инцидента, а также предоставить сведения о лице,
          уполномоченном оператором на взаимодействие с уполномоченным органом по защите прав субъектов персональных
          данных, по вопросам, связанным с выявленным инцидентом;
        </li>
        <li>
          в течение семидесяти двух часов о результатах внутреннего расследования выявленного инцидента, а также
          предоставить сведения о лицах, действия которых стали причиной выявленного инцидента (при наличии).
        </li>
      </ul>
      <p>
        7.6. В случае обращения субъекта персональных данных к оператору с требованием о прекращении обработки
        персональных данных оператор в срок, не превышающий десяти рабочих дней с даты получения им соответствующего
        требования, обязан прекратить их обработку или обеспечить прекращение такой обработки (если такая обработка
        осуществляется лицом, осуществляющим обработку персональных данных), за исключением случаев, предусмотренных{' '}
        <a href="https://internet.garant.ru/#/document/12148567/entry/0">Законом</a> о персональных данных.
      </p>
      <p>
        Указанный срок может быть продлен, но не более чем на пять рабочих дней в случае направления оператором в адрес
        субъекта персональных данных мотивированного уведомления с указанием причин продления срока предоставления
        запрашиваемой информации.
      </p>
      <p>
        7.7. После истечения срока нормативного хранения документов, содержащих персональные данные субъекта, или при
        наступлении иных законных оснований документы подлежат уничтожению.
      </p>
      <p>7.8. Оператор для этих целей создает экспертную комиссию и проводит экспертизу ценности документов.</p>
      <p>7.9. По результатам экспертизы документы, содержащие персональные данные субъекта и подлежащие уничтожению:</p>
      <ul>
        <li>на бумажном носителе - уничтожаются путем измельчения в шредере или сжигания.</li>
        <li>
          в электронном виде - стираются с информационных носителей либо физически уничтожаются сами носители, на
          которых хранится информация.
        </li>
      </ul>
      <h2>8. Получение согласия субъекта на&nbsp;обработку его персональных данных</h2>
      <p>
        8.1. В случаях обработки, не предусмотренных действующим законодательством или договором с субъектом явно,
        обработка осуществляется после получения согласия субъекта персональных данных. Обязательным случаем получения
        предварительного согласия является, например, контакт с потенциальным клиентом в маркетинговых целях, при
        продвижении товаров, программных продуктов, инвестиционных проектов, работ и услуг оператора на рынке.&nbsp;
      </p>
      <p>
        8.2. Согласие может быть выражено в форме совершения субъектом персональных данных конклюдентных действий,
        например:&nbsp;
      </p>
      <p>
        8.2.1. принятия условий договора-оферты, пользовательского соглашения, правил пользования информационными
        ресурсами и программными продуктами оператора;&nbsp;
      </p>
      <p>
        8.2.2. продолжения использования приложений, сервисов, информационных ресурсов, веб-сайтов оператора,
        взаимодействия с&nbsp;их пользовательскими интерфейсами после уведомления пользователя об&nbsp;обработке данных;
      </p>
      <p>
        8.2.3. предоставления необходимых разрешений мобильному приложению при запросе в момент установки или
        использования;&nbsp;
      </p>
      <p>8.2.4. проставления отметок, заполнения соответствующих полей в формах, бланках;&nbsp;</p>
      <p>8.2.5. поддержания электронной переписки, в которой говорится об обработке;&nbsp;</p>
      <p>8.2.6. иных действий, совершаемых субъектом, по которым можно судить о его волеизъявлении.&nbsp;</p>
      <p>
        8.3. В отдельных случаях, предусмотренных &nbsp;законодательством Российской Федерации, согласие оформляется в
        письменной форме с указанием сведений, предусмотренных &nbsp;Законом, а также в соответствии с иными применимыми
        требованиями, типовыми формами.&nbsp;
      </p>
      <p>
        8.4. В случаях обработки персональных данных, полученных не от субъекта напрямую, а от других лиц на основании
        договора или поручения на обработку, обязанность получения согласия субъекта может быть возложена на лицо, от
        которого получены персональные данные.&nbsp;
      </p>
      <p>
        8.5. В&nbsp;случае отказа субъекта от&nbsp;предоставления в&nbsp;необходимом и&nbsp;достаточном объеме его
        персональных данных, оператор не&nbsp;сможет осуществить необходимые действия для&nbsp;достижения
        соответствующих обработке целей. Например, в&nbsp;таком случае может быть не&nbsp;завершена регистрация
        пользователя в&nbsp;программном продукте, на сайте, платформе, услуги по&nbsp;договору могут быть
        не&nbsp;оказаны, работы не&nbsp;выполнены, услуги не оказаны, деньги не приняты&nbsp;&nbsp;и т.д.&nbsp;
      </p>
      <h2>9. Обработка электронных пользовательских данных, включая cookies</h2>
      <p>
        9.1. Оператор в целях обработки персональных данных, установленных Политикой, может собирать электронные
        пользовательские данные на своих сайтах автоматически, без необходимости участия пользователя и совершения им
        каких-либо действий по отправке данных.&nbsp;
      </p>
      <p>
        9.2. Достоверность собранных таким способом электронных данных оператором не проверяется, информация
        обрабатываются &laquo;как есть&raquo; в том виде, как она поступила с клиентского устройства.&nbsp;
      </p>
      <p>
        9.3. Посетителям и пользователям сайтов оператора могут показываться всплывающие уведомления о сборе и обработке
        данных cookies с ссылкой на Политику и кнопками принятия условий обработки либо закрытия всплывающего
        уведомления.&nbsp;
      </p>
      <p>
        9.4. Такие уведомления означают, что при посещении и использовании сайтов, информационных ресурсов и
        веб-приложений оператора в браузер на устройстве пользователя может сохраняться информация (например, данные
        cookies), позволяющая в дальнейшем идентифицировать пользователя или устройство, запомнить сеанс работы или
        сохранить некоторые настройки и предпочтения пользователя, специфичные для этих конкретных сайтов. Такая
        информация после сохранения в браузер и до истечения установленного срока действия или удаления с устройства
        будет отправляться при каждом последующем запросе на сайт, от имени которого они были сохранены, вместе с этим
        запросом для обработки на стороне оператора.&nbsp;
      </p>
      <p>
        9.5. Обработка данных cookies необходима оператору для корректной работы сайтов, в частности, их функций,
        относящихся к доступу зарегистрированных пользователей программных продуктов, услуг, работ и ресурсов оператора;
        персонализации пользователей; повышения эффективности и удобства работы с сайтами, а также иных целей,
        предусмотренных Политикой.&nbsp;
      </p>
      <p>
        9.6. Кроме обработки данных cookies, установленных самими сайтами оператора, пользователям и посетителям могут
        устанавливаться cookies, относящиеся к сайтам сторонних организаций, например, в случаях, когда на сайтах
        оператора используются сторонние компоненты и программное обеспечение. Обработка таких cookies регулируется
        политиками соответствующих сайтов, к которым они относятся, и может изменяться без уведомления пользователей
        сайтов оператора. К таким случаям может относиться размещение на сайтах:&nbsp;
      </p>
      <p>
        9.6.1. счетчиков посещений, аналитических и статистических сервисов, таких как Яндекс.Метрика или Google
        Analytics для сбора статистики посещаемости общедоступных страниц сайтов;&nbsp;
      </p>
      <p>
        9.6.2. виджетов вспомогательных сервисов для сбора обратной связи, организации чатов и иных видов коммуникаций с
        пользователями;&nbsp;
      </p>
      <p>9.6.3. систем контекстной рекламы, баннерных и иных маркетинговых сетей;&nbsp;</p>
      <p>9.6.4. кнопок авторизации на сайтах с помощью учетных записей в социальных сетях;&nbsp;</p>
      <p>9.6.5. иных сторонних компонент, используемых оператором на своих сайтах.&nbsp;</p>
      <p>
        9.7. Принятие пользователем условий обработки cookies или закрытие всплывающего уведомления в соответствии с
        Политикой расценивается как согласие на обработку данных cookies на сайтах оператора.&nbsp;
      </p>
      <p>
        9.8. В случае если пользователь не согласен с обработкой cookies, он должен принять на себя риск, что в таком
        случае функции и возможности сайта могут не быть доступны в полном объеме, а затем следовать по одному из
        следующих вариантов:&nbsp;
      </p>
      <p>
        9.8.1. произвести самостоятельную настройку своего браузера в соответствии с документацией или справкой к нему
        таким образом, чтобы он на постоянной основе не разрешал принимать и отправлять данные cookies для любых сайтов
        либо для конкретного сайта оператора или сайта стороннего компонента;&nbsp;
      </p>
      <p>
        9.8.2. переключиться в специальный режим &laquo;инкогнито&raquo; браузера для использования сайтом cookies до
        закрытия окна браузера или до переключения обратно в обычный режим;&nbsp;
      </p>
      <p>9.8.3. покинуть сайт во избежание дальнейшей обработки cookies.&nbsp;</p>
      <p>
        9.9. Пользователь может самостоятельно через встроенные в браузеры средства работы с данными cookies управлять
        сохраненными данными, в том числе, удалять или просматривать сведения об установленных сайтами cookies,
        включая:&nbsp;
      </p>
      <p>9.9.1. адреса сайтов и пути на них, куда будут отправляться cookies;&nbsp;</p>
      <p>9.9.2. названия и значения параметров, хранящихся в cookies;&nbsp;</p>
      <p>9.9.3. сроки действия cookies.</p>
    </RichText>
  )
}

export default DocsTermsPage
