import React, { useEffect } from 'react'
import Alert from 'components/Alert'
import Loader from 'components/Loader'
import useMutation from 'lib/useMutation'
import RichText from 'components/RichText'
import Button from 'components/Button'
import { getDashboardRoute } from 'lib/routes'

const ConfirmNewEmailPage = ({ match }) => {
  const confirmNewEmailToken = match.params.confirmNewEmailToken
  const [confirmNewEmail, { called, loading, error }] = useMutation('confirmNewEmail', {
    errorCodes: {
      ERR_INVALID_CONFIRM_EMAIL_TOKEN: 'Ссылка для подтверждения смены адреса электронной почты не действительна',
    },
  })
  useEffect(() => {
    confirmNewEmail({ variables: { input: { confirmNewEmailToken } } }).catch(e => {})
  }, [confirmNewEmailToken, confirmNewEmail])
  if (loading || !called) return <Loader type="page" />
  return error ? (
    <RichText>
      <h1>Ошибка подтверждения смены почты</h1>
      <Alert type="danger" message={error.humanMessage} />
      <br />
      <Button to={getDashboardRoute()}>Перейти в сервис</Button>
    </RichText>
  ) : (
    <RichText>
      <h1>Смена почты успешно подтверждена</h1>
      <br />
      <Button to={getDashboardRoute()}>Перейти в сервис</Button>
    </RichText>
  )
}

export default ConfirmNewEmailPage
