import React from 'react'
import Input from 'components/Input'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import Form, { FormItems, useForm } from 'components/Form'
import yup from 'lib/validation'
import useMutation from 'lib/useMutation'
import { getWelcomeRoute } from 'lib/routes'
import { useApolloClient } from '@apollo/client'
import PageWrapper from 'components/PageWrapper'
import Cookies from 'js-cookie'
import mixpanel from 'mixpanel-browser'
import PhoneVerification, { usePhoneVerification } from '../../components/PhoneVerification/index.js'

const SignUpPage = ({ history }) => {
  const apolloClient = useApolloClient()
  const [signUp] = useMutation('signUp')
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      verificationCode: '',
      password: '',
      passwordAgain: '',
      referralCode: Cookies.get('referralCode') || '',
    },
    onSubmit: ({ email, name, phone, password, referralCode, verificationCode }) => {
      return signUp({
        variables: { input: { email, name, phone, password, referralCode, verificationCode } },
      }).then(({ token, user }) => {
        if (token) {
          if (process.env.REACT_APP_MIXPANEL_ON === 'true') {
            mixpanel.alias(user.id)
            mixpanel.track('Sign Up', {
              referralCode,
            })
          }
          Cookies.set('token', token, { expires: 99999 })
          return apolloClient.resetStore().then(() => {
            history.push(getWelcomeRoute())
            return false
          })
        }
      })
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      name: yup.string().required(),
      phone: yup.string().required(),
      verificationCode: yup.string().required(),
      password: yup.string().required(),
      passwordAgain: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
        .required(),
    }),
    successMessage: false,
  })
  const phoneVerification = usePhoneVerification({
    purpose: 'signUp',
    onChange: ({ verificationCode, phone }) => {
      formik.setFieldValue('phone', phone)
      formik.setFieldValue('verificationCode', verificationCode)
    },
    formik,
    requestButton: 'Зарегистрироваться',
    phoneLabel: 'Ваш номер телефона',
  })
  return (
    <PageWrapper redirerctAuthorized>
      <Form formik={phoneVerification.formikInForm}>
        <FormItems>
          <PhoneVerification {...phoneVerification} />
          {formik.values.phone && (
            <>
              <Input icon="profile" label="ФИО" name="name" formik={formik} />
              <Input icon="mail" label="Адрес электронной почты" name="email" inputMode="email" formik={formik} />
              <Input icon="ticket" label="Реферальный код" hint="Если есть" name="referralCode" formik={formik} />
              <Input icon="lock" label="Пароль" name="password" type="password" formik={formik} />
              <Input icon="lock" label="Подтвердите пароль" name="passwordAgain" type="password" formik={formik} />
              <Alert {...alertProps} />
              <Buttons>
                <Button {...buttonProps}>Зарегистрироваться</Button>
              </Buttons>
            </>
          )}
        </FormItems>
      </Form>
    </PageWrapper>
  )
}

export default SignUpPage
