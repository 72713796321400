/* eslint-disable no-template-curly-in-string, no-useless-escape */
import * as yup from 'yup'

yup.setLocale({
  string: {
    email: 'Некорректный адрес электронной почты',
  },
  number: {
    positive: 'Должно быть положительным числом',
    integer: 'Должно быть целым числом',
  },
  mixed: {
    required: 'Обязательное поле',
  },
  array: {
    min: 'Должно быть не меньше ${min} элемента',
  },
})

export default yup
