import { gql } from '@apollo/client'

export const isResetPasswordTokenValidQuery = gql`
  query($input: IsResetPasswordTokenValidInput!) {
    isResetPasswordTokenValid(input: $input) {
      valid
    }
  }
`

export const isResetPasswordTokenValidOptions = { parser: [['valid', 'isResetPasswordTokenValid.valid']] }
