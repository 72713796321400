import PageWrapper from 'components/PageWrapper'
import useQuery from 'lib/useQuery'
import { NewsPage } from '../NewsPage'

const GetNewsWrapper = (props) => {
  const queryResult = useQuery('getNewsPosts', {
    fetchPolicy: 'network-only',
  })
  return (
    <PageWrapper queryResult={queryResult}>
      {() => <NewsPage newsPosts={queryResult.newsPosts} {...props} withSeparation={false} />}
    </PageWrapper>
  )
}

export default GetNewsWrapper
