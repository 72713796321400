import PhotoSwipe from 'photoswipe'

export const openGallery = ({ imagesUrls }) => {
  const d = imagesUrls.map((imageUrl) => ({ src: imageUrl, w: 0, h: 0 }))
  const pswp = new PhotoSwipe({
    index: 0,
    dataSource: d,
    // showHideAnimationType: 'fade',
    initialZoomLevel: 'fit',
  })
  pswp.on('gettingData', function (e) {
    console.log(e)
    if (!e.data.w || !e.data.h) {
      var img = new Image()
      const index = e.index
      img.onload = function () {
        console.log(123, this)
        d[index].w = this.width
        d[index].h = this.height
        pswp.options.dataSource = d
        // pswp.updateSize(true)
        pswp.refreshSlideContent(index)
      }
      img.src = e.data.src
    }
  })
  pswp.init()
}
