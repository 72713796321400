import { gql } from '@apollo/client'

export const requestPhoneChangingMutation = gql`
  mutation($input: RequestPhoneChangingInput!) {
    requestPhoneChanging(input: $input) {
      me {
        id
        phone
      }
    }
  }
`

export const requestPhoneChangingOptions = { parser: [['me', 'requestPhoneChanging.me']] }
