import cn from 'classnames'
import Alert from 'components/Alert'
import Icon from 'components/Icon'
import Loader from 'components/Loader'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canManageUserPassports } from 'lib/can'
import { getPassportRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import css from './styles.module.scss'

const PassportsListItem = ({ passport }) => {
  const history = useHistory()
  const status =
    passport.status === 'accepted' || passport.status === 'rejected' ? 'Данные проверены' : 'Ожидает проверки'
  return (
    <>
      <div className={cn(css.row, css.clickable)} onClick={() => history.push(getPassportRoute(passport.id))}>
        <div className={css.cell}>{passport.user.displayName}</div>
        <div className={css.cell}>{passport.user.email}</div>
        <div className={css.cell}>{passport.user.phone}</div>
        <div className={css.cell}>{status}</div>
      </div>
      <div className={cn(css.card, css.clickable)} onClick={() => history.push(getPassportRoute(passport.id))}>
        {/*<div className={css.cardHeader}>*/}
        {/*  <div className={css.number}>{passport.serialNumber}</div>*/}
        {/*</div>*/}
        {passport.user.displayName && <div className={css.title}>{passport.user.displayName}</div>}
        <div className={css.info}>
          {passport.user.email && (
            <div className={css.infoItem}>
              <div className={css.icon}>
                <Icon name="info" />
              </div>
              <div className={css.text}>{status}</div>
            </div>
          )}
          {passport.user.email && (
            <div className={css.infoItem}>
              <div className={css.icon}>
                <Icon name="mail" />
              </div>
              <div className={css.text}>{passport.user.email}</div>
            </div>
          )}
          {passport.user.phone && (
            <div className={css.infoItem}>
              <div className={css.icon}>
                <Icon name="phone" />
              </div>
              <div className={css.text}>{passport.user.phone}</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const PassportsList = ({ me }) => {
  const { passports, pageInfo, loading, error, fetchMore } = useQuery('getPassports', {
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!passports.length) return <Alert message="Нету активных заявок на установку паспортных данных" />
  return (
    <div className={css.passports}>
      <div className={css.header}>
        <div className={css.row}>
          <div className={css.cell}>Пользователь</div>
          <div className={css.cell}>Эл. почта</div>
          <div className={css.cell}>Телефон</div>
          <div className={css.cell}>Статус</div>
        </div>
      </div>
      <div className={css.items}>
        <InfiniteScroll
          threshold={250}
          loadMore={async () => {
            return fetchMore({
              variables: {
                input: {
                  endCursor: pageInfo.endCursor,
                  limit: 30,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return {
                  getPassports: {
                    ...fetchMoreResult.getPassports,
                    passports: [...prev.getPassports.passports, ...fetchMoreResult.getPassports.passports],
                  },
                }
              },
            })
          }}
          hasMore={pageInfo.hasNextPage}
          loader={
            <div className={css.loader} key="loader">
              <Loader type="section" />
            </div>
          }
        >
          {passports.map((passport) => (
            <PassportsListItem key={passport.id} passport={passport} me={me} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

const PassportsPage = () => {
  return (
    <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canManageUserPassports(me)}>
      {() => (
        <Segment title="Заявки на установку паспортных данных">
          <PassportsList />
        </Segment>
      )}
    </PageWrapper>
  )
}

export default PassportsPage
