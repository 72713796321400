import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'

const Select = ({
  label,
  formik,
  name,
  hint,
  onChange,
  fullWidth,
  width,
  disabled,
  options,
  isMulti = false,
  placeholder = '',
  creatable = false,
  ...restProps
}) => {
  const error = _.get(formik.errors, name)
  const value = _.get(formik.values, name)
  const touched = _.get(formik.touched, name)
  const selectedOption = isMulti
    ? _.filter(options, (option) => value.includes(option.value))
    : _.find(options, (option) => option.value === value)
  const selectProps = {
    id: name,
    value: selectedOption,
    onChange: (selectedOption) => {
      if (isMulti) {
        formik.setFieldValue(
          name,
          selectedOption.map((so) => so.value),
          true
        )
      } else {
        formik.setFieldValue(name, selectedOption.value, true)
      }
      setTimeout(() => {
        formik.setFieldTouched(name)
      }, 1)
      if (onChange) onChange(selectedOption)
    },
    onBlur: () => {
      formik.setFieldTouched(name)
    },
    isMulti: isMulti,
    name: name,
    className: css.select,
    isDisabled: disabled,
    styles: {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#898989' : '#191a1d',
        color: state.isSelected ? '#d3e2e9' : '#d3e2e9',
        // '&:active': {
        //   backgroundColor: '#f00',
        // },
        '&:hover': {
          backgroundColor: '#4f4f4f',
        },
      }),
      control: (provided, state) => ({
        ...provided,
        maxWidth: fullWidth ? 'none' : 300,
        borderRadius: state.menuIsOpen ? '4px 4px 0 0' : 4,
        transition: 'none',
        // borderColor: state.isFocused ? '#2684ff' : !!error && touched ? '#c00' : '#666',
        borderColor: !!error && touched ? '#EB281D' : '#191a1d',
        borderWidth: 1,
        lineHeight: '24px',
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.5 : 1,
        boxShadow: 'none',
        padding: '8px 26px 8px 13px',
        minHeight: '40px',
        backgroundColor: '#191a1d',
        '&:hover': {
          // borderColor: state.isFocused ? '#2684ff' : !!error && touched ? '#c00' : '#666',
          boxShadow: 'none',
        },
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        color: '#666',
        padding: 0,
        position: 'absolute',
        marginTop: -10,
        right: 7,
        top: '50%',
        '&:hover': {
          color: '#666',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
      }),
      input: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
        lineHeight: 1,
        fontFamily: 'Rubik, Helvetica, Arial, sans-serif',
      }),
      singleValue: (provided) => ({
        ...provided,
        maxWidth: '100%',
        margin: 0,
        transform: 'translateY(-50%)',
        color: '#d3e2e9',
        top: '52%',
      }),
      placeholder: (provided) => ({
        ...provided,
        maxWidth: '100%',
        margin: 0,
        transform: 'translateY(-50%)',
        top: '52%',
      }),
      menu: (provided) => ({
        ...provided,
        margin: 0,
        maxWidth: fullWidth ? 'none' : 300,
        boxShadow: 'none',
        border: 'none',
        backgroundColor: '#191a1d',
        borderTop: 'none',
        borderRadius: '0 0 4px 4px',
        padding: 0,
      }),
    },
    options: options,
    placeholder: placeholder,
  }
  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })} style={width ? { width } : {}}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      {creatable ? (
        <CreatableSelect formatCreateLabel={(label) => `Создать «${label}»`} {...selectProps} {...restProps} />
      ) : (
        <ReactSelect {...selectProps} {...restProps} />
      )}
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default Select
