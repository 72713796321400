import Alert from 'components/Alert'
import Button from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import Link from 'components/Link'
import Segment from 'components/Segment'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'
import { useState } from 'react'
import css from './styles.module.scss'

const DummyReferralCreatePage = ({ referralName, referralCode }) => {
  const [dummyReferral, setDummyReferral] = useState(null)
  const [createDummyReferral] = useMutation('createDummyReferral')
  const { formik, buttonProps, alertProps, success, reset } = useForm({
    forgetSuccessDelay: 0,
    onReset: () => {
      setDummyReferral(null)
    },
    initialValues: {
      fio: '',
    },
    onSubmit: ({ fio, sum, telegram, about, referralCode }) => {
      return createDummyReferral({
        variables: { input: { fio } },
      }).then(({ dummyReferral }) => {
        setDummyReferral(dummyReferral)
        return { dummyReferral }
      })
    },
    validationSchema: yup.object({
      fio: yup.string().required(),
    }),
    successMessage: ({ dummyReferral }) => `Рефеальная ссылка для «${dummyReferral.fio}» успешно создана`,
  })
  return (
    <Segment className={css.wrapper} title={'Создание реферальной ссылки'}>
      {!success ? (
        <Form formik={formik}>
          <FormItems>
            <Input fullWidth label="ФИО" name="fio" placeholder="Фамилия Имя Отчество" formik={formik} />
            <Alert {...alertProps} />
            <Button {...buttonProps}>Создать ссылку</Button>
          </FormItems>
        </Form>
      ) : (
        <FormItems>
          <Alert {...alertProps} />
          <Alert
            message={
              <>
                <Link color="text-white" target="_blank" href={dummyReferral.referralUrl}>
                  {dummyReferral.referralUrl}
                </Link>{' '}
                <Link
                  color="text-white"
                  href="#"
                  type="dashed"
                  onClick={(e) => {
                    e.preventDefault()
                    navigator.clipboard.writeText(dummyReferral.referralUrl)
                  }}
                >
                  Скопировать
                </Link>{' '}
              </>
            }
          />
          <Button onClick={() => reset()}>Продолжить</Button>
        </FormItems>
      )}
    </Segment>
  )
}

export default DummyReferralCreatePage
