import React from 'react'
import css from './styles.module.scss'

const ClearLayout = ({ children }) => {
  return (
    <div className={css.clearLayout}>
      <div className={css.content}>
        {children}
      </div>
    </div>
  )
}

export default ClearLayout
