import { gql } from '@apollo/client'
import { bankAccountFragment } from './bankAccountFragment'

export const rejectBankAccountMutation = gql`
  ${bankAccountFragment}
  mutation ($input: RejectBankAccountInput!) {
    rejectBankAccount(input: $input) {
      bankAccount {
        ...bankAccountFragment
        user {
          id
          displayName
        }
      }
    }
  }
`

export const rejectBankAccountOptions = { parser: [['bankAccount', 'rejectBankAccount.bankAccount']] }
