import cn from 'classnames'
import Button, { Buttons, ButtonWrapper } from 'components/Button'
import useMutation from 'lib/useMutation'
import _ from 'lodash'
import { useRef, useState } from 'react'
import css from './styles.module.scss'

const UploadGenDocTpl = ({
  label,
  formik,
  name,
  hint,
  disabled,
  accept = 'docx',
  refetchGetGenTpls,
  genDocTplIdName,
}) => {
  const inputEl = useRef()
  const [loading, setLoading] = useState(false)
  const [uploadGenDocTpl] = useMutation('uploadGenDocTpl')
  const error = _.get(formik.errors, name)
  return (
    <div className={cn({ [css.uploadGenDocTpl]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      <div className={css.buttons}>
        <Buttons>
          <ButtonWrapper>
            <label className={css.uploadButton}>
              <input
                className={css.fileInput}
                type="file"
                disabled={loading || disabled}
                accept={accept}
                ref={(el) => (inputEl.current = el)}
                onChange={async ({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => {
                  formik.setFieldError(name, null)
                  setLoading(true)
                  try {
                    await uploadGenDocTpl({
                      variables: { input: { file } },
                    })
                    const queryResult = await refetchGetGenTpls()
                    setTimeout(() => {
                      formik.setFieldValue(genDocTplIdName, _.last(queryResult.data.getGenDocTpls.genDocTpls).id, true)
                    }, 0)
                  } catch (err) {
                    console.error(err)
                    formik.setFieldError(name, err.message)
                  } finally {
                    formik.setFieldTouched(name, true, false)
                    setLoading(false)
                    inputEl.current.value = ''
                  }
                }}
              />
              <Button type="button" loading={loading} disabled={loading || disabled} color="outlined" asSpan>
                Загрузить шаблон
              </Button>
            </label>
          </ButtonWrapper>
        </Buttons>
      </div>
      {!!error && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default UploadGenDocTpl
