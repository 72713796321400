import { gql } from '@apollo/client'
import { txFragmentWithUser } from './txFragment'
import { userFragment } from './userFragment'

export const getLastSignUpsAndTxsQuery = gql`
  ${txFragmentWithUser}
  ${userFragment}
  query getLastSignUpsAndTxs {
    getLastSignUpsAndTxs {
      users {
        ...userFragment
      }
      txs {
        ...txFragmentWithUser
      }
    }
  }
`

export const getLastSignUpsAndTxsOptions = {
  parser: [
    ['users', 'getLastSignUpsAndTxs.users'],
    ['txs', 'getLastSignUpsAndTxs.txs'],
  ],
}
