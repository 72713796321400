import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import Textarea from 'components/Textarea'
import UploadSingleImage from 'components/UploadSingleImage'
import { canManageProperties } from 'lib/can'
import { getPropertyRoute } from 'lib/routes'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'

const PropertyEditPage = ({ property, me }) => {
  const [updateProperty] = useMutation('updateProperty')
  // const [deleteProperty] = useMutation('deleteProperty')
  // const {
  //   formik: deletePropertyFormik,
  //   buttonProps: buttonDeletePropertyProps,
  //   alertProps: alertDeletePropertyProps,
  // } = useForm({
  //   onSubmit: () => {
  //     return deleteProperty({
  //       variables: {
  //         input: {
  //           propertyId: property.id,
  //         },
  //       },
  //     })
  //   },
  //   successMessage: 'Пользователь удалён',
  // })
  const {
    formik: updatePropertyFormik,
    buttonProps: buttonUpdatePropertyProps,
    alertProps: alertUpdatePropertyProps,
  } = useForm({
    initialValues: {
      name: property.name,
      desc: property.desc,
      contentMd: property.contentMd,
      propertyType: property.propertyType,
      area: '' + property.area,
      additionalExpenses: '' + property.additionalExpenses,
      purchasePrice: '' + property.purchasePrice,
      sellingPrice: '' + property.sellingPrice,
      minPeriod: '' + property.minPeriod,
      maxPeriod: '' + property.maxPeriod,
      startsAt: property.startsAt,
      fundraisingUntil: property.fundraisingUntil || '',
      endsAt: property.endsAt || '',
      photos: property.photos,
      documents: property.documents,
      status: property.status,
      photo: property.photo || '',
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      desc: yup.string(),
      content: yup.string(),
      propertyType: yup.string().required(),
      area: yup.number().required(),
      additionalExpenses: yup.number().required(),
      purchasePrice: yup.number().required(),
      sellingPrice: yup.number().required(),
      minPeriod: yup.number().required(),
      maxPeriod: yup.number().required(),
      startsAt: yup.string().required(),
      fundraisingUntil: yup.string(),
      endsAt: yup.string(),
      photos: yup.array(yup.string().required()),
      documents: yup.array(yup.string().required()),
      status: yup.string().required(),
    }),
    onSubmit: (values) => {
      return updateProperty({
        variables: {
          input: {
            propertyId: property.id,
            name: values.name,
            desc: values.desc,
            contentMd: values.contentMd,
            propertyType: values.propertyType,
            area: +values.area,
            additionalExpenses: +values.additionalExpenses,
            purchasePrice: +values.purchasePrice,
            sellingPrice: +values.sellingPrice,
            minPeriod: +values.minPeriod,
            maxPeriod: +values.maxPeriod,
            startsAt: values.startsAt,
            fundraisingUntil: values.fundraisingUntil || null,
            endsAt: values.endsAt || null,
            photos: values.photos,
            documents: values.documents,
            status: values.status,
            photo: values.photo,
          },
        },
      })
    },
    successMessage: 'Изменения успешно сохранены',
  })
  return (
    <Segment title={`Объект ${property.name}`}>
      {/* property.deletedAt && (
        <div className={css.deletedAlert}>
          <Alert message="Пользователь удалён" type="danger" />
        </div>
      ) */}
      <Form formik={updatePropertyFormik}>
        <FormItems>
          <UploadSingleImage label="Главное фото" name="photo" formik={updatePropertyFormik} />
          <Textarea
            name="contentMd"
            fullWidth
            minHeight={500}
            label="Подробное описание"
            formik={updatePropertyFormik}
          />
          {/*<Input name="name" label="Название" formik={updatePropertyFormik} />
          <Textarea name="desc" label="Краткое описание" formik={updatePropertyFormik} />
          <Input name="propertyType" label="Тип недвижимости" formik={updatePropertyFormik} />
          <Input name="area" label="Площадь" formik={updatePropertyFormik} />
          <Input name="additionalExpenses" label="Дополнительные траты" formik={updatePropertyFormik} />
          <Input name="purchasePrice" label="Цена покупки" formik={updatePropertyFormik} />
          <Input name="sellingPrice" label="Цена продажи" formik={updatePropertyFormik} />
          <Input name="minPeriod" label="Минимальный срок реализации в месяцах" formik={updatePropertyFormik} />
          <Input name="maxPeriod" label="Максимальный срок реализации в месяцах" formik={updatePropertyFormik} />
          <DatePicker withTime={false} name="startsAt" label="Дата старта объекта" formik={updatePropertyFormik} />
          <DatePicker
            withTime={false}
            name="fundraisingUntil"
            label="Дата окончания сбора средств"
            formik={updatePropertyFormik}
          />
          <DatePicker withTime={false} name="endsAt" label="Дата завершения объекта" formik={updatePropertyFormik} />
          <Input name="status" label="Статус" formik={updatePropertyFormik} />
     */}
          {/* <Alert {...alertDeletePropertyProps} /> */}
          <Alert {...alertUpdatePropertyProps} />
          <Buttons>
            <Button {...buttonUpdatePropertyProps}>Сохранить изменения</Button>
            <Button href={getPropertyRoute(property.serialNumber)}>Просмотр</Button>
            {/* <Button to={getPropertyRoute(property.serialNumber)}>Просмотр объекта</Button> */}
            {/* !property.deletedAt && (
              <ButtonWithConfirmation
                title="Точно удалить пользователя?"
                modalButtonText="Удалить пользователя"
                modalButtonColor="red"
                color="system-error-red"
                {...buttonDeletePropertyProps}
                onClick={() => deletePropertyFormik.handleSubmit()}
              >
                Удалить пользователя
              </ButtonWithConfirmation>
            ) */}
          </Buttons>
        </FormItems>
      </Form>
    </Segment>
  )
}

const GetPropertyWrapper = (props) => {
  const propertySerialNumber = props.match.params.propertySerialNumber
  const queryResult = useQuery('getProperty', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        propertySerialNumber,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ property }) => !!property}>
      {({ property }) => <PropertyEditPage property={property} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canManageProperties(me)}>
    {({ me }) => <GetPropertyWrapper me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
