import PageWrapper from 'components/PageWrapper'
import { useEffect } from 'react'

export const NotionPropertiesPage = ({ me, history }) => {
  useEffect(() => {
    window.location.replace('https://estateinvest.notion.site/c7ddf4c0507e4d78b48286ce9d965f5e')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <PageWrapper queryResult={{ loading: true }} />
}
