import { gql } from '@apollo/client'

export const companyResultFragment = gql`
  fragment companyResultFragment on CompanyResult {
    id
    name
    url
    serialNumber
    profitInvestors
    roiInvestorsProject
    roiInvestorsYear
    duration
    durationType
    purchaseSum
    expensesSum
    sellingPrice
    sellingCurrency
    contentMd
    contentHtml
    photo
    finishType
    date
    hidden
  }
`
