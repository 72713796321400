import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import DatePicker from 'components/DatePicker'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import RadioButton, { RadioButtons } from 'components/RadioButton'
import Segment from 'components/Segment'
import Textarea from 'components/Textarea'
import UploadSingleImage from 'components/UploadSingleImage'
import { canManageProperties } from 'lib/can'
import { getCompanyResultEditRoute } from 'lib/routes'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'
import { useHistory } from 'react-router-dom'

const CompanyResultCreatePage = ({ me }) => {
  const history = useHistory()
  const [companyResultCreate] = useMutation('companyResultCreate')
  const {
    formik: createCompanyResultFormik,
    buttonProps: buttonCreateCompanyResultProps,
    alertProps: alertCreateCompanyResultProps,
  } = useForm({
    initialValues: {
      name: '',
      url: '',
      serialNumber: '',
      profitInvestors: '',
      roiInvestorsProject: '',
      roiInvestorsYear: '',
      duration: '',
      durationType: 'months',
      purchaseSum: '',
      expensesSum: '',
      sellingPrice: '',
      sellingCurrency: 'rub',
      contentMd: '',
      photo: '',
      finishType: 'finished',
      date: '',
      hidden: '0',
    },
    validationSchema: yup.object({
      name: yup.string(),
      url: yup.string(),
      serialNumber: yup.number(),
      profitInvestors: yup.number(),
      roiInvestorsProject: yup.number(),
      roiInvestorsYear: yup.number(),
      duration: yup.number(),
      durationType: yup.string(),
      purchaseSum: yup.number(),
      expensesSum: yup.number(),
      sellingPrice: yup.number(),
      sellingCurrency: yup.string(),
      contentMd: yup.string(),
      photo: yup.string(),
      finishType: yup.string(),
      date: yup.string().required(),
      hidden: yup.string(),
    }),
    onSubmit: (values) => {
      return companyResultCreate({
        variables: {
          input: {
            ...values,
            serialNumber: +values.serialNumber || 0,
            profitInvestors: +values.profitInvestors || 0,
            roiInvestorsProject: +values.roiInvestorsProject || 0,
            roiInvestorsYear: +values.roiInvestorsYear || 0,
            duration: +values.duration || 0,
            purchaseSum: +values.purchaseSum || 0,
            expensesSum: +values.expensesSum || 0,
            sellingPrice: +values.sellingPrice || 0,
            hidden: !!Number(values.hidden),

            // date: moment(values.date, 'D.M.YYYY').toDate(),
          },
        },
      }).then(({ companyResult }) => {
        history.push(getCompanyResultEditRoute(companyResult.serialNumber))
      })
    },
    successMessage: false,
  })
  return (
    <Segment title="Создание пользователя">
      <Form formik={createCompanyResultFormik}>
        <FormItems>
          <Input name="name" label="Название" formik={createCompanyResultFormik} />
          <Input name="url" label="Ссылка" formik={createCompanyResultFormik} />
          <Input name="serialNumber" label="Серийный номер" formik={createCompanyResultFormik} />
          <Input name="profitInvestors" label="Прибыль инвесторов" formik={createCompanyResultFormik} />
          <Input name="roiInvestorsProject" label="Доходность инвесторов проект" formik={createCompanyResultFormik} />
          <Input name="roiInvestorsYear" label="Доходность инвесторов год" formik={createCompanyResultFormik} />
          <Input name="duration" label="Длительность" formik={createCompanyResultFormik} />
          <RadioButtons label="Тип длительности" formik={createCompanyResultFormik} name="durationType">
            <RadioButton formik={createCompanyResultFormik} label="Месяцы" name="durationType" value={'months'} />
            <RadioButton formik={createCompanyResultFormik} label="Дни" name="durationType" value={'days'} />
          </RadioButtons>
          <Input name="purchaseSum" label="Сумма покупки" formik={createCompanyResultFormik} />
          <Input name="expensesSum" label="Сумма расходов" formik={createCompanyResultFormik} />
          <Input name="sellingPrice" label="Цена продажи" formik={createCompanyResultFormik} />
          <RadioButtons label="Валюта" formik={createCompanyResultFormik} name="sellingCurrency">
            <RadioButton formik={createCompanyResultFormik} label="Рубль" name="sellingCurrency" value={'rub'} />
            <RadioButton formik={createCompanyResultFormik} label="Евро" name="sellingCurrency" value={'euro'} />
            <RadioButton formik={createCompanyResultFormik} label="Доллар" name="sellingCurrency" value={'usd'} />
            <RadioButton formik={createCompanyResultFormik} label="USDT" name="sellingCurrency" value={'usdt'} />
          </RadioButtons>
          <Textarea name="contentMd" label="Контент" formik={createCompanyResultFormik} />
          <UploadSingleImage label="Главное фото" name="photo" formik={createCompanyResultFormik} />
          <RadioButtons label="Тип готовности" formik={createCompanyResultFormik} name="finishType">
            <RadioButton formik={createCompanyResultFormik} label={'Завершен'} name="finishType" value={'finished'} />
            <RadioButton
              formik={createCompanyResultFormik}
              label={'Частично завершен'}
              name="finishType"
              value={'partial'}
            />
          </RadioButtons>
          <DatePicker withTime={false} name="date" label="Дата" formik={createCompanyResultFormik} />
          <RadioButtons label="Скрыт" formik={createCompanyResultFormik} name="hidden">
            <RadioButton formik={createCompanyResultFormik} label={'Нет'} name="hidden" value={'0'} />
            <RadioButton formik={createCompanyResultFormik} label={'Да'} name="hidden" value={'1'} />
          </RadioButtons>
          <Alert {...alertCreateCompanyResultProps} />
          <Buttons>
            <Button {...buttonCreateCompanyResultProps}>Создать результат</Button>
          </Buttons>
        </FormItems>
      </Form>
    </Segment>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canManageProperties(me)}>
    {({ me }) => <CompanyResultCreatePage me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
