import Alert from 'components/Alert'
import Button from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canSyncSheets } from 'lib/can'
import useMutation from 'lib/useMutation'

const SyncSheetsNewPage = ({ user, me }) => {
  const form1 = useForm({
    onSubmit: async () => {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_SECONDARY_URI}/sync-sheets/start`)
      const data = await res.json()
      return data
    },
    successMessage: (result) => {
      return result.message
    },
  })

  const form2 = useForm({
    onSubmit: async () => {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_SECONDARY_URI}/sync-sheets/what`)
      const data = await res.json()
      return data
    },
    successMessage: (result) => {
      return result.message
    },
  })
  return (
    <Segment title={`Скоростная синхронизация с гугл таблицами`}>
      <Form formik={form1.formik}>
        <FormItems>
          <Alert {...form1.alertProps} />
          <Alert {...form2.alertProps} />
          <Button {...form1.buttonProps}>Синхронизировать</Button>
          <Button {...form2.buttonProps} type="button" onClick={() => form2.formik.handleSubmit()}>
            Как там?
          </Button>
        </FormItems>
      </Form>
    </Segment>
  )
}

const SyncSheetsPage = ({ user, me }) => {
  const [syncSheets] = useMutation('syncSheets')
  const form1 = useForm({
    onSubmit: () => {
      return syncSheets()
    },
    successMessage: 'Данные начали синхронизироваться',
  })

  const [whatSheets] = useMutation('whatSheets')
  const form2 = useForm({
    onSubmit: () => {
      return whatSheets()
    },
    successMessage: (result) => {
      return result.data.whatSheets
    },
  })
  return (
    <Segment title={`Синхронизация с гугл таблицами`}>
      <Form formik={form1.formik}>
        <FormItems>
          <Alert {...form1.alertProps} />
          <Alert {...form2.alertProps} />
          <Button {...form1.buttonProps}>Синхронизировать</Button>
          <Button {...form2.buttonProps} type="button" onClick={() => form2.formik.handleSubmit()}>
            Как там?
          </Button>
        </FormItems>
      </Form>
    </Segment>
  )
}

const CheckAuthWrapper = (props) => {
  const searchParams = new URLSearchParams(window.location.search)
  const isNew = searchParams.get('zxc') !== '1'
  return (
    <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canSyncSheets(me)}>
      {() => (isNew ? <SyncSheetsNewPage {...props} /> : <SyncSheetsPage {...props} />)}
    </PageWrapper>
  )
}

export default CheckAuthWrapper
