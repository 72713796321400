import { ReactComponent as LogoBig } from 'assets/landing/logo-big.svg'
import { ReactComponent as LogoGray } from 'assets/landing/logo-gray.svg'
import { ReactComponent as LogoSmall } from 'assets/landing/logo-small.svg'
import { ReactComponent as Risk1 } from 'assets/landing/risk-1-icon.svg'
import { ReactComponent as Risk2 } from 'assets/landing/risk-2-icon.svg'
import { ReactComponent as Risk3 } from 'assets/landing/risk-3-icon.svg'
import { ReactComponent as Risk4 } from 'assets/landing/risk-4-icon.svg'
import cn from 'classnames'
import Alert from 'components/Alert'
import Button1 from 'components/Button1'
import Form, { FormItems, useForm } from 'components/Form'
import Icon from 'components/Icon'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import getUploadUrl from 'lib/getUploadUrl'
import parseLocationSearch from 'lib/parseLocationSearch'
import {
  getCompanyResultsRoute,
  getDashboardRoute,
  getDocsContractRoute,
  getDocsPolicyRoute,
  getDocsTermsRoute,
  getSignInRoute,
} from 'lib/routes'
import toHumanDate from 'lib/toHumanDate'
import toMoney from 'lib/toMoney'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import css from './styles.module.scss'
import _ from 'lodash'
import ScrollToHash, { scrollToHash } from 'components/ScrollToHash'
import { useApolloClient } from '@apollo/client'
import Cookies from 'js-cookie'
import moment from 'moment'
import { plural } from 'lib/plural'
import Button from 'components/Button'

const CompanyResult = ({ cr }) => {
  const tilda = cr.finishType === 'finished' ? '' : '~ '
  const [expanded, setExpanded] = useState(false)
  const outerRef = useRef(null)
  const innerRef = useRef(null)
  useEffect(() => {
    if (!outerRef.current || !innerRef.current || expanded) return
    const outerHeight = outerRef.current.clientHeight
    const innerHeight = innerRef.current.clientHeight
    if (innerHeight <= outerHeight) {
      setExpanded(true)
    }
  }, [expanded])
  return (
    <div className={css.cr}>
      {cr.photo && <img className={css.photo} src={getUploadUrl(cr.photo, 'origin')} alt="" />}
      <h2 className={css.title}>{cr.url ? <a href={cr.url}>{cr.name}</a> : cr.name}</h2>
      <p className={css.sn}>Инвестиционное предложение №{cr.serialNumber}</p>
      <div className={css.statsBlocks}>
        <div className={cn(css.statsBlock, css.statsBlockGeneral)}>
          <div className={css.statsBlockLines}>
            <div className={css.statsBlockLine}>
              <div className={css.statsBlockLabel}>Срок реализации</div>
              <div className={css.statsBlockValue}>
                {tilda}
                {cr.durationType === 'days'
                  ? plural(cr.duration, ['день', 'дня', 'дней'], true)
                  : plural(cr.duration, ['месяц', 'месяца', 'месяцев'], true)}
              </div>
            </div>
            <div className={css.statsBlockLine}>
              <div className={css.statsBlockLabel}>Покупка объекта</div>
              <div className={css.statsBlockValue}>
                {tilda}
                {toMoney(cr.purchaseSum, cr.sellingCurrency)}
              </div>
            </div>
            <div className={css.statsBlockLine}>
              <div className={css.statsBlockLabel}>Расходы</div>
              <div className={css.statsBlockValue}>
                {tilda}
                {toMoney(cr.expensesSum, cr.sellingCurrency)}
              </div>
            </div>
            <div className={css.statsBlockLine}>
              <div className={css.statsBlockLabel}>Продажа объекта</div>
              <div className={css.statsBlockValue}>
                {tilda}
                {toMoney(cr.sellingPrice, cr.sellingCurrency)}
              </div>
            </div>
          </div>
        </div>
        <div className={cn(css.statsBlock, css.statsBlockInvestors)}>
          <div className={css.statsBlockTitle}>Доходность инвестору</div>
          <div className={css.statsBlockLines}>
            <div className={css.statsBlockLine}>
              <div className={css.statsBlockLabel}>Прибыль инвесторов</div>
              <div className={css.statsBlockValue}>
                {tilda}
                {toMoney(cr.profitInvestors, cr.sellingCurrency)}
              </div>
            </div>
            <div className={css.statsBlockLine}>
              <div className={css.statsBlockLabel}>За проект</div>
              <div className={css.statsBlockValue}>
                {tilda}
                {cr.roiInvestorsProject}%
              </div>
            </div>
            <div className={css.statsBlockLine}>
              <div className={css.statsBlockLabel}>Годовых</div>
              <div className={css.statsBlockValue}>
                {tilda}
                {cr.roiInvestorsYear}%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn({ [css.content]: true, [css.expanded]: expanded })}>
        <div className={css.contentTextWrapper} ref={outerRef}>
          <div className={css.contentText} dangerouslySetInnerHTML={{ __html: cr.contentHtml }} ref={innerRef} />
        </div>
        {!expanded && (
          <div className={css.contentMore}>
            <Button color="blue" onClick={() => setExpanded(true)}>
              Показать полностью
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const CompanyResultsPage = ({ companyResults }) => {
  const { me, loading } = useQuery('getMe')
  const [finishType, setFinishType] = useState('finished')
  const crs = companyResults.filter((cr) => cr.finishType === finishType)
  return (
    <div className={css.page}>
      <div id="header" className={cn(css.section, css.header)}>
        <div className={css.headerInner}>
          <div className={css.wrapper}>
            <div className={css.logoPlace}>
              <Link to={'/'} className={css.logoBigLink}>
                <LogoBig />
              </Link>
              <Link to={'/'} className={css.logoSmallLink}>
                <LogoSmall />
              </Link>
            </div>
            <div className={css.authMenu}>
              {loading ? null : !me ? (
                <>
                  <div className={css.authMenuItem}>
                    <Button1
                      icon="signin"
                      iconClassName={css.authMenuItemIcon}
                      size="small"
                      color="blue-without-background"
                      to={getSignInRoute()}
                    >
                      Вход
                    </Button1>
                  </div>
                  <div className={css.authMenuItem}>
                    <Button1 size="small" color="blue" onClick={() => scrollToHash('#form')}>
                      Регистрация
                    </Button1>
                  </div>
                </>
              ) : (
                <div className={css.authMenuItem}>
                  <Button1
                    icon="dashboard"
                    iconClassName={css.authMenuItemIcon}
                    size="small"
                    color="blue-without-background"
                    to={getDashboardRoute()}
                  >
                    Личный кабинет
                  </Button1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={cn(css.section, css.intro)}>
        <div className={css.wrapper}>
          <h1 className={css.sectionTitle}>Наши результаты</h1>
          <p className={css.sectionDesc}>
            Ниже представлен список наших реализованных объектов, с краткими комментариями по каждому
          </p>
          <div className={css.finishTypes}>
            <button
              className={cn({ [css.active]: finishType === 'finished', [css.finishType]: true })}
              onClick={() => setFinishType('finished')}
            >
              Завершенные
            </button>
            <button
              className={cn({ [css.active]: finishType === 'partial', [css.finishType]: true })}
              onClick={() => setFinishType('partial')}
            >
              Частично завершенные
            </button>
          </div>
          <div className={css.crs}>
            {crs.map((cr, i) => (
              <CompanyResult cr={cr} key={cr.id} />
            ))}
          </div>
        </div>
      </div>
      <div className={cn(css.section, css.footer)}>
        <div className={css.wrapper}>
          <Link to={'#header'} className={css.logo}>
            <LogoGray />
          </Link>
          <div className={css.navMenu}>
            {/* <Link to={getDocsPolicyRoute()} className={css.navMenuLink}>
              Политика конфиденциальности
            </Link> */}
            <Link to={getCompanyResultsRoute()} className={css.navMenuLink}>
              Наши результаты
            </Link>
            <Link to={getDocsTermsRoute()} className={css.navMenuLink}>
              Условия обработки персональных данных
            </Link>
            {/* <Link to={getDocsContractRoute()} className={css.navMenuLink}>
              Инвестиционный договор
            </Link> */}
          </div>
          <div className={css.copyright}>
            © {new Date().getFullYear()} ИП Мудров Иван Юрьевич ИНН 590610248187 ОГРН 304590632000277
          </div>
        </div>
      </div>
      <ScrollToHash elOffset={30} pageOffset={60} />
    </div>
  )
}

const GetCompaniesResultsWrapper = (props) => {
  const queryResult = useQuery('companyResultList', {
    fetchPolicy: 'network-only',
  })

  return (
    <PageWrapper queryResult={queryResult}>
      {() => <CompanyResultsPage companyResults={queryResult.companyResults} {...props} />}
    </PageWrapper>
  )
}

export default GetCompaniesResultsWrapper
