import React, { forwardRef } from 'react'
import cn from 'classnames'
import Icon from 'components/Icon'
import { Link } from 'react-router-dom'
import css from './styles.module.scss'

const Button = forwardRef(
  (
    {
      to,
      color = 'orange',
      size = 'normal',
      href,
      square,
      icon,
      loading = false,
      disabled,
      children,
      fullWidth,
      asSpan,
      className,
      ...restProps
    },
    ref
  ) => {
    const classNameHere = cn({
      [css.button]: true,
      [css.loading]: loading,
      [css.disabled]: disabled,
      [css[`color-${color}`]]: !!color,
      [css[`size-${size}`]]: !!size,
      [css.square]: !!square,
      [css.fullWidth]: !!fullWidth,
      [className]: !!className,
    })
    const childrenHere = (
      <span className={css.content}>
        {icon && <Icon className={css.icon} name={icon} />}
        {children}
      </span>
    )
    return to ? (
      <Link ref={ref} to={to} className={classNameHere} children={childrenHere} {...restProps} />
    ) : href ? (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a ref={ref} href={href} className={classNameHere} children={childrenHere} {...restProps} />
    ) : asSpan ? (
      <span ref={ref} className={classNameHere} children={childrenHere} {...restProps} />
    ) : (
      <button
        type="button"
        ref={ref}
        disabled={disabled}
        className={classNameHere}
        children={childrenHere}
        {...restProps}
      />
    )
  }
)

export const Buttons = ({ children, withLinks, ...restProps }) => (
  <div className={cn({ [css.buttonsPlace]: true, [css.withLinks]: !!withLinks })} {...restProps}>
    <div className={css.buttons}>{children}</div>
  </div>
)

export const ButtonWrapper = ({ children, className, ...restProps }) => (
  <div className={cn(css.button, className)} {...restProps}>
    {children}
  </div>
)

export default Button
