import Alert from 'components/Alert'
import Button from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canSyncTbankrot } from 'lib/can'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'

const SyncTbankrotPage = ({ user, me }) => {
  const [syncTbankrot] = useMutation('syncTbankrot')
  const { formik, buttonProps, alertProps } = useForm({
    onSubmit: (values) => {
      return syncTbankrot({ variables: { input: { onlyRowNumber: +values.onlyRowNumber } } })
    },
    validationSchema: yup.object({
      onlyRowNumber: yup.number().required(),
    }),
    successMessage: 'Данные успешно синхронизированы',
  })
  return (
    <Segment title={`Синхронизация с тбанкрот`}>
      <Form formik={formik}>
        <FormItems>
          <Alert message="Синхронизация происходит автоматически два раза в день. На этой странице вы можете вручную запустить синхронизацию для конкретной строки в таблицуе. Укажите «0», если хотите синхронизировать все строки (это долго)." />
          <Input name="onlyRowNumber" label="Номер строки" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Синхронизировать</Button>
        </FormItems>
      </Form>
    </Segment>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canSyncTbankrot(me)}>
    {() => <SyncTbankrotPage {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
