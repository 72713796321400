export const can = (user, perm) => {
  return !!(user && user.permissions && (user.permissions.includes(perm) || user.permissions.includes('all')))
}
export const canUseFutureFeatures = (user) => can(user, 'useFutureFeatures')
export const canGenDoc = (user) => can(user, 'genDoc')
export const canViewUsers = (user) => can(user, 'viewUsers')
export const canEditUsers = (user) => can(user, 'editUsers')
export const canCreateUsers = (user) => can(user, 'createUsers')
export const canDeleteUsers = (user) => can(user, 'deleteUsers')
export const canEditUserPermissions = (user) => can(user, 'editUserPermissions')
export const canManageUserPassports = (user) => can(user, 'manageUserPassports')
export const canManageUserBankAccounts = (user) => can(user, 'manageUserBankAccounts')
export const canManageProperties = (user) => can(user, 'manageProperties')
export const canManageNewsPosts = (user) => can(user, 'manageNewsPosts')
export const canSyncSheets = (user) => can(user, 'syncSheets')
export const canSyncTbankrot = (user) => can(user, 'syncTbankrot')
export const canViewLastSignUpsAndTxs = (user) => can(user, 'viewLastSignUpsAndTxs')
