import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import _ from 'lodash'

const Textarea = ({ label, formik, name, hint, onChange, fullWidth, minHeight = 150, disabled, ...restProps }) => {
  const error = _.get(formik.errors, name)
  const value = _.get(formik.values, name) || ''
  const touched = _.get(formik.touched, name)
  return (
    <div
      className={cn({
        [css.field]: true,
        [css.disabled]: disabled,
      })}
    >
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      <textarea
        id={name}
        onChange={(...agrs) => {
          if (onChange) onChange(...agrs)
          formik.handleChange(...agrs)
        }}
        onBlur={formik.handleBlur}
        name={name}
        value={value}
        disabled={disabled}
        className={cn({
          [css.textarea]: true,
          [css.fullWidth]: !!fullWidth,
          [css.invalid]: !!error && touched,
        })}
        style={{ minHeight }}
        {...restProps}
      />
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default Textarea
