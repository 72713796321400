import ChangePassword from 'components/ChangePassword'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'

const EditProfilePage = () => {
  return (
    <PageWrapper redirerctUnauthorized>
      {({ me }) => {
        return (
          <Segment title="Смена пароля">
            {/* <Segment size={2} title="Основные настройки">
              <ProfileEditor />
        </Segment> */}
            {/* canUseFutureFeatures(me) && (
              <Segment size={2} title="Паспортные данные">
                <PassportForUser />
              </Segment>
            ) */}
            {/* canUseFutureFeatures(me) && (
              <Segment size={2} title="Реквизиты">
                <BankAccountForUser />
              </Segment>
            ) */}
            <Segment size={2}>
              <ChangePassword />
            </Segment>
            {/*<Segment size={2} title="Управление номером телефона">
              <p>Ваш номер телефона: {me.phone} </p>
              <div style={{ marginTop: 30 }}>
                <ChangePhone />
              </div>
            </Segment>
            <Segment size={2} title="Управление почтой">
              <p>
                Ваша текущая почта: {me.email}{' '}
                {!me.emailConfirmed &&
                  '(Подтвердите вашу почту, чтобы иметь возможность получать на неё уведомления. Для этого перейдите по ссылке отправленной вам в письме при регистрации)'}
              </p>
              {!me.emailConfirmed && (
                <div style={{ marginTop: 10 }}>
                  <ResendEmailConfirmation />
                </div>
              )}
              <div style={{ marginTop: 30 }}>
                <ChangeEmail />
              </div>
              </Segment> */}
          </Segment>
        )
      }}
    </PageWrapper>
  )
}

export default EditProfilePage
