import { gql } from '@apollo/client'

export const getNewsPostsQuery = gql`
  query getNewsPosts {
    getNewsPosts {
      newsPosts {
        id
        serialNumber
        date
        contentHtml
        photos
        deleted
        isMy
        property {
          id
          serialNumber
          name
        }
      }
    }
  }
`

export const getNewsPostsOptions = {
  parser: [['newsPosts', 'getNewsPosts.newsPosts']],
}
