import { gql } from '@apollo/client'
import { userFragment } from './userFragment.js'

export const getMeQuery = gql`
  ${userFragment}
  query getMe {
    getMe {
      me {
        id
        welcomePageVisited
        email
        phone
        emailConfirmed
        permissions
        name
        referralCode
        referralsCount
        deletedAt
        serialNumber
        dummyReferrals
        dummyReferrer
        ...userFragment
      }
    }
  }
`

export const getMeOptions = { parser: [['me', 'getMe.me']] }
