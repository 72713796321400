import { useEffect } from 'react'
import useQuery from 'lib/useQuery'
import mixpanel from 'mixpanel-browser'

const UserMixpanel = () => {
  const { me } = useQuery('getMe')
  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_ON === 'true' && me) {
      mixpanel.identify(me.id)
      mixpanel.people.set({
        $email: me.email,
        phone: me.phone,
        emailConfirmed: me.emailConfirmed,
        $name: me.displayName,
        referralCode: me.referralCode,
        referralsCount: me.referralsCount,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(me)])
  return null
}

export default UserMixpanel
