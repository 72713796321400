import { useLocation } from 'react-router-dom'
import parseLocationSearch from 'lib/parseLocationSearch'
import Cookies from 'js-cookie'
import { useEffect } from 'react'

const ReferralLink = () => {
  const location = useLocation()
  useEffect(() => {
    const parsedLocationSearch = parseLocationSearch(location.search)
    if (parsedLocationSearch.ref) {
      Cookies.set('referralCode', parsedLocationSearch.ref, { expires: 99999 })
    }
  }, [location.search])
  return null
}

export default ReferralLink
