import { gql } from '@apollo/client'
import { passportFragment } from './passportFragment'

export const rejectPassportMutation = gql`
  ${passportFragment}
  mutation ($input: RejectPassportInput!) {
    rejectPassport(input: $input) {
      passport {
        ...passportFragment
        user {
          id
          displayName
        }
      }
    }
  }
`

export const rejectPassportOptions = { parser: [['passport', 'rejectPassport.passport']] }
