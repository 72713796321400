import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import _ from 'lodash'

const Checkbox = ({ label, formik, name, onChange, value, bold, type = 'boolean', ...restProps }) => {
  const formikValue = _.get(formik.values, name)
  return (
    <label
      className={cn({
        [css.checkbox]: true,
        [css.bold]: !!bold,
      })}
    >
      <input
        type="checkbox"
        name={name}
        value={type === 'boolean' ? undefined : value}
        checked={type === 'boolean' ? formikValue : formikValue?.includes(value)}
        onChange={() => {
          if (type === 'boolean') {
            if (onChange) onChange(!formikValue)
            formik.setFieldValue(name, !formikValue)
          } else {
            const newValue = formikValue.includes(value)
              ? formikValue.filter((v) => v !== value)
              : _.uniq([...formikValue, value])
            if (onChange) onChange(newValue)
            formik.setFieldValue(name, newValue)
          }
        }}
        onBlur={formik.handleBlur}
        {...restProps}
      />{' '}
      {label}
    </label>
  )
}

export const Checkboxes = ({ label, formik, name, hint, children, direction = 'column', ...restProps }) => {
  const error = _.get(formik.errors, name)
  const touched = _.get(formik.touched, name)
  return (
    <div
      className={cn({
        [css.checkboxes]: true,
        [css[`direction-${direction}`]]: !!direction,
      })}
      {...restProps}
    >
      {!!label && <label className={css.label}>{label}</label>}
      <div className={css.items}>{children}</div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default Checkbox
