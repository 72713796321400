import { gql } from '@apollo/client'
import { propertyFragment } from './propertyFragment'

export const createPropertyMutation = gql`
  ${propertyFragment}
  mutation ($input: CreatePropertyInput!) {
    createProperty(input: $input) {
      property {
        ...propertyFragment
      }
    }
  }
`

export const createPropertyOptions = { parser: [['property', 'createProperty.property']] }
