import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Checkbox, { Checkboxes } from 'components/Checkbox'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canCreateUsers, canEditUserPermissions } from 'lib/can'
import { getUserRoute } from 'lib/routes'
import toHumanPermission, { permissions } from 'lib/toHumanPermission'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'
import _ from 'lodash'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export const usePermissonNormalizer = (formik) => {
  const prevPermissions = useRef(formik.values.permissions)
  useEffect(() => {
    const op = prevPermissions.current
    const np = formik.values.permissions
    let vp = [...formik.values.permissions]
    if (!op.includes('editUsers') && np.includes('editUsers')) {
      vp = _.uniq([...vp, 'viewUsers'])
    }
    if (!op.includes('deleteUsers') && np.includes('deleteUsers')) {
      vp = _.uniq([...vp, 'viewUsers', 'editUsers'])
    }
    if (!op.includes('editUserPermissions') && np.includes('editUserPermissions')) {
      vp = _.uniq([...vp, 'viewUsers', 'editUsers'])
    }
    if (op.includes('editUsers') && !np.includes('editUsers')) {
      vp = _.uniq([...vp.filter((p) => p !== 'editUserPermissions')])
    }
    if (op.includes('viewUsers') && !np.includes('viewUsers')) {
      vp = _.uniq([
        ...vp.filter(
          (p) =>
            p !== 'editUsers' &&
            p !== 'deleteUsers' &&
            p !== 'editUserPermissions' &&
            p !== 'manageUserPassports' &&
            p !== 'manageUserBankAccounts'
        ),
      ])
    }
    if (!op.includes('manageUserPassports') && np.includes('manageUserPassports')) {
      vp = _.uniq([...vp, 'viewUsers'])
    }
    if (!op.includes('manageUserBankAccounts') && np.includes('manageUserBankAccounts')) {
      vp = _.uniq([...vp, 'viewUsers'])
    }
    prevPermissions.current = vp
    formik.setFieldValue('permissions', vp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formik.values.permissions)])
}

const UserCreatePage = ({ me }) => {
  const history = useHistory()
  const [createUser] = useMutation('createUser')
  const {
    formik: createUserFormik,
    buttonProps: buttonCreateUserProps,
    alertProps: alertCreateUserProps,
  } = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      password: '',
      permissions: [],
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      phone: yup.string().required(),
      password: yup.string().required(),
    }),
    onSubmit: (values) => {
      return createUser({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(({ user }) => {
        history.push(getUserRoute(user.serialNumber))
      })
    },
    successMessage: false,
  })
  usePermissonNormalizer(createUserFormik)
  return (
    <Segment title="Создание пользователя">
      <Form formik={createUserFormik}>
        <FormItems>
          <Input name="name" label="ФИО" formik={createUserFormik} />
          <Input name="email" label="E-mail" inputMode="email" formik={createUserFormik} />
          <Input name="phone" mask="+{7} 000 000-00-00" label="Телефон" inputMode="tel" formik={createUserFormik} />
          <Input name="password" label="Пароль" formik={createUserFormik} />
          {canEditUserPermissions(me) && (
            <Checkboxes name="permissions" label="Права" formik={createUserFormik}>
              {Object.keys(permissions)
                .filter((permission) => permission !== 'all')
                .map((permission, i) => (
                  <Checkbox
                    formik={createUserFormik}
                    label={toHumanPermission(permission)}
                    type="array"
                    name="permissions"
                    key={i}
                    value={permission}
                  />
                ))}
            </Checkboxes>
          )}
          <Alert {...alertCreateUserProps} />
          <Buttons>
            <Button {...buttonCreateUserProps}>Создать пользователя</Button>
          </Buttons>
        </FormItems>
      </Form>
    </Segment>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canCreateUsers(me)}>
    {({ me }) => <UserCreatePage me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
