const toMoney = (sum, currency, needSymbol = true) => {
  if (!sum && sum !== 0) return ''
  const normalizedSum = Math.round(sum * 100) / 100
  const formattedSum = normalizedSum.toLocaleString('de-DE').replaceAll('.', ' ')
  const formattedSumParts = formattedSum.split(',')
  const formattedSumWithNormalDigits = !formattedSumParts[1]
    ? formattedSum + ',00'
    : formattedSumParts[1].length === 1
    ? formattedSum + '0'
    : formattedSum
  const formattedSumWithNormalDigitsWithFixedMinus =
    formattedSumWithNormalDigits === '-0,00' ? '0,00' : formattedSumWithNormalDigits

  return needSymbol
    ? `${formattedSumWithNormalDigitsWithFixedMinus} ${
        {
          rub: '₽',
          usd: '$',
          euro: '€',
          usdt: 'USDT',
        }[currency]
      }`
    : formattedSumWithNormalDigitsWithFixedMinus
}

export default toMoney
