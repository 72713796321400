import { gql } from '@apollo/client'

export const setWelcomePageVisitedTrueMutation = gql`
  mutation {
    setWelcomePageVisitedTrue {
      me {
        id
        welcomePageVisited
      }
    }
  }
`

export const setWelcomePageVisitedTrueOptions = { parser: [['me', 'setWelcomePageVisitedTrueMe.me']] }
