import { gql } from '@apollo/client'

export const deleteUserMutation = gql`
  mutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
        deletedAt
      }
    }
  }
`

export const deleteUserOptions = { parser: [['user', 'deleteUser.user']] }
