import { useEffect, useState } from 'react'
import { ReactComponent as AlfaLogo } from 'assets/banks/alfa1.svg'
import { useLocation } from 'react-router-dom'

const requestAuthUrl = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URI}/alfa/auth-url`
  const res = await fetch(url)
  const data = await res.json()
  return data.url
}

export const AlfaAuthButton = ({ pText, buttonText }) => {
  const searchParams = new URLSearchParams(window.location.search)
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState('')
  const enabled = searchParams.get('alfa') === '1' || pathname === '/new'

  useEffect(() => {
    ;(async () => {
      if (!enabled) {
        return
      }
      try {
        const url = await requestAuthUrl()
        setUrl(url)
        setLoading(false)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [])

  if (!enabled || loading) {
    return null
  }

  return (
    <div>
      <a
        href={url}
        style={{
          minHeight: 56,
          padding: '17px 20px',
          borderRadius: 12,
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
          gap: 8,
          fontSize: 16,
          backgroundColor: '#ef3124',
          // fontFamily: 'SF Pro Text',
          color: '#fff',
        }}
      >
        <AlfaLogo />
        Войти с Альфа ID
      </a>
      <div style={{ marginTop: 5, color: '#8994a9' }}>
        {pText} используя кнопку выше, или воспользуйтесь формой ниже
      </div>
    </div>
  )
}
