import cn from 'classnames'
import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Loader from 'components/Loader'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canManageProperties } from 'lib/can'
import { getPropertyEditRoute, getPropertyRoute } from 'lib/routes'
import toMoney from 'lib/toMoney'
import useDropdown from 'lib/useDropdown'
import useQuery from 'lib/useQuery'
import { useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import css from './styles.module.scss'

const PropertiesListItem = ({ property, me }) => {
  const history = useHistory()
  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const menuRef = useRef()
  const dropdownMenuRef = useRef()
  useDropdown(menuRef, dropdownMenuRef, isDropdownMenuOpen, setDropdownMenuOpen)
  /* const [deleteProperty] = useMutation('deleteProperty')
  const {
    formik: deletePropertyFormik,
    buttonProps: buttonDeletePropertyProps,
    alertProps: alertDeletePropertyProps,
  } = useForm({
    onSubmit: () => {
      return deleteProperty({
        variables: {
          input: {
            propertyId: property.id,
          },
        },
        refetchQueries: ['getProperties'],
      })
    },
    successMessage: 'Пользователь удалён',
  }) */
  return (
    <>
      <div
        className={cn({
          [css.row]: true,
          [css.clickable]: true,
          [css.colorGreen]: property.status === 'finished',
        })}
        onClick={() => history.push(getPropertyRoute(property.serialNumber))}
      >
        <div className={css.cell}>{property.name}</div>
        <div className={css.cell}>{!!property.photo ? 'Да' : 'Нет'}</div>
        <div className={css.cell}>
          {property.status === 'finished' ? (
            <>{property.realProfitPercent}%</>
          ) : (
            <>
              {property.minProfitPercent} – {property.maxProfitPercent}%
            </>
          )}
        </div>
        <div className={css.cell}>{toMoney(property.purchasePrice, property.purchaseCurrency)}</div>
        <div className={css.cell}>
          {property.status === 'finished' ? (
            <>
              {Math.ceil(property.realPeriodMonths)}&nbsp;мес. ({property.realPeriodDays} дн.)
            </>
          ) : (
            <>
              {property.minPeriod} – {property.maxPeriod}&nbsp;мес.
            </>
          )}
        </div>
        <div className={css.cell}>{toMoney(property.sellingPrice, property.sellingCurrency)}</div>
        {canManageProperties(me) && (
          <div className={css.cell}>
            <Buttons>
              <Link
                color="system-links-blue"
                to={getPropertyEditRoute(property.serialNumber)}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                Редактировать
              </Link>
              {/* canDeleteProperties(me) && (
              <Link
                color="system-error-red"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setConfirmationOpen(true)
                }}
              >
                Удалить
              </Link>
              ) */}
            </Buttons>
          </div>
        )}
      </div>
      <div
        className={cn({ [css.card]: true, [css.clickable]: true, [css.colorGreen]: property.status === 'finished' })}
        onClick={() => history.push(getPropertyRoute(property.serialNumber))}
      >
        {/*<div className={css.cardHeader}>*/}
        {/*  <div className={css.number}>{property.serialNumber}</div>*/}
        {/*</div>*/}
        {property.name && <div className={css.title}>{property.name}</div>}
        <div className={css.info}>
          <div className={css.infoItem}>
            <div className={css.icon}>
              <Icon name="dot" />
            </div>
            <div className={css.text}>
              Доходность: {property.roi}
              {/* {property.status === 'finished' ? (
                <>{property.realProfitPercent}%</>
              ) : (
                <>
                  {property.minProfitPercent} – {property.maxProfitPercent}%
                </>
              )} */}
            </div>
          </div>
          <div className={css.infoItem}>
            <div className={css.icon}>
              <Icon name="dot" />
            </div>
            <div className={css.text}>Цена покупки: {toMoney(property.purchasePrice, property.purchaseCurrency)}</div>
          </div>
          <div className={css.infoItem}>
            <div className={css.icon}>
              <Icon name="dot" />
            </div>
            <div className={css.text}>
              Срок реализации:{' '}
              {property.status === 'finished' ? (
                <>{Math.ceil(property.realPeriodMonths)}&nbsp;мес.</>
              ) : (
                <>
                  {property.minPeriod} – {property.maxPeriod}&nbsp;мес.
                </>
              )}
            </div>
          </div>
          <div className={css.infoItem}>
            <div className={css.icon}>
              <Icon name="dot" />
            </div>
            <div className={css.text}>Цена продажи: {toMoney(property.sellingPrice, property.sellingCurrency)}</div>
          </div>
        </div>
        <div className={css.menu}>
          {canManageProperties(me) && (
            <>
              <Button
                size="small-icon"
                color="clear-text-orange"
                icon="dots"
                ref={menuRef}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
              <div
                className={cn({
                  [css.dropdown]: true,
                  [css.toBottom]: true,
                  [css.hidden]: !isDropdownMenuOpen,
                })}
                ref={dropdownMenuRef}
              >
                <div className={cn(css.links, css.compressed)}>
                  {canManageProperties(me) && (
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={getPropertyEditRoute(property.serialNumber)}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="edit" />
                        </div>
                        <div className={css.text}>Редактировать</div>
                      </Link>
                    </div>
                  )}
                  {/* canDeleteProperties(me) && (
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          setConfirmationOpen(true)
                          setDropdownMenuOpen(false)
                        }}
                      >
                        <div className={cn(css.iconPlace, css.red)}>
                          <Icon name="delete" />
                        </div>
                        <div className={cn(css.text, css.red)}>Удалить</div>
                      </Link>
                    </div>
                      ) */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <ConfirmationModal
        isOpen={confirmationOpen}
        setOpen={setConfirmationOpen}
        title="Точно удалить пользователя?"
        buttonText="Удалить пользователя"
        buttonColor="system-error-red"
        buttonProps={buttonDeletePropertyProps}
        alertProps={alertDeletePropertyProps}
        onConfirm={() => deletePropertyFormik.handleSubmit()}
                    /> */}
    </>
  )
}

const PropertiesList = ({ me }) => {
  const { properties, pageInfo, loading, error, fetchMore } = useQuery('getProperties', {
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!properties.length) return <Alert message="Нет объектов" />
  return (
    <div className={css.properties}>
      <div className={css.header}>
        <div className={css.row}>
          <div className={css.cell}>Название</div>
          <div className={css.cell}>Есть фото</div>
          <div className={css.cell}>Доходность</div>
          <div className={css.cell}>Покупка</div>
          <div className={css.cell}>Срок реализации</div>
          <div className={css.cell}>Продажа</div>
          {canManageProperties(me) && <div className={css.cell}></div>}
        </div>
      </div>
      <div className={css.items}>
        <InfiniteScroll
          threshold={250}
          loadMore={async () => {
            return fetchMore({
              variables: {
                input: {
                  endCursor: pageInfo.endCursor,
                  limit: 30,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return {
                  getProperties: {
                    ...fetchMoreResult.getProperties,
                    properties: [...prev.getProperties.properties, ...fetchMoreResult.getProperties.properties],
                  },
                }
              },
            })
          }}
          hasMore={pageInfo.hasNextPage}
          loader={
            <div className={css.loader} key="loader">
              <Loader type="section" />
            </div>
          }
        >
          {properties.map((property, i) => (
            <PropertiesListItem key={property.id} property={property} me={me} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

const PropertiesPage = () => {
  return (
    <PageWrapper redirerctUnauthorized>
      {({ me }) => (
        <Segment title="Объекты">
          {/* canManageProperties(me) && (
            <div className={css.new}>
              <Buttons>
                <Button to={getPropertyCreateRoute()}>Добавить объект</Button>
              </Buttons>
            </div>
          ) */}
          <PropertiesList me={me} />
        </Segment>
      )}
    </PageWrapper>
  )
}

export default PropertiesPage
