import { gql } from '@apollo/client'

export const createNewsPostMutation = gql`
  mutation ($input: CreateNewsPostInput!) {
    createNewsPost(input: $input) {
      newsPost {
        id
        serialNumber
        date
        contentMd
        contentHtml
        photos
        propertyId
      }
    }
  }
`

export const createNewsPostOptions = { parser: [['newsPost', 'createNewsPost.newsPost']] }
