import { ReactComponent as LogoBig } from 'assets/landing/logo-big.svg'
import { ReactComponent as LogoSmall } from 'assets/landing/logo-small.svg'
import cn from 'classnames'
import Button1 from 'components/Button1'
import { getDashboardRoute, getHomeRoute, getSignInRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'
import { Link } from 'react-router-dom'
import css from './styles.module.scss'

const DocsLayout = ({ children }) => {
  const { me, loading } = useQuery('getMe')
  return (
    <div className={css.docsLayout}>
      <div id="header" className={cn(css.section, css.header)}>
        <div className={css.headerInner}>
          <div className={css.wrapper}>
            <div className={css.logoPlace}>
              <Link to={getHomeRoute() + '#header'} className={css.logoBigLink}>
                <LogoBig />
              </Link>
              <Link to={getHomeRoute() + '#header'} className={css.logoSmallLink}>
                <LogoSmall />
              </Link>
            </div>
            <div className={css.authMenu}>
              {loading ? null : !me ? (<>
                <div className={css.authMenuItem}>
                  <Button1
                    icon="signin"
                    iconClassName={css.authMenuItemIcon}
                    size="small"
                    color="blue-without-background"
                    to={getSignInRoute()}
                  >
                    Вход
                  </Button1>
                </div>
                <div className={css.authMenuItem}>
                  <Button1 size="small" color="blue" to={getHomeRoute() + '#form'}>
                    Регистрация
                  </Button1>
                </div>
              </>) : (
                <div className={css.authMenuItem}>
                  <Button1 size="small" color="blue" to={getDashboardRoute()}>
                    Личный кабинет
                  </Button1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={css.content}>{children}</div>
    </div>
  )
}

export default DocsLayout
