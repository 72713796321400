import { gql } from '@apollo/client'
import { propertyFragment } from './propertyFragment'
import { txFragment } from './txFragment'

export const moneyInCurrenciesFragment = gql`
  fragment moneyInCurrenciesFragment on MoneyInCurrencies {
    rub
    usd
    euro
    usdt
  }
`

export const getMyDashboardDataQuery = gql`
  ${propertyFragment}
  ${moneyInCurrenciesFragment}
  ${txFragment}
  query getMyDashboardData {
    getMyDashboardData {
      txs {
        ...txFragment
      }
      propertiesWithMoney {
        investedCurrent {
          ...moneyInCurrenciesFragment
        }
        invested {
          ...moneyInCurrenciesFragment
        }
        profited {
          ...moneyInCurrenciesFragment
        }
        profitedBody {
          ...moneyInCurrenciesFragment
        }
        profitedInterest {
          ...moneyInCurrenciesFragment
        }
        property {
          ...propertyFragment
        }
        profitPercentTotal
        profitPercentYear
      }
      moneyInProperties {
        ...moneyInCurrenciesFragment
      }
      moneyWillBeProfited {
        ...moneyInCurrenciesFragment
      }
      moneyReferralWillBeProfited {
        ...moneyInCurrenciesFragment
      }
      moneyFree {
        ...moneyInCurrenciesFragment
      }
      moneyTotal {
        ...moneyInCurrenciesFragment
      }
      moneyDepositBonus {
        ...moneyInCurrenciesFragment
      }
      moneyProfited {
        ...moneyInCurrenciesFragment
      }
      moneyWithdrawled {
        ...moneyInCurrenciesFragment
      }
      moneyInvested {
        ...moneyInCurrenciesFragment
      }
    }
  }
`

export const getMyDashboardDataOptions = {
  parser: [['dashboardData', 'getMyDashboardData']],
}

export const getUserDashboardDataQuery = gql`
  ${propertyFragment}
  ${moneyInCurrenciesFragment}
  ${txFragment}
  query getUserDashboardData($input: GetUserDashboardDataInput!) {
    getUserDashboardData(input: $input) {
      txs {
        ...txFragment
      }
      propertiesWithMoney {
        investedCurrent {
          ...moneyInCurrenciesFragment
        }
        invested {
          ...moneyInCurrenciesFragment
        }
        profited {
          ...moneyInCurrenciesFragment
        }
        property {
          ...propertyFragment
        }
        profitPercentTotal
        profitPercentYear
      }
      moneyInProperties {
        ...moneyInCurrenciesFragment
      }
      moneyWillBeProfited {
        ...moneyInCurrenciesFragment
      }
      moneyReferralWillBeProfited {
        ...moneyInCurrenciesFragment
      }
      moneyFree {
        ...moneyInCurrenciesFragment
      }
      moneyTotal {
        ...moneyInCurrenciesFragment
      }
      moneyDepositBonus {
        ...moneyInCurrenciesFragment
      }
      moneyProfited {
        ...moneyInCurrenciesFragment
      }
      moneyWithdrawled {
        ...moneyInCurrenciesFragment
      }
      moneyInvested {
        ...moneyInCurrenciesFragment
      }
    }
  }
`

export const getUserDashboardDataOptions = {
  parser: [['dashboardData', 'getUserDashboardData']],
}
