import { gql } from '@apollo/client'
import { companyResultFragment } from './companyResultFragment'

export const companyResultListQuery = gql`
  ${companyResultFragment}
  query companyResultList($input: GetCompanyResultsInput) {
    companyResultList(input: $input) {
      companyResults {
        ...companyResultFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const companyResultListOptions = {
  parser: [
    ['companyResults', 'companyResultList.companyResults'],
    ['pageInfo', 'companyResultList.pageInfo'],
  ],
}
