import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

if (process.env.REACT_APP_SENTRY_ON === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

if (process.env.REACT_APP_MIXPANEL_ON === 'true') {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// serviceWorkerRegistration.register({
//   onUpdate: (e) => {
//     const { waiting: { postMessage = null } = {}, update } = e || {}
//     if (postMessage) {
//       postMessage({ type: 'SKIP_WAITING' })
//     }
//     update().then(() => {
//       window.location.reload()
//     })
//   },
// })

serviceWorkerRegistration.unregister()
