import PageWrapper from 'components/PageWrapper'
import { useEffect } from 'react'

export const RedirectPage = ({ to }) => {
  useEffect(() => {
    window.location.replace(to)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <PageWrapper queryResult={{ loading: true }} />
}
