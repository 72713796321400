import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const scrollToHash = (hash, elOffset = 30, pageOffset = 60) => {
  const el = document.querySelector(hash)
  const scrollWrapperEl = document.querySelector('#scroll-wrapper')
  if (el) {
    if (scrollWrapperEl) {
      scrollWrapperEl.scrollTo({
        top: el.getBoundingClientRect().top + scrollWrapperEl.scrollTop - elOffset,
        behavior: 'smooth',
      })
    }
    window.scrollTo({ top: el.getBoundingClientRect().top + window.pageYOffset - pageOffset, behavior: 'smooth' })
  }
}

const ScrollToHash = ({ elOffset = 30, pageOffset = 60 }) => {
  const { hash, pathname } = useLocation()

  useEffect(() => {
    if (hash) {
      scrollToHash(hash, elOffset, pageOffset)
    }
  }, [hash, pathname])

  return null
}

export default ScrollToHash
