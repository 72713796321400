import { gql } from '@apollo/client'
import { bankAccountFragment } from './bankAccountFragment'

export const sendBankAccountToProcessMutation = gql`
  ${bankAccountFragment}
  mutation ($input: SendBankAccountToProcessInput!) {
    sendBankAccountToProcess(input: $input) {
      me {
        id
        processingBankAccount {
          ...bankAccountFragment
        }
      }
    }
  }
`

export const sendBankAccountToProcessOptions = { parser: [['me', 'sendBankAccountToProcess.me']] }
