import cn from 'classnames'
import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import ConfirmationModal from 'components/ConfirmationModal'
import { useForm } from 'components/Form'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Link from 'components/Link'
import Loader from 'components/Loader'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canDeleteUsers, canEditUsers, canViewUsers } from 'lib/can'
import { getUserEditRoute, getUserRoute } from 'lib/routes'
import useDropdown from 'lib/useDropdown'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import css from './styles.module.scss'

const UsersListItem = ({ user, me }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const menuRef = useRef()
  const dropdownMenuRef = useRef()
  useDropdown(menuRef, dropdownMenuRef, isDropdownMenuOpen, setDropdownMenuOpen)
  const [deleteUser] = useMutation('deleteUser')
  const {
    formik: deleteUserFormik,
    buttonProps: buttonDeleteUserProps,
    alertProps: alertDeleteUserProps,
  } = useForm({
    onSubmit: () => {
      return deleteUser({
        variables: {
          input: {
            userId: user.id,
          },
        },
        refetchQueries: ['getUsers'],
      })
    },
    successMessage: 'Пользователь удалён',
  })
  return (
    <>
      <Link className={cn(css.row, css.clickable)} to={getUserRoute(user.serialNumber)}>
        <div className={css.cell}>{user.displayName}</div>
        <div className={css.cell}>{user.email}</div>
        <div className={css.cell}>{user.phone}</div>
        <div className={css.cell}>
          <Buttons>
            {canEditUsers(me) && (
              <Link
                color="system-links-blue"
                to={getUserEditRoute(user.serialNumber)}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                Редактировать
              </Link>
            )}
            {canDeleteUsers(me) && (
              <Link
                color="system-error-red"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setConfirmationOpen(true)
                }}
              >
                Удалить
              </Link>
            )}
          </Buttons>
        </div>
      </Link>
      <Link className={cn(css.card, css.clickable)} to={getUserRoute(user.serialNumber)}>
        {/*<div className={css.cardHeader}>*/}
        {/*  <div className={css.number}>{user.serialNumber}</div>*/}
        {/*</div>*/}
        {user.displayName && <div className={css.title}>{user.displayName}</div>}
        <div className={css.info}>
          {user.email && (
            <div className={css.infoItem}>
              <div className={css.icon}>
                <Icon name="mail" />
              </div>
              <div className={css.text}>{user.email}</div>
            </div>
          )}
          {user.phone && (
            <div className={css.infoItem}>
              <div className={css.icon}>
                <Icon name="phone" />
              </div>
              <div className={css.text}>{user.phone}</div>
            </div>
          )}
        </div>
        <div className={css.menu}>
          {(canEditUsers(me) || canDeleteUsers(me)) && user.id !== me.id && (
            <>
              <Button
                size="small-icon"
                color="clear-text-quaternary"
                icon="dots"
                ref={menuRef}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
              <div
                className={cn({
                  [css.dropdown]: true,
                  [css.toBottom]: true,
                  [css.hidden]: !isDropdownMenuOpen,
                })}
                ref={dropdownMenuRef}
              >
                <div className={cn(css.links, css.compressed)}>
                  {canEditUsers(me) && (
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={getUserEditRoute(user.serialNumber)}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="edit" />
                        </div>
                        <div className={css.text}>Редактировать</div>
                      </Link>
                    </div>
                  )}
                  {canDeleteUsers(me) && (
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          setConfirmationOpen(true)
                          setDropdownMenuOpen(false)
                        }}
                      >
                        <div className={cn(css.iconPlace, css.red)}>
                          <Icon name="delete" />
                        </div>
                        <div className={cn(css.text, css.red)}>Удалить</div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Link>
      <ConfirmationModal
        isOpen={confirmationOpen}
        setOpen={setConfirmationOpen}
        title="Точно удалить пользователя?"
        buttonText="Удалить пользователя"
        buttonColor="system-error-red"
        buttonProps={buttonDeleteUserProps}
        alertProps={alertDeleteUserProps}
        onConfirm={() => deleteUserFormik.handleSubmit()}
      />
    </>
  )
}

const UsersList = ({ me, queryResult, search }) => {
  const { users, pageInfo, loading, error, fetchMore } = queryResult
  if (loading) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!users.length) return <Alert message="Нет пользователей" />
  return (
    <div className={css.users}>
      <div className={css.header}>
        <div className={css.row}>
          <div className={css.cell}>Имя</div>
          <div className={css.cell}>Эл. почта</div>
          <div className={css.cell}>Телефон</div>
          <div className={css.cell}></div>
        </div>
      </div>
      <div className={css.items}>
        <InfiniteScroll
          threshold={250}
          useWindow={false}
          getScrollParent={() => {
            const el = document.getElementById('scroll-wrapper')
            return el
          }}
          loadMore={async () => {
            return fetchMore({
              variables: {
                input: {
                  endCursor: pageInfo.endCursor,
                  limit: 20,
                  search,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                if (!prev) return fetchMoreResult
                return {
                  getUsers: {
                    ...fetchMoreResult.getUsers,
                    users: [...(prev?.getUsers?.users || []), ...(fetchMoreResult?.getUsers?.users || [])],
                  },
                }
              },
            })
          }}
          hasMore={pageInfo.hasNextPage}
          loader={
            <div className={css.loader} key="loader">
              <Loader type="section" />
            </div>
          }
        >
          {users.map((user, i) => (
            <UsersListItem key={user.id} user={user} me={me} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

const UsersSearch = ({ setSearch }) => {
  const { formik } = useForm({
    initialValues: {
      search: '',
    },
  })
  useEffect(() => {
    setSearch(formik.values.search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.search])

  return <Input style={{ 'margin-bottom': '20px' }} fullWidth name="search" formik={formik} />
}

const UsersPage = () => {
  const [search, setSearch] = useState('')
  const queryResult = useQuery('getUsers', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        limit: 20,
        search,
      },
    },
  })
  return (
    <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canViewUsers(me)}>
      {({ me }) => (
        <Segment title={`Пользователи${queryResult.total ? ` (${queryResult.total})` : ''}`}>
          {/* canCreateUsers(me) && (
            <div className={css.new}>
              <Buttons>
                <Button to={getUserCreateRoute()}>Добавить пользователя</Button>
              </Buttons>
            </div>
          ) */}
          <UsersSearch setSearch={setSearch} />
          <UsersList me={me} queryResult={queryResult} search={search} />
        </Segment>
      )}
    </PageWrapper>
  )
}

export default UsersPage
