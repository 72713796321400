import { gql } from '@apollo/client'
import { propertyFragment } from './propertyFragment'
import { userFragment } from './userFragment'

export const txFragment = gql`
  ${propertyFragment}
  fragment txFragment on Tx {
    id
    serialNumber
    property {
      ...propertyFragment
    }
    createdAt
    sum
    sumPlus
    sumMinus
    currency
    currencyPlus
    currencyMinus
    type
    extraText
  }
`

export const txFragmentWithUser = gql`
  ${propertyFragment}
  ${userFragment}
  fragment txFragmentWithUser on Tx {
    id
    serialNumber
    property {
      ...propertyFragment
    }
    user {
      ...userFragment
    }
    createdAt
    sum
    sumPlus
    sumMinus
    currency
    currencyPlus
    currencyMinus
    type
  }
`
