import { gql } from '@apollo/client'
import { bankAccountFragment } from './bankAccountFragment.js'

export const getBankAccountQuery = gql`
  ${bankAccountFragment}
  query ($input: GetBankAccountInput!) {
    getBankAccount(input: $input) {
      bankAccount {
        ...bankAccountFragment
        user {
          id
          bankAccount {
            ...bankAccountFragment
          }
        }
      }
    }
  }
`

export const getBankAccountOptions = {
  parser: [['bankAccount', 'getBankAccount.bankAccount']],
}
