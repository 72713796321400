import { gql } from '@apollo/client'

export const propertyFragment = gql`
  fragment propertyFragment on Property {
    id
    status
    photo
    name
    desc
    notionUrl
    contentMd
    contentHtml
    propertyType
    area
    additionalExpenses
    purchasePrice
    purchaseCurrency
    sellingPrice
    sellingCurrency
    minPeriod
    maxPeriod
    createdAt
    startsAt
    fundraisingUntil
    endsAt
    photos
    documents
    serialNumber
    profitSum
    expensesSum
    minInvestmentSum
    maxInvestmentSum
    profitPercentTotal
    minProfitPercent
    maxProfitPercent
    realProfitPercent
    realPeriodMonths
    realPeriodDays
    roi
    leftToCollect
    neededToCollect
    leftToCollectOriginal
    neededToCollectOriginal
    collectCurrency
    investorsCount
    message
  }
`
