import React, { useEffect } from 'react'
import Alert from 'components/Alert'
import Loader from 'components/Loader'
import useMutation from 'lib/useMutation'
import Button from 'components/Button'
import { getDashboardRoute } from 'lib/routes'
import RichText from 'components/RichText'

const ConfirmEmailPage = ({ match }) => {
  const confirmEmailToken = match.params.confirmEmailToken
  const [confirmEmail, { called, loading, error }] = useMutation('confirmEmail', {
    errorCodes: {
      ERR_INVALID_CONFIRM_EMAIL_TOKEN: 'Ссылка для подтверждения адреса электронной почты не действительна',
    },
  })
  useEffect(() => {
    confirmEmail({ variables: { input: { confirmEmailToken } } }).catch(e => {})
  }, [confirmEmailToken, confirmEmail])
  if (loading || !called) return <Loader type="page" />
  return error ? (
    <RichText>
      <h1>Ошибка подтверждения почты</h1>
      <Alert type="danger" message={error.humanMessage} />
      <br />
      <Button to={getDashboardRoute()}>Перейти в сервис</Button>
    </RichText>
  ) : (
    <RichText>
      <h1>Почта успешно подтверждена</h1>
      <br />
      <Button to={getDashboardRoute()}>Перейти в сервис</Button>
    </RichText>
  )
}

export default ConfirmEmailPage
