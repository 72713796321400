import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import Link from 'components/Link'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { getSignInRoute } from 'lib/routes'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'
import PhoneVerification, { usePhoneVerification } from '../../components/PhoneVerification/index.js'

const RestorePasswordByEmail = () => {
  const [requestPasswordResetByEmail] = useMutation('requestPasswordResetByEmail', {
    errorCodes: {
      ERR_USER_NOT_FOUND: 'Пользователь с такой почтой не найден',
    },
  })
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      return requestPasswordResetByEmail({
        variables: { input: values },
      })
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    successMessage:
      'Ссылка для восстановления пароля отправлена вам на почту. Проверьте папку "Спам", если не увидите письма во входящих',
    resetOnSuccess: true,
  })
  return (
    <Form formik={formik}>
      <FormItems>
        <Input label="Адрес электронной почты" name="email" inputMode="email" formik={formik} />
        <Alert {...alertProps} />
        <Buttons>
          <Button {...buttonProps}>Восстановить пароль</Button>
          <Link color="text-trietary" to={getSignInRoute()}>
            Вход
          </Link>
        </Buttons>
      </FormItems>
    </Form>
  )
}

const RestorePasswordByPhone = () => {
  const [requestPasswordResetByPhone] = useMutation('requestPasswordResetByPhone', {
    errorCodes: {
      ERR_USER_NOT_FOUND: 'Пользователь с такой почтой не найден',
    },
  })
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      phone: '',
      verificationCode: '',
      newPassword: '',
      newPasswordAgain: '',
    },
    onSubmit: ({ phone, verificationCode, newPassword }) => {
      return requestPasswordResetByPhone({
        variables: { input: { phone, verificationCode, newPassword } },
      }).then(() => {
        phoneVerification.reset()
      })
    },
    validationSchema: yup.object({
      phone: yup.string().required(),
      verificationCode: yup.string().required(),
      newPassword: yup.string().required(),
      newPasswordAgain: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Пароли не совпадают')
        .required(),
    }),
    successMessage: (
      <>
        Новый пароль установлен, теперь вы можете{' '}
        <Link color="system-links-blue" to={getSignInRoute()}>
          войти
        </Link>{' '}
        с помощью него
      </>
    ),
    resetOnSuccess: true,
  })
  const phoneVerification = usePhoneVerification({
    purpose: 'resetPassword',
    onChange: ({ verificationCode, phone }) => {
      formik.setFieldValue('phone', phone)
      formik.setFieldValue('verificationCode', verificationCode)
    },
    formik,
    phoneLabel: 'Номер телефона',
    requestButton: 'Восстановить пароль',
    hideButton: true,
  })
  return (
    <Form formik={phoneVerification.formikInForm}>
      <FormItems>
        <PhoneVerification {...phoneVerification} />
        {formik.values.phone && (
          <>
            <Input label="Новый пароль" name="newPassword" type="password" formik={formik} />
            <Input label="Подтвердите новый пароль" name="newPasswordAgain" type="password" formik={formik} />
          </>
        )}
        <Alert {...alertProps} />
        <Buttons>
          {formik.values.phone ? (
            <Button {...buttonProps}>Установить пароль</Button>
          ) : (
            <Button {...phoneVerification.buttonProps}>{phoneVerification.button}</Button>
          )}
          <Link color="text-trietary" to={getSignInRoute()}>
            Вход
          </Link>
        </Buttons>
      </FormItems>
    </Form>
  )
}

const RestorePasswordPage = () => {
  const { formik } = useForm({
    initialValues: {
      method: 'email',
    },
  })
  return (
    <PageWrapper redirerctAuthorized>
      <Segment title="Восстановление пароля">
        <FormItems>
          {/*<RadioButtons label="Способ восстановления" direction="column" formik={formik} name="method">
            <RadioButton label="По номеру телефона" value="phone" formik={formik} name="method" />
            <RadioButton label="По адресу почты" value="email" formik={formik} name="method" />
  </RadioButtons>*/}
          {formik.values.method === 'phone' && <RestorePasswordByPhone />}
          {formik.values.method === 'email' && <RestorePasswordByEmail />}
        </FormItems>
      </Segment>
    </PageWrapper>
  )
}

export default RestorePasswordPage
