import React, { useEffect, useRef } from 'react'
import css from './styles.module.scss'
import cn from 'classnames'
import _ from 'lodash'
import Icon from 'components/Icon'
import { IMaskInput } from 'react-imask'

const Input = ({
  label,
  mask,
  formik,
  name,
  hint,
  onChange,
  fullWidth,
  width,
  disabled,
  noName,
  icon,
  inputMode,
  defaultValue,
  type,
  ...restProps
}) => {
  const error = formik && _.get(formik.errors, name)
  const value = defaultValue === undefined ? formik && _.get(formik.values, name) : defaultValue
  const touched = formik && _.get(formik.touched, name)
  const elRef = useRef()
  useEffect(() => {
    if (inputMode === 'decimal' && value) {
      const newValue = value.replace(',', '.')
      if (newValue !== value) {
        formik?.setFieldValue(name, newValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  return (
    <div
      className={cn({
        [css.field]: true,
        [css.disabled]: disabled,
        [css.fullWidth]: !!fullWidth,
        [css.withIcon]: !!icon,
      })}
      style={width ? { maxWidth: width } : {}}
    >
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      <div className={css.inputPlace}>
        {icon && (
          <div className={css.iconPlace}>
            <Icon className={css.icon} name={icon} />
          </div>
        )}
        <IMaskInput
          mask={mask}
          onChange={(e) => {
            if (!mask) {
              const newValue = e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value
              if (onChange) onChange(newValue)
              formik?.setFieldValue(name, newValue)
            }
          }}
          onAccept={(value) => {
            if (mask) {
              const newValue = value
              if (onChange) onChange(newValue)
              formik?.setFieldValue(name, newValue)
            }
          }}
          inputRef={(el) => (elRef.current = el)}
          onBlur={() => {
            formik?.setFieldTouched(name)
          }}
          onPaste={(e) => {
            if (inputMode === 'tel') {
              e.preventDefault()
              const text = e.clipboardData.getData('Text')
              if (text.substr(0, 1) === '8') {
                formik?.setFieldValue(name, text.replace('8', '+7'))
              } else {
                formik?.setFieldValue(name, text)
              }
              setTimeout(() => {
                formik?.setFieldValue(name, elRef.current.value)
              }, 1)
            }
          }}
          inputMode={inputMode}
          // pattern={{ number: '[0-9]*' }[type]}
          type={type}
          name={!noName ? name : null}
          value={value}
          className={cn({
            [css.input]: true,
            [css.fullWidth]: !!fullWidth,
            [css.invalid]: !!error && touched,
          })}
          disabled={disabled}
          {...restProps}
        />
      </div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default Input
