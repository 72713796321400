import { gql } from '@apollo/client'

export const syncSheetsMutation = gql`
  mutation {
    syncSheets
  }
`

export const whatSheetsMutation = gql`
  mutation {
    whatSheets
  }
`
