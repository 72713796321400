import { getDashboardRoute, getResetPasswordRoute, getRestorePasswordRoute, getSignInRoute, getSignUpRoute } from 'lib/routes'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export let lastVisistedNotAuthRouteStore = getDashboardRoute()

export const NotAuthRouteTracker = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    const authRoutesRegexes = [
      getSignUpRoute(),
      getSignInRoute(),
      getResetPasswordRoute({ resetPasswordToken: '[^/]+' }),
      getRestorePasswordRoute(),
    ]
    // Check if pathname not equals to regex in authRoutesRegexes
    const isAuthRoute = authRoutesRegexes.some((regex) => new RegExp('^' + regex + '$').test(pathname))

    // const isAuthRoute = authRoutes.includes(pathname)
    if (!isAuthRoute) {
      lastVisistedNotAuthRouteStore = pathname
    }
  }, [pathname])

  return null
}
