import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Checkbox, { Checkboxes } from 'components/Checkbox'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import UploadMultipleFiles from 'components/UploadMultipleFiles'
import UploadSingleFile from 'components/UploadSingleFile'
import { canEditUserPermissions, canEditUsers } from 'lib/can'
import { getUserRoute } from 'lib/routes'
import toHumanPermission, { permissions } from 'lib/toHumanPermission'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'
import { usePermissonNormalizer } from 'pages/UserCreatePage/index'
import css from './styles.module.scss'

const UserEditPage = ({ user, me }) => {
  const [updateUser] = useMutation('updateUser')
  const {
    formik: updateUserFormik,
    buttonProps: buttonUpdateUserProps,
    alertProps: alertUpdateUserProps,
  } = useForm({
    initialValues: {
      name: user.name,
      email: user.email,
      phone: user.phone,
      permissions: user.permissions,
      presentation: user.presentation || '',
      documents: user.documents,
      newPassword: '',
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      phone: yup.string().required(),
    }),
    onSubmit: (values) => {
      return updateUser({
        variables: {
          input: {
            userId: user.id,
            ...values,
          },
        },
      })
    },
    successMessage: 'Изменения успешно сохранены',
  })
  usePermissonNormalizer(updateUserFormik)
  return (
    <Segment title={`Пользователь ${user.displayName}`}>
      {user.deletedAt && (
        <div className={css.deletedAlert}>
          <Alert message="Пользователь удалён" type="danger" />
        </div>
      )}
      <Form formik={updateUserFormik}>
        <FormItems>
          <Input disabled={true} name="name" label="ФИО" formik={updateUserFormik} />
          <Input disabled={true} name="email" label="E-mail" inputMode="email" formik={updateUserFormik} />
          <Input
            disabled={true}
            name="phone"
            mask="+{7} 000 000-00-00"
            label="Телефон"
            inputMode="tel"
            formik={updateUserFormik}
          />
          <UploadSingleFile name="presentation" formik={updateUserFormik} label="Презентация" />
          <UploadMultipleFiles name="documents" formik={updateUserFormik} label="Документы" />
          <Input name="newPassword" label="Новый пароль" formik={updateUserFormik} />
          {canEditUserPermissions(me) && (
            <Checkboxes name="permissions" label="Права" formik={updateUserFormik}>
              {Object.keys(permissions)
                .filter((permission) => permission !== 'all')
                .map((permission, i) => (
                  <Checkbox
                    formik={updateUserFormik}
                    label={toHumanPermission(permission)}
                    type="array"
                    name="permissions"
                    key={i}
                    value={permission}
                  />
                ))}
            </Checkboxes>
          )}
          <Alert {...alertUpdateUserProps} />
          <Buttons>
            <Button {...buttonUpdateUserProps}>Сохранить изменения</Button>
            <Button to={getUserRoute(user.serialNumber)}>Просмотр пользователя</Button>
            {/* !user.deletedAt && (
              <ButtonWithConfirmation
                title="Точно удалить пользователя?"
                modalButtonText="Удалить пользователя"
                modalButtonColor="red"
                color="system-error-red"
                {...buttonDeleteUserProps}
                onClick={() => deleteUserFormik.handleSubmit()}
              >
                Удалить пользователя
              </ButtonWithConfirmation>
            ) */}
          </Buttons>
        </FormItems>
      </Form>
    </Segment>
  )
}

const GetUserWrapper = (props) => {
  const userSerialNumber = props.match.params.userSerialNumber
  const queryResult = useQuery('getUser', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        userSerialNumber,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ user }) => !!user}>
      {({ user }) => <UserEditPage user={user} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canEditUsers(me)}>
    {({ me }) => <GetUserWrapper me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
