import { gql } from '@apollo/client'
import { companyResultFragment } from './companyResultFragment'

export const companyResultUpdateMutation = gql`
  ${companyResultFragment}
  mutation ($input: UpdateCompanyResultInput!) {
    companyResultUpdate(input: $input) {
      companyResult {
        ...companyResultFragment
      }
    }
  }
`

export const companyResultUpdateOptions = { parser: [['companyResult', 'companyResultUpdate.companyResult']] }
