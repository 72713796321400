import { gql } from '@apollo/client'

export const passportFragment = gql`
  fragment passportFragment on Passport {
    id
    fullName
    seriesAndNumber
    dateOfIssue
    registration
    issuedBy
    departmentCode
    dateOfBirth
    placeOfBirth
    passportScan1
    passportScan2
    sentToProcessByUserAt
    rejectedByManagerAt
    acceptedByManagerAt
    status
    rejectComment
    prevRejectComment
    user {
      id
      serialNumber
      displayName
      email
      phone
    }
  }
`
