import { useApolloClient } from '@apollo/client'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import { getSignInRoute } from 'lib/routes'
import { getMeQuery } from 'gqls'

const useLogout = () => {
  const apolloClient = useApolloClient()
  const history = useHistory()
  return e => {
    e && e.preventDefault()
    Cookies.remove('token')
    apolloClient.writeQuery({
      query: getMeQuery,
      data: {
        getMe: {
          __typename: 'GetMePayload',
          me: null,
        },
      },
    })
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        apolloClient.resetStore().then(() => {
          history.push(getSignInRoute())
          resolve()
        })
      }, 1)
    })
  }
}

export default useLogout
