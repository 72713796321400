import React from 'react'
import Segment from 'components/Segment'
import PageWrapper from 'components/PageWrapper'
import Input from 'components/Input'
import Button, { Buttons } from 'components/Button'
import Alert from 'components/Alert'
import Link from 'components/Link'
import Form, { FormItems, useForm } from 'components/Form'
import yup from 'lib/validation'
import useMutation from 'lib/useMutation'
import { getRestorePasswordRoute, getSignInRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'

const ResetPasswordPage = ({ match }) => {
  const { passwordResetToken } = match.params
  const queryResult = useQuery('isResetPasswordTokenValid', {
    variables: {
      input: {
        passwordResetToken,
      },
    },
  })
  const { valid: resetPasswordTokenValid } = queryResult
  const [resetPassword] = useMutation('resetPassword', {
    errorCodes: {
      ERR_NOT_FOUND: 'Ссылка для восстановления пароля не действительна',
    },
  })
  const { formik, buttonProps, alertProps, success } = useForm({
    initialValues: {
      newPassword: '',
      newPasswordAgain: '',
    },
    onSubmit: ({ newPassword }) => {
      return resetPassword({
        variables: {
          input: {
            newPassword,
            passwordResetToken,
          },
        },
      })
    },
    validationSchema: yup.object({
      newPassword: yup.string().required(),
      newPasswordAgain: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Пароли не совпадают')
        .required(),
    }),
    successMessage: (
      <>
        Новый пароль установлен, теперь вы можете <Link color="system-links-blue" to={getSignInRoute()}>войти</Link> с помощью него
      </>
    ),
    resetOnSuccess: true,
    forgetSuccessDelay: 0,
  })
  return (
    <PageWrapper redirerctAuthorized queryResult={queryResult}>
      {() =>
        !resetPasswordTokenValid ? (
          <Segment title="Ссылка для восстановления пароля не действительна">
            <Alert
              type="danger"
              title={
                <>
                  Ссылка для восстановления пароля не действительна. Вы можете заного восстановить пароль{' '}
                  <Link color="system-links-blue" to={getRestorePasswordRoute()}>по этой ссылке.</Link>
                </>
              }
            />
          </Segment>
        ) : (
          <Segment title="Установите новый пароль">
            {!success ? (
              <Form formik={formik}>
                <FormItems>
                  <Input label="Новый пароль" name="newPassword" type="password" formik={formik} />
                  <Input label="Подтвердите новый пароль" name="newPasswordAgain" type="password" formik={formik} />
                  <Alert {...alertProps} />
                  <Buttons>
                    <Button {...buttonProps}>Установить пароль</Button>
                  </Buttons>
                </FormItems>
              </Form>
            ) : (
              <Alert {...alertProps} />
            )}
          </Segment>
        )
      }
    </PageWrapper>
  )
}

export default ResetPasswordPage
