import { useCallback } from 'react'
import { useMutation as useMutationApollo } from '@apollo/client'
import prettifyError from 'lib/prettifyError'
import batchGet from 'lib/batchGet'
import * as gqls from 'gqls'
import * as Sentry from '@sentry/react'

const useMutation = (queryOrName, options) => {
  let query
  let optionsHere
  if (typeof queryOrName === 'string') {
    query = gqls[`${queryOrName}Mutation`]
    if (!query) throw new Error(`useMutation: ${queryOrName} not implenented in gqls`)
    const optionsGqls = gqls[`${queryOrName}Options`] || {}
    optionsHere = { ...optionsGqls, ...options }
  } else {
    query = queryOrName
    optionsHere = options
  }
  const { errorCodes, parser, ...mutationOptions } = optionsHere
  const [fn, info] = useMutationApollo(query, mutationOptions)
  const customFn = useCallback(
    ({ errorCodes: execErrorCodes = {}, parser: execParser, ...mutationExecOptions } = {}) => {
      return fn({ ...mutationExecOptions })
        .then(res => {
          const parserHere = execParser || parser
          if (parserHere) return { ...res, ...batchGet(res.data, parserHere) }
          return res
        })
        .catch(error => {
          prettifyError(error, { ...(errorCodes || {}), ...execErrorCodes })
          if (error.isUnknown) {
            console.error(error)
            if (process.env.REACT_APP_SENTRY_ON === 'true') {
              Sentry.captureException(error)
            }
          }
          throw error
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fn]
  )
  // if (info.error) {
  //   prettifyError(info.error, errorCodes)
  // }
  return [customFn, info]
}

export default useMutation
