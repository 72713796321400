import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import useMutation from 'lib/useMutation'
import yup from 'lib/validation'
import { useAppCtx } from '../AppContextProvider/index.js'
import Textarea from '../Textarea/index.js'

const BankAccountFields = ({ formik, compareWith, disabled }) => {
  return (
    <>
      <Input
        disabled={disabled}
        label="Название банка"
        name="bankName"
        formik={formik}
        hint={
          compareWith && formik.values.bankName !== compareWith.bankName && `Прежнее значение: ${compareWith.bankName}`
        }
      />
      <Input
        disabled={disabled}
        label="БИК банка"
        name="bankBik"
        formik={formik}
        hint={
          compareWith && formik.values.bankBik !== compareWith.bankBik && `Прежнее значение: ${compareWith.bankBik}`
        }
      />
      <Input
        disabled={disabled}
        label="Корр счёт"
        name="corrAccount"
        formik={formik}
        hint={
          compareWith &&
          formik.values.corrAccount !== compareWith.corrAccount &&
          `Прежнее значение: ${compareWith.corrAccount}`
        }
      />
      <Input
        disabled={disabled}
        label="Номер счёта"
        name="benAccount"
        formik={formik}
        hint={
          compareWith &&
          formik.values.benAccount !== compareWith.benAccount &&
          `Прежнее значение: ${compareWith.benAccount}`
        }
      />
      <Input
        disabled={disabled}
        label="ФИО"
        name="fullName"
        formik={formik}
        hint={
          compareWith && formik.values.fullName !== compareWith.fullName && `Прежнее значение: ${compareWith.fullName}`
        }
      />
    </>
  )
}

export const BankAccountForUser = () => {
  const { me } = useAppCtx()
  const [sendBankAccountToProcess] = useMutation('sendBankAccountToProcess')
  const bankAccountInProcess = !!me.processingBankAccount
  const { formik, buttonProps, alertProps } = useForm({
    initialValues: {
      bankName: (bankAccountInProcess ? me.processingBankAccount.bankName : me.bankAccount?.bankName) || '',
      bankBik: (bankAccountInProcess ? me.processingBankAccount.bankBik : me.bankAccount?.bankBik) || '',
      corrAccount: (bankAccountInProcess ? me.processingBankAccount.corrAccount : me.bankAccount?.corrAccount) || '',
      benAccount: (bankAccountInProcess ? me.processingBankAccount.benAccount : me.bankAccount?.benAccount) || '',
      fullName: (bankAccountInProcess ? me.processingBankAccount.fullName : me.bankAccount?.fullName) || '',
    },
    onSubmit: ({ bankName, bankBik, corrAccount, benAccount, fullName }) => {
      return sendBankAccountToProcess({
        variables: {
          input: {
            bankName,
            bankBik,
            corrAccount,
            benAccount,
            fullName,
          },
        },
      })
    },
    validationSchema: yup.object({
      bankName: yup.string().required(),
      bankBik: yup.string().required(),
      corrAccount: yup.string().required(),
      benAccount: yup.string().required(),
      fullName: yup.string().required(),
    }),
    successMessage: 'Реквизиты успешно отправлены на проверку',
    enableReinitialize: true,
  })
  const statusAlertProps =
    me.processingBankAccount?.status === 'processing'
      ? {
          title: 'Ваши реквизиты находятся на проверке',
          message: 'Когда реквизиты будут проверены, вы получите уведомление по электронной почте',
        }
      : me.processingBankAccount?.status === 'rejected'
      ? {
          title: 'Менеджер отклонил ваши реквизиты',
          type: 'danger',
          message: `Комментарий от менеджера: ${me.processingBankAccount.rejectComment}`,
        }
      : !me.processingBankAccount && me.bankAccount
      ? {
          title: 'Ваши реквизиты успешно прошли проверку',
          type: 'success',
        }
      : {
          hidden: true,
        }

  return (
    <Form formik={formik}>
      <FormItems>
        <Alert {...statusAlertProps} />
        <BankAccountFields formik={formik} compareWith={me.bankAccount} />
        <Alert {...alertProps} />
        <Button {...buttonProps} disabled={!formik.dirty}>
          Сохранить изменения
        </Button>
        <Alert {...statusAlertProps} />
      </FormItems>
    </Form>
  )
}

export const BankAccountValidation = ({ newBankAccount, oldBankAccount }) => {
  const [approveBankAccount] = useMutation('approveBankAccount')
  const [rejectBankAccount] = useMutation('rejectBankAccount')
  const { formik, alertProps } = useForm({
    initialValues: {
      bankName: newBankAccount.bankName || '',
      bankBik: newBankAccount.bankBik || '',
      corrAccount: newBankAccount.corrAccount || '',
      benAccount: newBankAccount.benAccount || '',
      fullName: newBankAccount.fullName || '',
      rejectComment: newBankAccount.rejectComment || '',
      action: 'reject',
    },
    onSubmit: ({ bankName, bankBik, corrAccount, benAccount, fullName, rejectComment, action }) => {
      return action === 'approve'
        ? approveBankAccount({
            variables: {
              input: {
                bankAccountId: newBankAccount.id,
                bankName,
                bankBik,
                corrAccount,
                benAccount,
                fullName,
              },
            },
          })
        : action === 'reject'
        ? rejectBankAccount({
            variables: {
              input: {
                bankAccountId: newBankAccount.id,
                bankName,
                bankBik,
                corrAccount,
                benAccount,
                fullName,
                rejectComment,
              },
            },
          })
        : Promise.reject('Неизвестное действие')
    },
    validationSchema: yup.object({
      bankName: yup.string().required(),
      bankBik: yup.string().required(),
      corrAccount: yup.string().required(),
      benAccount: yup.string().required(),
      fullName: yup.string().required(),
      rejectComment: yup.string().when('action', {
        is: 'reject',
        then: yup.string().required(),
      }),
    }),
    successMessage: (res) => {
      return res.data.rejectBankAccount
        ? 'Реквизиты успешно отклонены'
        : res.data.approveBankAccount
        ? 'Реквизиты успешно приняты'
        : 'Произошло что-то странное'
    },
    enableReinitialize: true,
  })
  const statusAlertProps =
    newBankAccount.status === 'processing'
      ? {
          title: 'Реквизиты ожидают проверки с вашей стороны',
        }
      : newBankAccount.status === 'rejected'
      ? {
          title: 'Реквизиты были отклонены',
          type: 'danger',
        }
      : newBankAccount.status === 'accepted'
      ? {
          title: 'Реквизиты были приняты',
          type: 'success',
        }
      : {
          hidden: true,
        }
  const disabled = false // user.processingBankAccount.status !== 'sentToProcess'
  return (
    <Form formik={formik}>
      <FormItems>
        <Alert {...statusAlertProps} />
        <BankAccountFields formik={formik} compareWith={oldBankAccount} disabled={disabled} />
        <Textarea
          disabled={disabled}
          label="Причина отказа"
          name="rejectComment"
          formik={formik}
          hint={newBankAccount.prevRejectComment && `Прежняя причина: ${newBankAccount.prevRejectComment}`}
        />
        <Alert {...alertProps} />
        <Buttons>
          <Button
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.setFieldValue('action', 'approve')
              setTimeout(() => {
                formik.handleSubmit()
              }, 1)
            }}
          >
            Принять
          </Button>
          <Button
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.setFieldValue('action', 'reject')
              setTimeout(() => {
                formik.handleSubmit()
              }, 1)
            }}
          >
            Отклонить
          </Button>
        </Buttons>
        <Alert {...statusAlertProps} />
      </FormItems>
    </Form>
  )
}
