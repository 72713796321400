import { gql } from '@apollo/client'
import { passportFragment } from './passportFragment.js'

export const getPassportQuery = gql`
  ${passportFragment}
  query ($input: GetPassportInput!) {
    getPassport(input: $input) {
      passport {
        ...passportFragment
        user {
          id
          passport {
            ...passportFragment
          }
        }
      }
    }
  }
`

export const getPassportOptions = {
  parser: [['passport', 'getPassport.passport']],
}
