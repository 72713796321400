import cn from 'classnames'
import Alert from 'components/Alert'
import PageWrapper from 'components/PageWrapper'
import toHumanDate from 'lib/toHumanDate'
import useQuery from 'lib/useQuery'
import { useEffect, useRef, useState } from 'react'
import css from './styles.module.scss'
import { canManageNewsPosts } from 'lib/can'
import { Link } from 'react-router-dom'
import { getNewsPostCreateRoute, getNewsPostEditRoute, getPropertyRoute } from 'lib/routes'
import { openGallery } from 'lib/openGallery'
import getUploadUrl from 'lib/getUploadUrl'
import moment from 'moment'

const stratOfDay = (date) => moment(date).startOf('day').toISOString()

const getNewsByDates = (newsPosts, isMy) => {
  const allPosts = newsPosts.filter((newsPost) => (isMy === null ? true : newsPost.isMy === isMy))
  const stratDayDates = allPosts.map((newsPost) => stratOfDay(newsPost.date))
  const uniqueStartDayDates = stratDayDates.filter((date, i) => stratDayDates.indexOf(date) === i)
  const newsByStartDates = []
  for (const startDayDate of uniqueStartDayDates) {
    const news = allPosts.filter((newsPost) => stratOfDay(newsPost.date) === startDayDate)
    newsByStartDates.push({ date: new Date(startDayDate), news })
  }
  return newsByStartDates
}

const NewsItem = ({ newsPost, me, withDate, withPropertyName }) => {
  const [expanded, setExpanded] = useState(false)
  const outerRef = useRef(null)
  const innerRef = useRef(null)
  useEffect(() => {
    if (!outerRef.current || !innerRef.current || expanded) return
    const outerHeight = outerRef.current.clientHeight
    const innerHeight = innerRef.current.clientHeight
    if (innerHeight <= outerHeight) {
      setExpanded(true)
    }
  }, [expanded])
  const imagesUrls = newsPost.photos.map((p) => getUploadUrl(p, 'large'))
  return (
    <div className={css.newsPost}>
      {withDate && <h3 className={css.newsPostDate}>{toHumanDate(newsPost.date, 'D MMMM YYYY')}</h3>}
      {withPropertyName && (
        <h3 className={css.title}>
          <Link to={getPropertyRoute(newsPost.property.serialNumber)}>{newsPost.property.name}</Link>
        </h3>
      )}
      {!!imagesUrls.length && (
        <p className={css.linkPlace}>
          <Link
            color="blue"
            onClick={(e) => {
              e.preventDefault()
              openGallery({ imagesUrls })
            }}
          >
            Смотреть фотографии
          </Link>
        </p>
      )}
      <div className={cn({ [css.textOuter]: true, [css.expanded]: expanded })} ref={outerRef}>
        <div className={css.text} dangerouslySetInnerHTML={{ __html: newsPost.contentHtml }} ref={innerRef} />
      </div>
      {!expanded && (
        <p className={css.linkPlace}>
          <Link
            color="blue"
            onClick={(e) => {
              e.preventDefault()
              setExpanded(true)
            }}
          >
            Показать полностью
          </Link>
        </p>
      )}
      {canManageNewsPosts(me) && (
        <p className={css.linkPlace}>
          <Link to={getNewsPostEditRoute(newsPost.serialNumber)}>Редактировать</Link>
        </p>
      )}
    </div>
  )
}

export const NewsList = ({ newsPosts, withPropertyName, withDate, me }) => {
  return (
    <div className={css.news}>
      {newsPosts.map((newsPost, i) => (
        <NewsItem
          newsPost={newsPost}
          withPropertyName={withPropertyName}
          withDate={withDate}
          me={me}
          key={newsPost.id}
        />
      ))}
    </div>
  )
}

export const NewsPage = ({ newsPosts, withSeparation = true }) => {
  const { me } = useQuery('getMe')
  const [isMy, setIsMy] = useState(true)
  const myNewsByDates = getNewsByDates(newsPosts, true)
  const otherNewsByDates = getNewsByDates(newsPosts, false)
  const newsByDates = withSeparation ? (isMy ? myNewsByDates : otherNewsByDates) : getNewsByDates(newsPosts, null)
  return (
    <div className={css.wrapper}>
      {!!withSeparation && (
        <div className={css.switcher}>
          <button onClick={() => setIsMy(true)} className={cn({ [css.switcherItem]: true, [css.active]: isMy })}>
            Мои объекты
          </button>
          <button onClick={() => setIsMy(false)} className={cn({ [css.switcherItem]: true, [css.active]: !isMy })}>
            Остальные
          </button>
          {canManageNewsPosts(me) && (
            <Link to={getNewsPostCreateRoute()} className={cn({ [css.switcherItem]: true, [css.active]: false })}>
              Создание новости
            </Link>
          )}
        </div>
      )}
      {!!newsByDates.length ? (
        <div className={css.newsAndDates}>
          {newsByDates.map((newsByDate, i) => (
            <div className={css.newsAndDate}>
              <div className={css.date}>{toHumanDate(newsByDate.date, 'D MMMM YYYY')}</div>
              <NewsList me={me} newsPosts={newsByDate.news} withPropertyName />
            </div>
          ))}
        </div>
      ) : (
        <Alert message="В этом разделе пока нет новостей" />
      )}
    </div>
  )
}

const GetNewsWrapper = (props) => {
  const queryResult = useQuery('getNewsPosts', {
    fetchPolicy: 'network-only',
  })
  return (
    <PageWrapper queryResult={queryResult}>
      {() => <NewsPage newsPosts={queryResult.newsPosts} {...props} />}
    </PageWrapper>
  )
}

export default GetNewsWrapper
