import cn from 'classnames'
import Button, { Buttons, ButtonWrapper } from 'components/Button'
import getUploadUrl from 'lib/getUploadUrl'
import useMutation from 'lib/useMutation'
import _ from 'lodash'
import { useRef, useState } from 'react'
import css from './styles.module.scss'

const UploadGenDocData = ({ label, formik, name, hint, disabled, accept = 'docx', setDownloadLink, genDocTpl }) => {
  const inputEl = useRef()
  const [loading, setLoading] = useState(false)
  const [uploadGenDocData] = useMutation('uploadGenDocData')
  const error = _.get(formik.errors, name)
  const value = _.get(formik.values, name)
  return (
    <div className={cn({ [css.uploadGenDocData]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      <div className={css.buttons}>
        <Buttons>
          <ButtonWrapper>
            <label className={css.uploadButton}>
              <input
                className={css.fileInput}
                type="file"
                disabled={loading || disabled}
                accept={accept}
                ref={(el) => (inputEl.current = el)}
                onChange={async ({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => {
                  formik.setFieldError(name, null)
                  setDownloadLink('')
                  setLoading(true)
                  try {
                    const mutationResult = await uploadGenDocData({
                      variables: {
                        input: { file, genDocTplId: genDocTpl.id, sendToSigneasy: formik.values.sendToSigneasy },
                      },
                    })
                    setDownloadLink(getUploadUrl(mutationResult.data.uploadGenDocData.genDocArchiveFile.path, 'file'))
                  } catch (err) {
                    console.error(err)
                    formik.setFieldError(name, err.message)
                  } finally {
                    formik.setFieldTouched(name, true, false)
                    setLoading(false)
                    inputEl.current.value = ''
                  }
                }}
              />
              <Button type="button" loading={loading} disabled={loading || disabled} color="outlined" asSpan>
                {value ? 'Загрузить другой CSV файл' : 'Загрузить CSV файл'}
              </Button>
            </label>
          </ButtonWrapper>
        </Buttons>
      </div>
      {!!error && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default UploadGenDocData
