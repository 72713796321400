import cn from 'classnames'
import Alert from 'components/Alert'
import Button from 'components/Button'
import Button1 from 'components/Button1'
import Form, { FormItems, useForm } from 'components/Form'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Modal from 'components/Modal'
import { copyToClipboard } from 'lib/copyToClipboard'
import getUploadUrl from 'lib/getUploadUrl'
import parseLocationSearch from 'lib/parseLocationSearch'
import toMoney from 'lib/toMoney'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'
import QRCode from 'qrcode'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import css from './styles.module.scss'

export const useNewPaymentModal = () => {
  const history = useHistory()
  const location = useLocation()
  const openNewPaymentModal = ({ propertySerialNumber = null, sum = '' } = {}) => {
    const search = parseLocationSearch(location.search)
    search.modal = 'new-payment'
    search.propertySerialNumber = propertySerialNumber
    search.sum = sum
    const searchStr = Object.keys(search)
      .map((key) => `${key}=${search[key]}`)
      .join('&')
    const newPath = `${location.pathname}?${searchStr}`
    history.replace(newPath)
  }
  return { openNewPaymentModal }
}

const Instructions = ({ title, desc }) => {
  return (
    <div className={css.instructions}>
      <div className={css.item}>
        <div className={css.number}>1</div>
        <h3 className={css.title}>{title}</h3>
        <p className={css.desc}>{desc}</p>
        {/* <Button1 className={css.button} size="small" color="white-outline" onClick={() => }>
                    Подробная инструкция
                  </Button1> */}
      </div>
      <div className={css.item}>
        <div className={css.number}>2</div>
        <h3 className={css.title}>Отправьте квитанцию</h3>
        <p className={css.desc}>Сохраните и отправьте квитанцию платежа в Telegram-чат c нашей поддержкой</p>
        <div className={css.buttonPlace}>
          <Button1
            className={css.button}
            icon="telegram1"
            size="small"
            color="orange"
            target="_blank"
            href="https://t.me/Estate_invest_RF_bot"
          >
            Отправить квитанцию в телеграм
          </Button1>
        </div>
      </div>
    </div>
  )
}

const BackButton = ({ prevStep, setStep }) => {
  return null
  // return (
  //   <div className={css.backButton}>
  //     <Button1
  //       size="small"
  //       color="white-outline"
  //       icon="SliderLeftArrow"
  //       onClick={() => {
  //         setStep(prevStep)
  //       }}
  //     >
  //       Назад
  //     </Button1>
  //   </div>
  // )
}

export const sbigintFromFullHumanAmount = (value) => {
  return value.replace(',', '')
}

export const sbigintFromAnyHumanAmount = (value) => {
  const unspacedValue = value.replace(/\s/g, '')
  if (unspacedValue.match(/^-?\d+,\d\d$/)) {
    return sbigintFromFullHumanAmount(unspacedValue)
  }
  if (unspacedValue.match(/^-?\d+,\d$/)) {
    return sbigintFromFullHumanAmount(`${unspacedValue}0`)
  }
  if (unspacedValue.match(/^-?\d+,$/)) {
    return sbigintFromFullHumanAmount(`${unspacedValue}00`)
  }
  if (unspacedValue.match(/^-?\d+$/)) {
    return sbigintFromFullHumanAmount(`${unspacedValue},00`)
  }
  return null
}

const isAnyHumanAmountValid = (value) => {
  return /^\s*\d(\s*\d*)*(,\d{0,2})?$/.test(value)
}

export const parseAnyHumanAmount = (value) => {
  if (!isAnyHumanAmountValid(value)) {
    return {
      valid: false,
      float: null,
      sbigint: null,
    }
  }
  const sbigint = sbigintFromAnyHumanAmount(value)
  const float = parseFloat(sbigint) / 100
  return {
    valid: true,
    float,
    sbigint,
  }
}

export const NewPaymentModal = ({ me, dashboardData }) => {
  const canInvest = me && !!me.contractNumber && !!me.contractDate
  const queryResult = useQuery('getProperties', {
    fetchPolicy: 'network-only',
  })
  const properties = (queryResult.properties || []).filter((property) => property.status === 'foundrising')
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const location = useLocation()
  const parsedLocationSearch = parseLocationSearch(location.search)
  const [propertySerialNumber, setPropertySerialNumber] = useState(null)
  const property = properties.find((property) => `${property.serialNumber}` === propertySerialNumber)
  const [step, setStep] = useState('start')
  const [sum, setSum] = useState('')
  const { formik, buttonProps, alertProps, reset } = useForm({
    // forgetSuccessDelay: 0,
    initialValues: {
      sum,
    },
    onSubmit: ({ sum }) => {
      const resultSum = parseAnyHumanAmount(sum)
      if (!resultSum.valid) {
        throw new Error('Invalid sum')
      }
      const stringSum = resultSum.float.toFixed(2)
      setSum(stringSum)
      setStep('qr')
    },
    validate: (values) => {
      const errors = {}
      if (!values.sum) {
        errors.sum = 'Обязательное поле'
        return errors
      }
      const result = parseAnyHumanAmount(values.sum)
      if (!result.valid) {
        errors.sum = 'Должно быть положительным числом с двумя знаками после запятой'
        return errors
      }
      const minInvestmentSum = property?.minInvestmentSum || 1000
      if (result.float < minInvestmentSum) {
        errors.sum = `Сумма должна быть не менее ${toMoney(minInvestmentSum, 'rub')}`
        return errors
      }
      const maxInvestmentSum = property?.maxInvestmentSum || 0
      if (maxInvestmentSum !== 0 && result.float > maxInvestmentSum) {
        errors.sum = `Максимальная сумма инвестирования в этот объект ${toMoney(
          maxInvestmentSum,
          'rub'
        )} для каждого отдельного инвестора`
        return errors
      }
      return errors
    },
    successMessage: false,
    showValidationAlert: false,
  })
  const [investFromFree] = useMutation('investFromFree')
  const form1 = useForm({
    // forgetSuccessDelay: 0,
    initialValues: {
      sum,
    },
    onSubmit: ({ sum }) => {
      const resultSum = parseAnyHumanAmount(sum)
      if (!resultSum.valid) {
        throw new Error('Invalid sum')
      }
      const floatSum = resultSum.float
      const stringSum = resultSum.float.toFixed(2)
      return investFromFree({
        variables: {
          input: {
            amount: floatSum,
            currency: 'rub',
            properySerialNumber: property?.serialNumber?.toString() || '',
            properyName: property?.name || '',
          },
        },
      }).then(() => {
        setSum(stringSum)
        setStep('freeSuccess')
      })
    },
    // validationSchema: yup.object({
    //   sum: yup.number().min(1000, 'Сумма должна быть не менее 1 000 ₽').max(dashboardData.moneyFree.rub + 0.5, 'Сумма не должна превышать свободный баланс').required(),
    // }),
    validate: (values) => {
      const errors = {}
      if (!values.sum) {
        errors.sum = 'Обязательное поле'
        return errors
      }
      const result = parseAnyHumanAmount(values.sum)
      if (!result.valid) {
        errors.sum = 'Должно быть положительным числом с двумя знаками после запятой'
        return errors
      }
      const minInvestmentSum = property?.minInvestmentSum || 1000
      if (result.float < minInvestmentSum) {
        errors.sum = `Сумма должна быть не менее ${toMoney(minInvestmentSum, 'rub')}`
        return errors
      }
      const maxInvestmentSum = property?.maxInvestmentSum || 0
      if (maxInvestmentSum !== 0 && result.float > maxInvestmentSum) {
        errors.sum = `Максимальная сумма инвестирования в этот объект ${toMoney(
          maxInvestmentSum,
          'rub'
        )} для каждого отдельного инвестора`
        return errors
      }
      if (result.float > dashboardData.moneyFree.rub + 0.5) {
        errors.sum = 'Сумма не должна превышать свободный баланс'
        return errors
      }
      return errors
    },
    successMessage: false,
    showValidationAlert: false,
  })
  const { minRoi, maxRoi } = (() => {
    if (!property) {
      return { minRoi: 0, maxRoi: 0 }
    }
    const roiParts = property?.roi?.split(/[-–—]/) || []
    if (roiParts.length === 0) {
      return { minRoi: 0, maxRoi: 0 }
    }
    if (roiParts.length === 1) {
      return { minRoi: parseFloat(roiParts[0]) / 100, maxRoi: 0 }
    }
    if (roiParts.length === 2) {
      return { minRoi: parseFloat(roiParts[0]) / 100, maxRoi: parseFloat(roiParts[1]) / 100 }
    }
    return { minRoi: 0, maxRoi: 0 }
  })()
  const roinHint = (() => {
    const sum = parseFloat(formik.values.sum.replaceAll(' ', ''))
    if (!sum || isNaN(sum)) {
      return ''
    }
    if (formik.errors.sum) {
      return ''
    }
    if (!minRoi && !maxRoi) {
      return ''
    }
    if (minRoi && !maxRoi) {
      return `Ожидаемый доход от ${toMoney(minRoi * sum, 'rub')} `
    }
    if (minRoi && maxRoi) {
      // return `Ожидаемый доход от ${ toMoney(minRoi * sum, 'rub') } до ${ toMoney(maxRoi * sum, 'rub') } `
      return `Ожидаемый доход от ${toMoney(minRoi * sum, 'rub')} `
    }
  })()
  useEffect(() => {
    if (parsedLocationSearch.modal === 'new-payment') {
      setModalOpen(true)
      setPropertySerialNumber(parsedLocationSearch.propertySerialNumber || null)
      setSum(parsedLocationSearch.sum || '')
      setModalOpen(true)
      // history.replace(location.pathname)
    } else {
      setModalOpen(false)
      setStep('start')
      setSum('')
      setPropertySerialNumber(null)
      reset()
    }
  }, [JSON.stringify(parsedLocationSearch)])

  const close = () => {
    history.replace(location.pathname)
  }
  const staticPaymentDataWithProperty = {
    Name: 'МУДРОВ ИВАН ЮРЬЕВИЧ (ИП)',
    LastName: 'Мудров',
    FirstName: 'Иван',
    MiddleName: 'Юрьевич',
    PersonalAcc: '40802810529210005339',
    BankName: 'ФИЛИАЛ "НИЖЕГОРОДСКИЙ" АО "АЛЬФА-БАНК"',
    BIC: '042202824',
    KPP: null,
    CorrespAcc: '30101810200000000824',
    PayeeINN: '590610248187',
  }
  const staticPaymentDataWithOutProperty = {
    Name: 'МУДРОВ ИВАН ЮРЬЕВИЧ (ИП)',
    LastName: 'Мудров',
    FirstName: 'Иван',
    MiddleName: 'Юрьевич',
    PersonalAcc: '40802810529210005339',
    BankName: 'ФИЛИАЛ "НИЖЕГОРОДСКИЙ" АО "АЛЬФА-БАНК"',
    BIC: '042202824',
    KPP: null,
    CorrespAcc: '30101810200000000824',
    PayeeINN: '590610248187',
  }
  const getPaymentData = ({ sum = 0, property }) => {
    return {
      ...(property ? staticPaymentDataWithProperty : staticPaymentDataWithOutProperty),
      Sum: Math.round((sum ? Number(sum) : 0) * 100),
      Purpose: property
        ? `Оплата инвестиционного предложения №${property.serialNumber} на основании инвестиционного договора №${me.contractNumber} от ${me.contractDate} БЕЗ НДС`
        : `Оплата свободного баланса по инвестиционному договору №${me.contractNumber} от ${me.contractDate} БЕЗ НДС`,
    }
  }
  const paymentDataLabels = {
    BIC: 'БИК банка получателя',
    BankName: 'Название банка',
    PersonalAcc: 'Счет получателя',
    CorrespAcc: 'Корр. счет',
    PayeeINN: 'ИНН получателя',
    Name: 'Получатель',
    KPP: 'КПП',
    Nds: 'НДС',
    Purpose: 'Назначение платежа',
    Sum: 'Сумма инвестирования',
  }
  const paymentData = getPaymentData({ sum, property })
  const paymentDataWithNds = {
    ...paymentData,
    Nds: 'НДС не облагается',
  }
  const visibleRequisitesKeys = [
    'BIC',
    'BankName',
    'PersonalAcc',
    'CorrespAcc',
    'PayeeINN',
    'Name',
    'KPP',
    'Nds',
    'Purpose',
  ]
  const allRequistesAsText = visibleRequisitesKeys
    .map((key) => `${paymentDataLabels[key]}:\n${paymentDataWithNds[key]}`)
    .join('\n\n')
  const [qrCodeUrl, setQrCodeUrl] = useState(null)
  const paymentString = Object.keys(paymentData)
    .map((key) => `${key}=${paymentData[key]}`)
    .join('|')
  useEffect(() => {
    if (paymentString && step === 'qr') {
      QRCode.toDataURL(`ST00012|${paymentString}`)
        .then((url) => {
          setQrCodeUrl(url)
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      setQrCodeUrl(null)
    }
  }, [paymentString, step])
  return (
    <Modal isOpen={modalOpen} onRequestClose={() => close()}>
      {queryResult.loading ? (
        <div>Загрузка...</div>
      ) : !canInvest ? (
        <div>
          Чтобы начать инвестировать необходимо подписать договор. Если вы видите это сообщение, но договор уже
          подписан, пожалуйста, обратитесь{' '}
          <a className={css.link} href="https://t.me/Estate_invest_RF_bot" target="_blank" rel="noreferrer">
            в чат поддержки
          </a>
        </div>
      ) : (
        <div className={css.modal}>
          {step === 'start' && (
            <>
              {!property ? (
                <div className={css.stepHeader}>
                  <img
                    className={cn(css.pic, css.iconPic)}
                    src="/images/new-payment.png"
                    width="218"
                    height="140"
                    alt=""
                  />

                  <h2 className={css.title}>Пополнение баланса</h2>
                  <p className={css.desc}>
                    Чтобы пополнить баланс, сделайте платеж по реквизитам в вашем банковском приложении
                  </p>
                </div>
              ) : (
                <div className={css.stepHeader}>
                  <img
                    className={cn(css.pic, css.propertyPic)}
                    src={getUploadUrl(property.photo)}
                    width="312"
                    height="180"
                    alt=""
                  />
                  <h2 className={css.title}>
                    №{property.serialNumber}. {property.name}
                  </h2>
                  <p className={css.desc}>
                    Чтобы инвестировать в объект, сделайте платеж по реквизитам в вашем банковском приложении или
                    инвестируйте со свободного баланса
                  </p>
                </div>
              )}
              <div className={css.directionMenu}>
                <div className={css.item} onClick={() => setStep('sum')}>
                  <div className={css.header}>
                    <Icon name="np-qr-icon" className={css.icon} />
                    <span className={css.title}>Сгенерировать QR-код</span>
                    <Icon name="np-right-arrow-icon" className={css.rightArrow} />
                  </div>
                  <div className={css.desc}>
                    Отсканировав QR-код, все реквизиты и сумма пополнения подставятся автоматически, останется только
                    нажать кнопку «Оплатить»
                  </div>
                </div>
                <div className={css.item} onClick={() => setStep('manual')}>
                  <div className={css.header}>
                    <Icon name="np-doc-icon" className={css.icon} />
                    <span className={css.title}>Буду переводить вручную</span>
                    <Icon name="np-right-arrow-icon" className={css.rightArrow} />
                  </div>
                  <div className={css.desc}>Если вы хотите вбить все реквизиты самостоятельно</div>
                </div>
                {!!property && (
                  <div className={css.item} onClick={() => setStep('freeSum')}>
                    <div className={css.header}>
                      <Icon name="np-doc-icon" className={css.icon} />
                      <span className={css.title}>Инвестировать со свободного баланса</span>
                      <Icon name="np-right-arrow-icon" className={css.rightArrow} />
                    </div>
                    {/* <div className={css.desc}>Если вы хотите вбить все реквизиты самостоятельно</div> */}
                  </div>
                )}
              </div>
            </>
          )}
          {step === 'manual' && (
            <>
              <div className={css.requisites}>
                <div className={css.header}>
                  <h2 className={css.title}>Реквизиты</h2>
                  <button className={css.copy} onClick={() => copyToClipboard(allRequistesAsText)}>
                    Скопировать все
                  </button>
                </div>
                <div className={css.items}>
                  {visibleRequisitesKeys.map((key) =>
                    paymentDataWithNds[key] ? (
                      <div key={key} className={css.item} onClick={() => copyToClipboard(paymentDataWithNds[key])}>
                        <div className={css.left}>
                          <div className={css.label}>{paymentDataLabels[key]}</div>
                          <div className={css.value}>{paymentDataWithNds[key]}</div>
                        </div>
                        <div className={css.right}>
                          <Icon name="np-copy-icon" />
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
              <Instructions
                title="Совершите перевод в своем банковском приложении"
                desc="В вашем банковском приложении найдите переводы по реквизитам, введите все реквизиты, которые вы скопировали, и сумму пополнения. Затем совершите перевод"
              />
              <BackButton setStep={setStep} prevStep="start" />
            </>
          )}
          {step === 'sum' && (
            <>
              {!!property && (
                <div className={css.sumProperty}>
                  <Icon name="np-property-icon" className={css.icon} />
                  <div className={css.title}>
                    №{property.serialNumber}. {property.name}
                  </div>
                </div>
              )}
              <Form formik={formik}>
                <FormItems>
                  <Input
                    fullWidth={true}
                    // type="number"
                    mask={Number}
                    thousandsSeparator={' '}
                    label="Сумма инвестиции в рублях"
                    name="sum"
                    formik={formik}
                    hint={roinHint}
                  />
                  <div className={css.sumInfo}>
                    <Icon name="np-info-icon" className={css.icon} />
                    <div className={css.desc}>
                      Укажите сумму пополнения, и она автоматически подставится после сканирования вместе со всеми
                      реквизитами
                    </div>
                  </div>
                  <Alert {...alertProps} />
                  <Button style={{ width: '100%' }} {...buttonProps}>
                    Сгенерировать QR-код
                  </Button>
                </FormItems>
              </Form>
              <BackButton setStep={setStep} prevStep="start" />
            </>
          )}
          {step === 'qr' && (
            <>
              <div className={css.stepHeader}>
                <h2 className={css.title}>QR-код для платежа</h2>
                <p className={css.desc}>Сделайте скриншот экрана, чтобы сохранить изображение с QR-кодом</p>
                <img className={css.bottomPicQr} src={qrCodeUrl} alt="" />
              </div>
              <Instructions
                title="Отсканируйте QR-код в вашем банковском приложении"
                desc="В вашем банковском приложении найдите переводы по реквизитам и нажмите значок c QR-кодом, отсканируйте или загрузите изображение с QR-кодом"
              />
              <BackButton setStep={setStep} prevStep="sum" />
            </>
          )}
          {step === 'freeSum' && (
            <>
              <div className={css.sumInfo}>
                <Icon name="np-info-icon" className={css.icon} />
                <div className={css.desc}>Укажите сумму инвестирования</div>
              </div>
              {!!property && (
                <div className={css.sumProperty}>
                  <Icon name="np-property-icon" className={css.icon} />
                  <div className={css.title}>
                    №{property.serialNumber}. {property.name}
                  </div>
                </div>
              )}
              <Form formik={form1.formik}>
                <FormItems>
                  <Input
                    fullWidth={true}
                    // type="number"
                    mask={Number}
                    thousandsSeparator={' '}
                    label="Сумма инвестиции в рублях"
                    name="sum"
                    hint={`Максимум ${toMoney(dashboardData.moneyFree.rub, 'rub')}`}
                    formik={form1.formik}
                  />
                  <Alert {...form1.alertProps} />
                  <Button style={{ width: '100%' }} {...form1.buttonProps}>
                    Инвестировать
                  </Button>
                </FormItems>
              </Form>
              <BackButton setStep={setStep} prevStep="start" />
            </>
          )}
          {step === 'freeSuccess' && (
            <>
              <div className={css.stepHeader}>
                <img
                  className={cn(css.pic, css.iconPic)}
                  src="/images/new-payment.png"
                  width="218"
                  height="140"
                  alt=""
                />
                <h2 className={css.title}>Заявка на инвестирование создана</h2>
                <p className={css.desc}>Спасибо! Скоро с вами свяжется наш менеджер</p>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  )
}
