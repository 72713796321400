import PageWrapper from 'components/PageWrapper'
import { useEffect } from 'react'

export const NotionFuturePropertiesPage = ({ me, history }) => {
  useEffect(() => {
    window.location.replace('https://estateinvest.notion.site/b038c742cec048e79a3de7115e072d2a')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <PageWrapper queryResult={{ loading: true }} />
}
