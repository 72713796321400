import { gql } from '@apollo/client'

export const confirmNewEmailMutation = gql`
  mutation($input: ConfirmNewEmailInput!) {
    confirmNewEmail(input: $input) {
      token
      user {
        id
        email
        emailConfirmed
      }
    }
  }
`
