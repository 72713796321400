import { gql } from '@apollo/client'

export const uploadGenDocDataMutation = gql`
  mutation ($input: UploadGenDocDataInput!) {
    uploadGenDocData(input: $input) {
      genDocArchiveFile {
        path
      }
    }
  }
`

export const uploadGenDocDataOptions = {
  parser: [['genDocArchiveFile', 'uploadGenDocData.genDocArchiveFile']],
}
