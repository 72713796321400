import errorCodes from 'lib/errorCodes'
import _ from 'lodash'

const prettifyError = (error, extraErrorCodes = {}) => {
  let message = _.get(error, 'graphQLErrors[0].message', 'ERR_UNKNOWN')
  if (message === 'ERR_UNKNOWN') message = _.get(error, 'networkError.result.errors[0].message', 'ERR_UNKNOWN')
  if (message === 'ERR_UNKNOWN') message = _.get(error, 'message', 'ERR_UNKNOWN')
  const foundedHumanMessage = extraErrorCodes[message] || errorCodes[message]
  const isUnknown = !foundedHumanMessage
  const humanMessage = foundedHumanMessage || extraErrorCodes.ERR_UNKNOWN || errorCodes.ERR_UNKNOWN
  Object.assign(error, {
    message,
    humanMessage,
    isUnknown,
  })
  return error
}

export default prettifyError
