import { gql } from '@apollo/client'

export const signInMutation = gql`
  mutation($input: SignInInput!) {
    signIn(input: $input) {
      token
      user {
        id
      }
    }
  }
`

export const signInOptions = { parser: [['token', 'signIn.token'], ['user', 'signIn.user']] }
