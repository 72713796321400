import React, { useState, useRef } from 'react'
import css from './styles.module.scss'
import useMutation from 'lib/useMutation'
import getUploadUrl from 'lib/getUploadUrl'
import getUploadName from 'lib/getUploadName'
import Link from 'components/Link'
import Button, { Buttons, ButtonWrapper } from 'components/Button'
import cn from 'classnames'
import _ from 'lodash'

const UploadSingleFile = ({ label, formik, name, hint, disabled, accept = '*' }) => {
  const inputEl = useRef()
  const [loading, setLoading] = useState(false)
  const [uploadSingleFile] = useMutation('uploadSingleFile')
  const error = _.get(formik.errors, name)
  const value = _.get(formik.values, name)
  const touched = _.get(formik.touched, name)
  return (
    <div className={cn({ [css.uploadSingleFile]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      {!!value && !loading && (
        <div className={css.previewPlace}>
          <Link color="system-links-blue" target="_blank" href={getUploadUrl(value, 'file')}>
            {getUploadName(value)}
          </Link>
        </div>
      )}
      <div className={css.buttons}>
        <Buttons>
          <ButtonWrapper>
            <label className={css.uploadButton}>
              <input
                className={css.fileInput}
                type="file"
                disabled={loading || disabled}
                accept={accept}
                ref={(el) => (inputEl.current = el)}
                onChange={async ({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => {
                  setLoading(true)
                  try {
                    const { uploadedFile } = await uploadSingleFile({
                      variables: { input: { file } },
                    })
                    formik.setFieldValue(name, uploadedFile.path)
                  } catch (err) {
                    console.error(err)
                    formik.setFieldError(name, err.humanMessage || err.message)
                  } finally {
                    formik.setFieldTouched(name, true, true)
                    setLoading(false)
                    inputEl.current.value = ''
                  }
                }}
              />
              <Button type="button" loading={loading} disabled={loading || disabled} color="outlined" asSpan>
                {value ? 'Загрузить другой' : 'Загрузить'}
              </Button>
            </label>
          </ButtonWrapper>
          {!!value && !loading && (
            <Button
              type="button"
              color="outlined-red"
              onClick={() => {
                formik.setFieldValue(name, '')
                formik.setFieldError(name, false)
                formik.setFieldTouched(name, true, true)
              }}
              disabled={disabled}
            >
              Удалить
            </Button>
          )}
        </Buttons>
      </div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default UploadSingleFile
