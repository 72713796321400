import { gql } from '@apollo/client'

export const investFromFreeMutation = gql`
  mutation($input: InvestFromFreeInput!) {
    investFromFree(input: $input) {
      ok
    }
  }
`

export const investFromFreeOptions = { parser: [['ok', 'investFromFree.ok']] }
