import { gql } from '@apollo/client'

export const uploadSingleImageMutation = gql`
  mutation($input: UploadSingleImageInput!) {
    uploadSingleImage(input: $input) {
      uploadedFile {
        name
      }
    }
  }
`

export const uploadSingleImageOptions = {
  parser: [['uploadedFile', 'uploadSingleImage.uploadedFile']],
}
