import { gql } from '@apollo/client'
import { passportFragment } from './passportFragment'

export const sendPassportToProcessMutation = gql`
  ${passportFragment}
  mutation ($input: SendPassportToProcessInput!) {
    sendPassportToProcess(input: $input) {
      me {
        id
        processingPassport {
          ...passportFragment
        }
      }
    }
  }
`

export const sendPassportToProcessOptions = { parser: [['me', 'sendPassportToProcess.me']] }
