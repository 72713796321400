import cn from 'classnames'
import Alert from 'components/Alert'
import Icon from 'components/Icon'
import Loader from 'components/Loader'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canManageUserBankAccounts } from 'lib/can'
import { getBankAccountRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import css from './styles.module.scss'

const BankAccountsListItem = ({ bankAccount }) => {
  const history = useHistory()
  const status =
    bankAccount.status === 'accepted' || bankAccount.status === 'rejected' ? 'Данные проверены' : 'Ожидает проверки'
  return (
    <>
      <div className={cn(css.row, css.clickable)} onClick={() => history.push(getBankAccountRoute(bankAccount.id))}>
        <div className={css.cell}>{bankAccount.user.displayName}</div>
        <div className={css.cell}>{bankAccount.user.email}</div>
        <div className={css.cell}>{bankAccount.user.phone}</div>
        <div className={css.cell}>{status}</div>
      </div>
      <div className={cn(css.card, css.clickable)} onClick={() => history.push(getBankAccountRoute(bankAccount.id))}>
        {/*<div className={css.cardHeader}>*/}
        {/*  <div className={css.number}>{user.serialNumber}</div>*/}
        {/*</div>*/}
        {bankAccount.user.displayName && <div className={css.title}>{bankAccount.user.displayName}</div>}
        <div className={css.info}>
          {bankAccount.user.email && (
            <div className={css.infoItem}>
              <div className={css.icon}>
                <Icon name="info" />
              </div>
              <div className={css.text}>{status}</div>
            </div>
          )}
          {bankAccount.user.email && (
            <div className={css.infoItem}>
              <div className={css.icon}>
                <Icon name="mail" />
              </div>
              <div className={css.text}>{bankAccount.user.email}</div>
            </div>
          )}
          {bankAccount.user.phone && (
            <div className={css.infoItem}>
              <div className={css.icon}>
                <Icon name="phone" />
              </div>
              <div className={css.text}>{bankAccount.user.phone}</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const BankAccountsList = ({ me }) => {
  const { bankAccounts, pageInfo, loading, error, fetchMore } = useQuery('getBankAccounts', {
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!bankAccounts.length) return <Alert message="Нету активных заявок на установку реквизитов" />
  return (
    <div className={css.bankAccounts}>
      <div className={css.header}>
        <div className={css.row}>
          <div className={css.cell}>Пользователь</div>
          <div className={css.cell}>Эл. почта</div>
          <div className={css.cell}>Телефон</div>
          <div className={css.cell}>Статус</div>
        </div>
      </div>
      <div className={css.items}>
        <InfiniteScroll
          threshold={250}
          loadMore={async () => {
            return fetchMore({
              variables: {
                input: {
                  endCursor: pageInfo.endCursor,
                  limit: 30,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return {
                  getBankAccounts: {
                    ...fetchMoreResult.getBankAccounts,
                    bankAccounts: [
                      ...prev.getBankAccounts.bankAccounts,
                      ...fetchMoreResult.getBankAccounts.bankAccounts,
                    ],
                  },
                }
              },
            })
          }}
          hasMore={pageInfo.hasNextPage}
          loader={
            <div className={css.loader} key="loader">
              <Loader type="section" />
            </div>
          }
        >
          {bankAccounts.map((bankAccount) => (
            <BankAccountsListItem key={bankAccount.id} bankAccount={bankAccount} me={me} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

const BankAccountsPage = () => {
  return (
    <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canManageUserBankAccounts(me)}>
      {() => (
        <Segment title="Заявки на установку реквизитов">
          <BankAccountsList />
        </Segment>
      )}
    </PageWrapper>
  )
}

export default BankAccountsPage
