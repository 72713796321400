import { gql } from '@apollo/client'

export const userFragment = gql`
  fragment userFragment on User {
    id
    contractDate
    contractNumber
    displayName
    createdAt
    name
    email
    phone
    permissions
    presentation
    generatedPresentation
    dummyReferralUrl
    dummyReferrals
    dummyReferrer
    documents
    referralCode
    referralsCount
    lastSignInAt
    serialNumber
    deletedAt
    apiKey
    processingPassport {
      id
      fullName
      seriesAndNumber
      dateOfIssue
      registration
      issuedBy
      departmentCode
      dateOfBirth
      placeOfBirth
      passportScan1
      passportScan2
      sentToProcessByUserAt
      rejectedByManagerAt
      status
      rejectComment
    }
    passport {
      id
      fullName
      seriesAndNumber
      dateOfIssue
      registration
      issuedBy
      departmentCode
      dateOfBirth
      placeOfBirth
      passportScan1
      passportScan2
      acceptedByManagerAt
      sentToProcessByUserAt
    }
    processingBankAccount {
      id
      bankName
      bankBik
      corrAccount
      benAccount
      fullName
      sentToProcessByUserAt
      rejectedByManagerAt
      status
      rejectComment
    }
    bankAccount {
      id
      bankName
      bankBik
      corrAccount
      benAccount
      fullName
      acceptedByManagerAt
      sentToProcessByUserAt
    }
  }
`
