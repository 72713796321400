import Link from 'components/Link'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import useQuery from 'lib/useQuery'
import { useState } from 'react'
import css from './styles.module.scss'
import { HaveQuestions } from 'pages/DashboardPage'

const Question = ({ question }) => {
  const [visible, setVisible] = useState(false)
  const anserText = question.answer
  // replace all <bot>...</bot> with <a href="https://t.me/Estate_invest_RF_bot" target="_blank">...</a>
  const answerTextWithSupportLinks = anserText.replace(
    /<bot>(.*?)<\/bot>/g,
    '<a href="https://t.me/Estate_invest_RF_bot" target="_blank">$1</a>'
  )
  return (
    <div className={css.question}>
      <h4 className={css.questionTitle}>
        <Link
          className={css.link}
          type="dashed"
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setVisible(!visible)
          }}
        >
          {question.question}
        </Link>
      </h4>
      {visible && (
        <div className={css.questionAnswer} dangerouslySetInnerHTML={{ __html: answerTextWithSupportLinks }}></div>
      )}
    </div>
  )
}

const FaqPage = ({ sections }) => {
  return (
    <>
      <Segment title={`FAQ`}>
        <div className={css.sections}>
          {sections.map((section, i) => (
            <div className={css.section} key={i}>
              <h3 className={css.sectionTitle}>{section.name}</h3>
              <div className={css.questions}>
                {section.questions.map((question, i) => (
                  <Question key={i} question={question} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </Segment>
      <div className={css.haveQuestions}>
        <HaveQuestions />
      </div>
    </>
  )
}

const GetFaqWrapper = (props) => {
  const queryResult = useQuery('getFaq', {
    fetchPolicy: 'network-only',
  })
  return (
    <PageWrapper queryResult={queryResult}>{() => <FaqPage sections={queryResult.sections} {...props} />}</PageWrapper>
  )
}

export default GetFaqWrapper
