import { gql } from '@apollo/client'
import { propertyFragment } from './propertyFragment'

export const updatePropertyMutation = gql`
  ${propertyFragment}
  mutation ($input: UpdatePropertyInput!) {
    updateProperty(input: $input) {
      property {
        ...propertyFragment
      }
    }
  }
`

export const updatePropertyOptions = { parser: [['property', 'updateProperty.property']] }
