import Alert from 'components/Alert'
import Link from 'components/Link'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canManageUserBankAccounts } from 'lib/can'
import { getUserRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'
import React from 'react'
import { BankAccountValidation } from '../../components/BankAccount/index.js'
import css from './styles.module.scss'

const BankAccountPage = ({ bankAccount }) => {
  return (
    <Segment
      title={
        <>
          Заявка на установку реквизитов{' '}
          <Link color="system-links-blue" to={getUserRoute(bankAccount.user.serialNumber)}>
            от {bankAccount.user.displayName}
          </Link>
        </>
      }
    >
      {bankAccount.user.deletedAt && (
        <div className={css.deletedAlert}>
          <Alert message="Пользователь удалён" type="danger" />
        </div>
      )}
      <BankAccountValidation newBankAccount={bankAccount} oldBankAccount={bankAccount.user.bankAccount} />
    </Segment>
  )
}

const GetUserWrapper = (props) => {
  const bankAccountId = props.match.params.bankAccountId
  const queryResult = useQuery('getBankAccount', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        bankAccountId,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ bankAccount }) => !!bankAccount}>
      {({ bankAccount }) => <BankAccountPage bankAccount={bankAccount} {...props} />}
    </PageWrapper>
  )
}

const CheckAuthWrapper = (props) => (
  <PageWrapper redirerctUnauthorized checkAccess={({ me }) => canManageUserBankAccounts(me)}>
    {({ me }) => <GetUserWrapper me={me} {...props} />}
  </PageWrapper>
)

export default CheckAuthWrapper
