import { gql } from '@apollo/client'
import { userFragment } from './userFragment'

export const getUsersQuery = gql`
  ${userFragment}
  query getUsers($input: GetUsersInput) {
    getUsers(input: $input) {
      total
      users {
        ...userFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const getUsersOptions = {
  parser: [
    ['total', 'getUsers.total'],
    ['users', 'getUsers.users'],
    ['pageInfo', 'getUsers.pageInfo'],
  ],
}
