import { gql } from '@apollo/client'

export const getSettingsQuery = gql`
  query {
    getSettings {
      _
    }
  }
`

export const getSettingsOptions = {
  parser: [['settings', 'getSettings']],
}
