import Button, { Buttons } from 'components/Button'
import Link from 'components/Link'
import PageWrapper from 'components/PageWrapper'
import RichText from 'components/RichText'
import Segment from 'components/Segment'
import { canManageProperties } from 'lib/can'
import { getPropertyEditRoute } from 'lib/routes'
import { Link as NativeLink } from 'react-router-dom'
import toHumanDate from 'lib/toHumanDate'
import toHumanPropertyStatus from 'lib/toHumanPropertyStatus'
import toMoney from 'lib/toMoney'
import useQuery from 'lib/useQuery'
import css from './styles.module.scss'
import getUploadUrl from 'lib/getUploadUrl'
import React, { useEffect, useRef, useState } from 'react'
import { NewsList } from 'pages/NewsPage'
import { NewPaymentModal, useNewPaymentModal } from 'components/NewPaymentModal'
import cn from 'classnames'

// const PropertyViewPage = ({ property, newsPosts, me }) => {
//   return (
//     <Segment title={`Объект ${property.name}`}>
//       {/* property.deletedAt && (
//         <div className={css.deletedAlert}>
//           <Alert message="Пользователь удалён и не может пользоваться системой" type="danger" />
//         </div>
//       ) */}
//       <RichText>
//         <p>
//           <img src={getUploadUrl(property.photo, 'property')} alt="" />
//         </p>
//         {/* <h1>Основное</h1> */}
//         <p>
//           Название: {property.name || '—'}
//           <br />
//           Краткое описание: {property.desc || '—'}
//           <br />
//           Тип недвижимости: {property.propertyType || '—'}
//           <br />
//           Площадь: {property.area || '—'}
//           <br />
//           Дополнительные траты:{' '}
//           {property.additionalExpenses ? toMoney(property.additionalExpenses, property.sellingCurrency) : '—'}
//           <br />
//           Цена покупки: {property.purchasePrice ? toMoney(property.purchasePrice, property.sellingCurrency) : '—'}
//           <br />
//           Цена продажи: {property.sellingPrice ? toMoney(property.sellingPrice, property.sellingCurrency) : '—'}
//           <br />
//           Минимальный срок реализации в месяцах: {property.minPeriod || '—'}
//           <br />
//           Максимальный срок реализации в месяцах: {property.maxPeriod || '—'}
//           <br />
//           Доходность: {property.minProfitPercent} – {property.maxProfitPercent}%
//           <br />
//           Дата старта объекта: {property.startsAt ? toHumanDate(property.startsAt) : '—'}
//           <br />
//           Дата завершения объекта: {property.endsAt ? toHumanDate(property.endsAt) : '—'}
//           <br />
//           Статус: {toHumanPropertyStatus(property.status)}
//           <br />
//           Сумма сбора: {toMoney(property.neededToCollectOriginal, property.collectCurrency)}
//           <br />
//           Осталось собрать: {toMoney(property.leftToCollectOriginal, property.collectCurrency)}
//         </p>
//       </RichText>
//       {!!property.contentHtml && (
//         <>
//           <br />
//           <br />
//           <RichText>
//             <h1 style={{ marginBottom: 5 }}>Об объекте</h1>
//           </RichText>
//           <RichText html={property.contentHtml} />
//         </>
//       )}
//       {canManageProperties(me) && (
//         <div className={css.edit}>
//           <Buttons>
//             <Button to={getPropertyEditRoute(property.serialNumber)}>Редактировать</Button>
//           </Buttons>
//         </div>
//       )}
//       {!!newsPosts.length && (
//         <>
//           <br />
//           <br />
//           <RichText>
//             <h1 style={{ marginBottom: 5 }}>Новости</h1>
//           </RichText>
//           <div style={{ maxWidth: 900 }}>
//             <NewsList newsPosts={newsPosts} withDate me={me} />
//           </div>
//         </>
//       )}
//     </Segment>
//   )
// }

const PropertyViewPage = ({ property, newsPosts, dashboardData, me }) => {
  const { openNewPaymentModal } = useNewPaymentModal()
  const [expanded, setExpanded] = useState(false)
  const outerRef = useRef(null)
  const innerRef = useRef(null)
  useEffect(() => {
    if (!outerRef.current || !innerRef.current || expanded) return
    const outerHeight = outerRef.current.clientHeight
    const innerHeight = innerRef.current.clientHeight
    if (innerHeight <= outerHeight) {
      setExpanded(true)
    }
  }, [expanded])
  return (
    <>
      {!!dashboardData && <NewPaymentModal me={me} dashboardData={dashboardData} />}
      <div className={css.page}>
        {property.photo && <img className={css.mainPic} src={getUploadUrl(property.photo, 'property')} alt="" />}
        <h1 className={css.propertyTitle}>Объект {property.name}</h1>
        {canManageProperties(me) && (
          <div className={css.edit}>
            <Buttons>
              <Button to={getPropertyEditRoute(property.serialNumber)}>Редактировать</Button>
            </Buttons>
          </div>
        )}
        <div className={css.investPlace}>
          {!!dashboardData && property.status === 'foundrising' && (
            <div className={css.newPaymentPlace}>
              <Button
                color="orange"
                className={css.newPaymentButton}
                onClick={() =>
                  openNewPaymentModal({
                    propertySerialNumber: property.serialNumber,
                    sum: '',
                  })
                }
              >
                Инвестировать
              </Button>
            </div>
          )}
          {!dashboardData && (
            <div className={css.newPaymentPlace}>
              <Button color="orange" className={css.newPaymentButton} to="/#form">
                Зарегистрироваться
              </Button>
            </div>
          )}
        </div>
        <div className={css.stats}>
          <div className={css.statsItem}>
            <div className={css.statsItemLabel}>ROI (доходность)</div>
            <div className={css.statsItemValue}>{property.roi || '—'}</div>
          </div>
          <div className={css.statsItem}>
            <div className={css.statsItemLabel}>Срок реализации</div>
            <div className={css.statsItemValue}>
              {property.minPeriod === property.maxPeriod ? (
                <>{property.maxPeriod} мес</>
              ) : (
                <>
                  {property.minPeriod}-{property.maxPeriod} мес
                </>
              )}
            </div>
          </div>
        </div>
        <div className={css.props}>
          <div className={css.propsItem}>
            <div className={css.propsItemLabel}>Статус</div>
            <div className={css.propsItemValue}>{toHumanPropertyStatus(property.status)}</div>
          </div>
          <div className={css.propsItem}>
            <div className={css.propsItemLabel}>Тип объекта</div>
            <div className={css.propsItemValue}>{property.propertyType}</div>
          </div>
          {!!property.area && (
            <div className={css.propsItem}>
              <div className={css.propsItemLabel}>Площадь</div>
              <div className={css.propsItemValue}>
                {property.area.toLocaleString('de-DE').replaceAll('.', ' ')} кв.м.
              </div>
            </div>
          )}
          {!!property.purchasePrice && (
            <div className={css.propsItem}>
              <div className={css.propsItemLabel}>Цена покупки</div>
              <div className={css.propsItemValue}>
                {property.purchasePrice ? toMoney(property.purchasePrice, property.sellingCurrency) : '—'}
              </div>
            </div>
          )}
          {!!property.additionalExpenses && (
            <div className={css.propsItem}>
              <div className={css.propsItemLabel}>Расходы</div>
              <div className={css.propsItemValue}>
                {property.additionalExpenses ? toMoney(property.additionalExpenses, property.sellingCurrency) : '—'}
              </div>
            </div>
          )}
          {!!property.sellingPrice && (
            <div className={css.propsItem}>
              <div className={css.propsItemLabel}>Цена продажи</div>
              <div className={css.propsItemValue}>
                {property.sellingPrice ? toMoney(property.sellingPrice, property.sellingCurrency) : '—'}
              </div>
            </div>
          )}
          {!!property.neededToCollectOriginal && (
            <div className={css.propsItem}>
              <div className={css.propsItemLabel}>Сумма сбора</div>
              <div className={css.propsItemValue}>
                {toMoney(property.neededToCollectOriginal, property.collectCurrency)}
              </div>
            </div>
          )}
          {!!property.leftToCollectOriginal && (
            <div className={css.propsItem}>
              <div className={css.propsItemLabel}>Осталось собрать</div>
              <div className={css.propsItemValue}>
                {toMoney(property.leftToCollectOriginal, property.collectCurrency)}
              </div>
            </div>
          )}
          {!!property.startsAt && (
            <div className={css.propsItem}>
              <div className={css.propsItemLabel}>Дата старта</div>
              <div className={css.propsItemValue}>{property.startsAt ? toHumanDate(property.startsAt) : '—'}</div>
            </div>
          )}
          {!!property.endsAt && (
            <div className={css.propsItem}>
              <div className={css.propsItemLabel}>Дата завершения</div>
              <div className={css.propsItemValue}>{property.endsAt ? toHumanDate(property.endsAt) : '—'}</div>
            </div>
          )}
        </div>
        {!!property.contentHtml && (
          <div className={css.aboutPlace}>
            <h2 className={css.aboutTitle}>Об объекте</h2>
            <div className={css.aboutPanel}>
              <div ref={outerRef} className={cn({ [css.aboutTextOuter]: true, [css.expanded]: expanded })}>
                <div
                  ref={innerRef}
                  className={css.aboutText}
                  dangerouslySetInnerHTML={{ __html: property.contentHtml }}
                />
              </div>
              {!expanded && (
                <div className={css.morePlace}>
                  <NativeLink
                    className={css.moreLink}
                    onClick={(e) => {
                      e.preventDefault()
                      setExpanded(true)
                    }}
                  >
                    Показать полностью
                  </NativeLink>
                </div>
              )}
            </div>
          </div>
        )}
        {!!newsPosts.length && (
          <div className={css.newsPlace} id="news">
            <h2 className={css.newsTitle}>Новости</h2>
            <NewsList newsPosts={newsPosts} withDate me={me} />
          </div>
        )}
      </div>
    </>
  )
}

const GetDashboardDataWrapper = (props) => {
  const queryResult = useQuery('getMyDashboardData', {
    skip: !props.me,
  })
  return (
    <PageWrapper queryResult={queryResult}>
      {({ dashboardData }) => <PropertyViewPage dashboardData={dashboardData} {...props} />}
    </PageWrapper>
  )
}

const GetPropertyWrapper = (props) => {
  const propertySerialNumber = props.match.params.propertySerialNumber
  const queryResult = useQuery('getProperty', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        propertySerialNumber,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} checkExists={({ property }) => !!property}>
      {({ property, newsPosts, me }) => (
        <GetDashboardDataWrapper property={property} newsPosts={newsPosts} me={me} {...props} />
      )}
    </PageWrapper>
  )
}

export default GetPropertyWrapper
