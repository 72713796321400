import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import DatePicker from 'components/DatePicker'
import Form, { FormItems, useForm } from 'components/Form'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import Select from 'components/Select'
import Textarea from 'components/Textarea'
import UploadMultipleImages from 'components/UploadMultipleImages'
import { canManageNewsPosts } from 'lib/can'
import { getNewsPostEditRoute } from 'lib/routes'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'
import { useHistory } from 'react-router-dom'

const NewsPostCreatePage = ({ me, properties }) => {
  const history = useHistory()
  const [createNewsPost] = useMutation('createNewsPost')
  const {
    formik: createNewsPostFormik,
    buttonProps: buttonCreateNewsPostProps,
    alertProps: alertCreateNewsPostProps,
  } = useForm({
    initialValues: {
      contentMd: '',
      date: new Date(),
      propertyId: '',
      photos: [],
    },
    validationSchema: yup.object({
      contentMd: yup.string().required(),
      date: yup.string().required(),
      propertyId: yup.string().required(),
      photos: yup.array(yup.string().required()),
    }),
    onSubmit: (values) => {
      return createNewsPost({
        variables: {
          input: {
            contentMd: values.contentMd,
            date: values.date,
            propertyId: values.propertyId,
            photos: values.photos,
          },
        },
      }).then(({ newsPost }) => {
        history.push(getNewsPostEditRoute(newsPost.serialNumber))
      })
    },
    successMessage: false,
  })
  return (
    <Segment title="Создание новости">
      <Form formik={createNewsPostFormik}>
        <FormItems>
          <DatePicker withTime={false} name="date" label="Дата" formik={createNewsPostFormik} />
          <Select
            formik={createNewsPostFormik}
            name={'propertyId'}
            label={'Объект'}
            options={properties.map((property) => ({ value: property.id, label: property.name }))}
          />
          <Textarea name="contentMd" label="Текст новости" formik={createNewsPostFormik} />
          <UploadMultipleImages name="photos" label="Фотографии" formik={createNewsPostFormik} />
          <Alert {...alertCreateNewsPostProps} />
          <Buttons>
            <Button {...buttonCreateNewsPostProps}>Создать новость</Button>
          </Buttons>
        </FormItems>
      </Form>
    </Segment>
  )
}

const CheckAuthWrapper = (props) => {
  const queryResult = useQuery('getProperties', {
    variables: {
      input: {
        limit: 99,
      },
    },
  })
  return (
    <PageWrapper queryResult={queryResult} redirerctUnauthorized checkAccess={({ me }) => canManageNewsPosts(me)}>
      {({ me }) => <NewsPostCreatePage me={me} properties={queryResult.properties} {...props} />}
    </PageWrapper>
  )
}

export default CheckAuthWrapper
