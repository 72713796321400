import React, { useState, useRef } from 'react'
import css from './styles.module.scss'
import useMutation from 'lib/useMutation'
import getUploadUrl from 'lib/getUploadUrl'
import getUploadName from 'lib/getUploadName'
import Link from 'components/Link'
import Button, { Buttons, ButtonWrapper } from 'components/Button'
import cn from 'classnames'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const Preview = ({ filePath, formik, value, name, i }) => {
  return (
    <div className={css.previewPlace}>
      <Link color="system-links-blue" target="_blank" href={getUploadUrl(filePath, 'file')}>
        {getUploadName(filePath)}
      </Link>{' '}
      <Link
        color="system-error-red"
        onClick={(e) => {
          e.preventDefault()
          formik.setFieldValue(
            name,
            value.filter((x, j) => j !== i)
          )
        }}
      >
        Удалить
      </Link>
    </div>
  )
}

const UploadMultipleFiles = ({ label, formik, name, hint, disabled, accept = '*' }) => {
  const inputEl = useRef()
  const [loading, setLoading] = useState(false)
  const [uploadMultipleFiles] = useMutation('uploadMultipleFiles')
  const error = _.get(formik.errors, name)
  const value = _.get(formik.values, name)
  const touched = _.get(formik.touched, name)
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const items = reorder(value, result.source.index, result.destination.index)
    formik.setFieldValue(name, items)
  }
  return (
    <div className={cn({ [css.uploadMultipleFiles]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}

      {/* value && !!value.length && (
        <div className={css.previews}>
          {value.map((filePath, i) => (
            <Preview key={filePath} filePath={filePath} formik={formik} value={value} name={name} i={i} />
          ))}
        </div>
          ) */}

      {value && !!value.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className={css.previews}>
                {value.map((filePath, index) => (
                  <Draggable key={filePath} draggableId={filePath} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Preview
                          key={filePath}
                          filePath={filePath}
                          formik={formik}
                          value={value}
                          name={name}
                          i={index}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <div className={css.buttons}>
        <Buttons>
          <ButtonWrapper>
            <label className={css.uploadButton}>
              <input
                className={css.fileInput}
                type="file"
                disabled={loading || disabled}
                accept={accept}
                multiple
                ref={(el) => (inputEl.current = el)}
                onChange={async ({ target: { validity, files } }) => {
                  setLoading(true)
                  try {
                    const { uploadedFiles } = await uploadMultipleFiles({
                      variables: { input: { files } },
                    })
                    const newInputValue = [...value]
                    for (const uploadedFile of uploadedFiles) {
                      newInputValue.push(uploadedFile.path)
                    }
                    formik.setFieldValue(name, newInputValue)
                  } catch (err) {
                    console.error(err)
                    formik.setFieldError(name, err.humanMessage || err.message)
                  } finally {
                    formik.setFieldTouched(name, true, true)
                    setLoading(false)
                    if (inputEl.current) {
                      inputEl.current.value = ''
                    }
                  }
                }}
              />
              <Button type="button" loading={loading} disabled={loading || disabled} color="outlined" asSpan>
                {value?.length ? 'Загрузить ещё' : 'Загрузить'}
              </Button>
            </label>
          </ButtonWrapper>
          {!!value && !!value.length && !loading && (
            <Button
              type="button"
              color="outlined-red"
              onClick={() => {
                formik.setFieldValue(name, [])
                formik.setFieldError(name, false)
                formik.setFieldTouched(name, true, true)
              }}
              disabled={disabled}
            >
              Удалить все
            </Button>
          )}
        </Buttons>
      </div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default UploadMultipleFiles
