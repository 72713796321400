import RichText from 'components/RichText'

const DocsContractPage = () => {
  return (
    <RichText>
      <h1>Заголовок</h1>
      <p>123</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
      <p>234</p>
    </RichText>
  )
}

export default DocsContractPage
