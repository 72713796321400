import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'

const RichText = ({ children, html, className }) => {
  if (html) return <div className={cn(css.richText, className)} dangerouslySetInnerHTML={{ __html: html }} />
  return <div className={cn(css.richText, className)}>{children}</div>
}

export default RichText
