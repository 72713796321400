import React, { useState, useRef } from 'react'
import css from './styles.module.scss'
import useMutation from 'lib/useMutation'
import getUploadUrl from 'lib/getUploadUrl'
import Button, { Buttons, ButtonWrapper } from 'components/Button'
import cn from 'classnames'
import _ from 'lodash'

const UploadMultipleImages = ({ label, formik, name, hint, disabled }) => {
  const inputEl = useRef()
  const [loading, setLoading] = useState(false)
  const [uploadMultipleImages] = useMutation('uploadMultipleImages')
  const error = _.get(formik.errors, name)
  const value = _.get(formik.values, name)
  const touched = _.get(formik.touched, name)
  return (
    <div className={cn({ [css.uploadMultipleImages]: true, [css.disabled]: disabled })}>
      {!!label && (
        <label htmlFor={name} className={css.label}>
          {label}
        </label>
      )}
      {value && !!value.length && (
        <div className={css.previews}>
          {value.map((imageName, i) => (
            <div key={i} className={css.previewPlace}>
              <img className={css.preview} alt="" src={getUploadUrl(imageName, 'preview')} />
              <span
                className={css.delete}
                onClick={e => {
                  e.preventDefault()
                  formik.setFieldValue(
                    name,
                    value.filter((x, j) => j !== i)
                  )
                }}
              />
            </div>
          ))}
        </div>
      )}
      <div className={css.buttons}>
        <Buttons>
          <ButtonWrapper>
            <label className={css.uploadButton}>
              <input
                className={css.fileInput}
                type="file"
                disabled={loading || disabled}
                accept="image/*"
                multiple
                ref={el => (inputEl.current = el)}
                onChange={async ({ target: { validity, files } }) => {
                  setLoading(true)
                  try {
                    const { uploadedFiles } = await uploadMultipleImages({
                      variables: { input: { files } },
                    })
                    const newInputValue = [...value]
                    for (const uploadedFile of uploadedFiles) {
                      newInputValue.push(uploadedFile.name)
                    }
                    formik.setFieldValue(name, newInputValue)
                  } catch (err) {
                    console.error(err)
                    formik.setFieldError(name, err.humanMessage || err.message)
                  } finally {
                    formik.setFieldTouched(name, true, true)
                    setLoading(false)
                    inputEl.current.value = ''
                  }
                }}
              />
              <Button type="button" loading={loading} disabled={loading || disabled} color="outlined" asSpan>
                {value?.length ? 'Загрузить ещё' : 'Загрузить'}
              </Button>
            </label>
          </ButtonWrapper>
          {!!value && !!value.length && !loading && (
            <Button
              type="button"
              color="outlined-red"
              onClick={() => {
                formik.setFieldValue(name, [])
                formik.setFieldError(name, false)
                formik.setFieldTouched(name, true, true)
              }}
              disabled={disabled}
            >
              Удалить все
            </Button>
          )}
        </Buttons>
      </div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default UploadMultipleImages
