// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ERR_EMAIL_TAKEN: 'Пользователь с такой почтой уже есть',
  ERR_PHONE_TAKEN: 'Пользователь с таким телефоном уже есть',
  ERR_INVALID_CONFIRM_PHONE_CODE: 'Код подтверждения указан не верно',
  ERR_CHECKED_CONFIRM_PHONE_CODE: 'Нельзя использовать код подтверждения повторно, перезапросите код подтверждения',
  ERR_ATTEMPTS_EXCEEDED_CONFIRM_PHONE_CODE: 'Количество попыток исчерпано, перезапросите код подтверждения',
  ERR_REQUEST_EXCEEDED_CONFIRM_PHONE_CODE:
    'Количество попыток запросить код исчерпано, перезапросите код подтверждения позже',
  ERR_NOT_FOUND: 'Страница не найдена',
  ERR_UNAUTHORIZED: 'Ошибка авторизации',
  ERR_UNKNOWN:
    'На сайте ведутся технические работы. Сайт заработает в течение ближайших 5–10 минут. Пожалуйста, обновите страницу чуть позже.',
}
