export const propertyStatuses = {
  foundrising: 'сбор средств',
  processing: 'реализация',
  payoutPrepearing: 'подготовка к выплате',
  finished: 'завершен',
  cancelled: 'отменён',
}

export const propertyStatusesSelectOptions = Object.entries(propertyStatuses).map((entry) => {
  return {
    value: entry[0],
    label: entry[1],
  }
})

const toHumanPropertyStatus = (propertyStatus) => {
  return propertyStatuses[propertyStatus] || 'неизвестно'
}

export default toHumanPropertyStatus
