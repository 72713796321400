import React from 'react'
import css from './styles.module.scss'
import cn from 'classnames'

const RadioButton = ({ label, formik, name, onChange, value, ...restProps }) => {
  const formikValue = formik.values[name]
  return (
    <label className={css.radioButton}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={value === formikValue}
        onChange={(...agrs) => {
          if (onChange) onChange(...agrs)
          formik.handleChange(...agrs)
        }}
        onBlur={formik.handleBlur}
        {...restProps}
      />{' '}
      {label}
    </label>
  )
}

export const RadioButtons = ({ label, formik, name, hint, children, direction = 'row', attention, ...restProps }) => {
  const error = formik.errors[name]
  const touched = formik.touched[name]
  return (
    <div
      className={cn({
        [css.radioButtons]: true,
        [css[`direction-${direction}`]]: !!direction,
      })}
      {...restProps}
    >
      {!!label && (
        <label className={css.label}>
          {label}
          {!attention ? null : (
            <>
              {' '}
              <span className={css.attention}>{attention === true ? 'Внимание' : attention}</span>
            </>
          )}
        </label>
      )}
      <div className={css.items}>{children}</div>
      {!!error && touched && <p className={css.error}>{error}</p>}
      {!!hint && <p className={css.hint}>{hint}</p>}
    </div>
  )
}

export default RadioButton
