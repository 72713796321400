import cn from 'classnames'
import Alert from 'components/Alert'
import Button, { Buttons } from 'components/Button'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Loader from 'components/Loader'
import PageWrapper from 'components/PageWrapper'
import Segment from 'components/Segment'
import { canManageProperties } from 'lib/can'
import { getCompanyResultCreateRoute, getCompanyResultEditRoute } from 'lib/routes'
import toMoney from 'lib/toMoney'
import useDropdown from 'lib/useDropdown'
import useQuery from 'lib/useQuery'
import { useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import css from './styles.module.scss'
import toHumanDate from 'lib/toHumanDate'

const CompanyResultsListItem = ({ companyResult, me }) => {
  const history = useHistory()
  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const menuRef = useRef()
  const dropdownMenuRef = useRef()
  useDropdown(menuRef, dropdownMenuRef, isDropdownMenuOpen, setDropdownMenuOpen)
  /* const [deleteCompanyResult] = useMutation('deleteCompanyResult')
  const {
    formik: deleteCompanyResultFormik,
    buttonProps: buttonDeleteCompanyResultProps,
    alertProps: alertDeleteCompanyResultProps,
  } = useForm({
    onSubmit: () => {
      return deleteCompanyResult({
        variables: {
          input: {
            companyResultId: companyResult.id,
          },
        },
        refetchQueries: ['getCompanyResults'],
      })
    },
    successMessage: 'Пользователь удалён',
  }) */
  return (
    <>
      <div
        className={cn({
          [css.row]: true,
          [css.clickable]: true,
          [css.colorGreen]: companyResult.status === 'finished',
        })}
        onClick={() => history.push(getCompanyResultEditRoute(companyResult.serialNumber))}
      >
        <div className={css.cell}>{companyResult.serialNumber}</div>
        <div className={css.cell}>{companyResult.name}</div>
        <div className={css.cell}>{toHumanDate(companyResult.date)}</div>
        <div className={css.cell}>{companyResult.hidden ? 'Да' : 'Нет'}</div>
        {canManageProperties(me) && (
          <div className={css.cell}>
            <Buttons>
              <Link
                color="system-links-blue"
                to={getCompanyResultEditRoute(companyResult.serialNumber)}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                Редактировать
              </Link>
              {/* canDeleteCompanyResults(me) && (
              <Link
                color="system-error-red"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setConfirmationOpen(true)
                }}
              >
                Удалить
              </Link>
              ) */}
            </Buttons>
          </div>
        )}
      </div>
      <div
        className={cn({
          [css.card]: true,
          [css.clickable]: true,
          [css.colorGreen]: companyResult.status === 'finished',
        })}
        onClick={() => history.push(getCompanyResultEditRoute(companyResult.serialNumber))}
      >
        {/*<div className={css.cardHeader}>*/}
        {/*  <div className={css.number}>{companyResult.serialNumber}</div>*/}
        {/*</div>*/}
        {companyResult.name && <div className={css.title}>{companyResult.name}</div>}
        <div className={css.info}>
          <div className={css.infoItem}>
            <div className={css.icon}>
              <Icon name="dot" />
            </div>
            <div className={css.text}>Дата: {toHumanDate(companyResult.date)}</div>
            <div className={css.text}>Номер: {companyResult.serialNumber}</div>
            <div className={css.text}>Скрыт: {companyResult.hidden ? 'Да' : 'Нет'}</div>
          </div>
        </div>
        <div className={css.menu}>
          {canManageProperties(me) && (
            <>
              <Button
                size="small-icon"
                color="clear-text-orange"
                icon="dots"
                ref={menuRef}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
              <div
                className={cn({
                  [css.dropdown]: true,
                  [css.toBottom]: true,
                  [css.hidden]: !isDropdownMenuOpen,
                })}
                ref={dropdownMenuRef}
              >
                <div className={cn(css.links, css.compressed)}>
                  {canManageProperties(me) && (
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={getCompanyResultEditRoute(companyResult.serialNumber)}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="edit" />
                        </div>
                        <div className={css.text}>Редактировать</div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

const CompanyResultsList = ({ me }) => {
  const { companyResults, pageInfo, loading, error, fetchMore } = useQuery('companyResultList', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        showHidden: true,
      },
    },
  })
  if (loading) return <Loader type="section" />
  if (error) return <Alert type="danger" message={error.humanMessage} />
  if (!companyResults.length) return <Alert message="Нет результатов" />
  return (
    <div className={css.companyResults}>
      <div className={css.header}>
        <div className={css.row}>
          <div className={css.cell}>№</div>
          <div className={css.cell}>Название</div>
          <div className={css.cell}>Дата</div>
          <div className={css.cell}>Скрыт</div>
          {canManageProperties(me) && <div className={css.cell}></div>}
        </div>
      </div>
      <div className={css.items}>
        <InfiniteScroll
          threshold={250}
          loadMore={async () => {
            return fetchMore({
              variables: {
                input: {
                  endCursor: pageInfo.endCursor,
                  limit: 30,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return {
                  getCompanyResults: {
                    ...fetchMoreResult.getCompanyResults,
                    companyResults: [
                      ...prev.getCompanyResults.companyResults,
                      ...fetchMoreResult.getCompanyResults.companyResults,
                    ],
                  },
                }
              },
            })
          }}
          hasMore={pageInfo.hasNextPage}
          loader={
            <div className={css.loader} key="loader">
              <Loader type="section" />
            </div>
          }
        >
          {companyResults.map((companyResult, i) => (
            <CompanyResultsListItem key={companyResult.id} companyResult={companyResult} me={me} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}

const CompanyResultsAdminPage = () => {
  return (
    <PageWrapper redirerctUnauthorized>
      {({ me }) => (
        <Segment
          title="Результаты"
          desc={
            <Buttons>
              <Button to={getCompanyResultCreateRoute()}>Создать новый</Button>
            </Buttons>
          }
        >
          {/* canManageProperties(me) && (
            <div className={css.new}>
              <Buttons>
                <Button to={getCompanyResultCreateRoute()}>Добавить объект</Button>
              </Buttons>
            </div>
          ) */}
          <CompanyResultsList me={me} />
        </Segment>
      )}
    </PageWrapper>
  )
}

export default CompanyResultsAdminPage
